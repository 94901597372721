.ds-citySearchItems {
  display: flex;
  height: 40px;
  margin-bottom: 20px;

  &__left {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-right: 15px;
    & > img {
      max-width: 100%;
      height: 16px;
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    width: 100%;

    .city-name {
      font-size: 15px;
      border-bottom: 1px dashed #a5a5a5;
      width: 80%;
    }

    .check-icon {
      & > img {
        max-width: 100%;
        height: 16px;
      }
    }
  }
}