.Footer3 {
  width: 100%;

  &__top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 766px) {
      margin-bottom: 30px;
    }
  }

  .desktop-hr {
    display: flex;
    flex-direction: column;
  }

  &__bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 48px;

    .footer__bottom-text {
      font-size: 14px;
      color: #a3a3a3;
      margin-top: 24px;
    }

    .policy-nav {
      cursor: pointer;
    }
  }

  &__collections-component {
    width: calc(80% + 40px);
    margin: 40px auto 0;

    & .collections-component {
      &--header {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        margin: 0 0 40px;
      }

      &__body {
        width: 100%;
        display: grid;
        grid-auto-rows: auto;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 10px;
        row-gap: 90px;

        & .collection-item {
          padding: 0 10px;

          &--header {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            text-transform: uppercase;
          }

          &__item-list {
            padding-top: 20px;
            list-style-type: none;

            & > li {
              cursor: pointer;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 14px;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    & > hr {
      width: calc(100% + 40px);
      margin: 95px auto 60px;
      left: -20px;
      position: relative;
    }
  }

  .footer-nav:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .Footer3__map-section {
    position: relative;
    width: 100%;

    .map-frame {
      filter: brightness(40%);
      transition: filter 0.3s;
      transition-timing-function: ease-out;
    }

    .map-text {
      color: white;
      position: absolute;
      font-size: 1.5rem;
      transition: display 0.3s;
      transition-timing-function: ease-out;
      transform: translate(-50%, 50%);
      margin: 0;
      bottom: 50%;
      left: 50%;
    }
  }

  .justify-evenly {
    justify-content: space-evenly;
  }

  .store-information {
    display: flex;
    flex-direction: column;

    &_arrow {
      position: absolute;
      left: 84%;
      transform: translateY(-70%);
      background: white;
      width: 12rem;
      max-height: 16rem;
    }

    &_arrowBox {
      height: 0;
      width: 20px;
      margin-left: 2.5rem;
      transform: rotateZ(90deg);
      margin-top: -0.4rem;
    }

    &_arrowBox_shadow {
      box-shadow: 0px -6px 12px 2px rgb(0 0 0 / 30%);
    }

    &_arrowBox::before {
      content: '';
      border-top: 10px solid white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }

    &_moreitesmdivwidth {
      max-width: 12rem;
    }

    h4 {
      font-size: 14px;
    }

    .address-text {
      margin: 0;
      max-width: 270px;
      width: 100%;
      line-height: 18px;
      word-break: break-word;
    }

    &__subsection {
      display: flex;
      align-items: center;
      margin-bottom: 13px;

      & span {
        margin-right: 8px;
        width: 15px;
      }

      & a {
        all: unset;
        cursor: pointer;
        margin: 0;
      }
    }

    .footer-social-media-container {
      h4 {
        margin-top: 0;
      }

      width: 246px;
      .social-media-section {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;

        .social-media-icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  hr {
    background-color: #ebebeb;
    box-shadow: 0 0 0.5px;
    border: none;
    height: 0.5px;
  }

  .mobile-hr {
    display: none;
  }

  .mobile-hr-shop {
    display: none;
  }

  &__section {
    &.store-policies {
      max-width: 800px;
      @media screen and (min-width: 787px) {
        margin: 40px auto;
      }
      @media screen and (max-width: 786px) {
        margin: 0px auto 20px;
      }
    }
  }

  .mt-13 {
    margin-top: -13px;
  }
}

@media screen and (max-width: 767px) {
  .Footer3 {
    .Footer3__section {
      flex-direction: column;
      align-items: center;
      width: 100%;

      & > div {
        align-items: center;
        text-align: center;
        width: 100%;
      }
    }

    .desktop-hr {
      display: none;
    }

    .mobile-hr {
      display: block;
      width: 100%;
    }

    .mobile-hr-shop {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 0 10px;
    }

    .footer__bottom-text {
      text-align: center;
    }

    .payment-methods {
      flex-wrap: wrap;
    }

    & .accordion-component {
      & .accordion-header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-size: 14px;
        text-align: center;
        padding: 15px 10px;
      }

      & .accordion-body {
        & > ul {
          list-style-type: none;
          & > li {
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            padding: 10px;
          }
        }
      }
    }

    .footer-social-media-container {
      .social-media-section {
        display: flex;
        gap: 16px;
        justify-content: center;
        .social-media-icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}
