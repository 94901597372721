.cardWidget {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &.cardWidget1 {
    width: 100%;
    height: auto;
    max-width: 220px;
    overflow: hidden;

    & .cardWidgetImage {
      filter: brightness(80%);
    }

    & .cardWidgetText {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-size: 16px;
      width: fit-content;
      font-weight: 500;
      text-align: center;

      @media screen and (max-width: 786px) {
        font-size: 14px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: #0000004d;
      z-index: 2;
      transition: 0.5s;
      opacity: 0;
    }
  }

  &.cardWidget2 {
    width: 100%;
    height: auto;
    max-width: 220px;
    overflow: hidden;

    & .cardWidgetText {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      padding-top: 12px;
      text-align: center;

      @media screen and (max-width: 786px) {
        text-align: start;
        font-size: 14px;
      }
    }
  }

  & .cardWidgetImage {
    width: 100%;
    background-repeat: no-repeat;
    transition: transform 200ms linear, filter 200ms linear;
    &.animation {
      &-none {
        transform: scale(1);
      }
      &-zoom-in {
        transform: scale(1);
        filter: brightness(80%);
      }
      &-zoom-out {
        transform: scale(1.2);
        filter: brightness(80%);
      }
    }
  }

  &:hover {
    & .animation {
      &-zoom-in {
        transform: scale(1.2);
        filter: brightness(40%);
      }
      &-zoom-out {
        transform: scale(1);
        filter: brightness(40%);
      }
    }
  }
}
