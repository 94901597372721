.framer-card {
  .image-wrap {
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
    width: 100%;
    & .collection-image {
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  .banner-type {
    width: 100%;
    position: absolute;
    bottom: -40px;
    opacity: 0;
    background-color: #000000;
    color: #ffffff;
    padding: 12px;
    font-size: 14px;
    text-align: center;
    transition:
      bottom linear 250ms,
      opacity linear 250ms;
  }

  &:hover {
    .banner-type {
      bottom: 0;
      opacity: 1;
    }
  }
}
