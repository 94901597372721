.athsPopUp {
  display: flex;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  background: #000000b5;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    margin: 0 22px;
    padding: 9px 10px 0 10px;

    &--cancelIcon {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      & > img {
        width: 16px;
        filter: grayscale(1);
      }
    }

    &--imgLogo {
      max-width: 85px;
      max-height: 85px;
      box-shadow: 0px 3px 6px #00000029;
      border: 4px solid #FFFFFF;
      
      & > img {
        max-width: 100%;
      }
    }
    &--qoute {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0px;
      color: #000000;
      margin: 18px 0 7px 0;
    }
    &--text {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #606060;
      padding: 0 30px;
      line-height: 1.4em;
    }
    &--button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background: #EA5151 0% 0% no-repeat padding-box;
      border-radius: 22px;
      color: #FFFFFF;
      font-size: 13px;
      margin: 21px 0 25px 0;
      padding: 14px 0;
      width: 90%;
      border: 0;
    }
  }
}