.lazy-itemCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &--head {
      background: #dddddd;
      height: 158px;
      max-width: 100%;
      position: relative;
      background-size: cover;
      border-radius: 6px 6px 0 0;
  }
  &--body {
      display: flex;
      flex-direction: column;
      padding: 6px 6px 10px 6px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0 0 6px 6px;
      background-color: #fff;
  }

  &--name {
      background: #dddddd;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 5px;
  }
  
  &--box {
      display: flex;
      flex-direction: row;
  }

  &--amount {
      display: flex;
      flex-grow: 1;
      background: #dddddd;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 5px;
  }
  &--cta {
      display: flex;
      flex-grow: 1;
      background: #dddddd;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 5px;
  }

}

.animate {
  -webkit-animation: load 1s linear infinite;
  animation: load 0.8s linear infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background: #dddddd;
  background-image: linear-gradient(
    to left,
    #dddddd 0%,
    #f5f5f5 20%,
    #efefef 40%,
    #dddddd 100%
  );
  background-repeat: no-repeat;
  background-size: cover;
}