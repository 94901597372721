  .SortingAndFiltersTabWrapper {

    &.Wrapper {
      position: absolute;
      left: -113%;
      height: 100vh;
      display: flex;
    }

    .SortingAndFiltersTab {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        position: fixed;
        background: white;
        z-index: 6;
        bottom: 0%;
        left: 0%;
      }

      &.mobileDrawer {
        @media screen and (max-width: 767px) {
          position: absolute;
          background: white;
          z-index: 6;
          bottom: 0%;
          left: 0%;
        }
      }

      .filterTab {
        display: flex;
        align-items: center;
        justify-items: center;
        padding: 7px 8px 7px 11px;
        border: 1px solid rgb(209, 209, 209);
        cursor: pointer;

        @media screen and (max-width: 767px) {
          width: 50vw;
          display: flex;
          justify-content: center;
          height: 48px;
          border-bottom: none;
          border-left: none;
          border-right: none;
        }

        .filterText {
          margin-left: 10px;
          font-size: 16px;
        }
      }

      .dropDownContainer {
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 6;
        padding-left: 22px;
        top: 100%;
        margin-top: 10px;
        left: 0%;
        min-width: 233px;
        padding-top: 25px;
        border: 1px solid rgb(209, 209, 209);
        background: white;
      }

      .spacing-flex {
        display: flex;
        flex-shrink: 2;
        width: 85%;
        animation-name: identifier;
        animation-duration: 7s;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      @keyframes identifier {
        from {
          width: 85%;
        }

        to {
          width: 55%;
        }
      }
    }
  }