.inaccessibleLayer {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;

  &-login {
    position: absolute;
    right: 0;
    top: 0;
    padding: 30px 20px;

    & p {
      margin: 0;
      font-size: 14px;
      line-height: 15px;
      color: #ffffff;
      text-align: right;
    }

    & .login-btn {
      font-size: 15px;
      border-radius: 8px;
      border: 0.4px solid #ffffff;
      padding: 7px 13px;
      color: #ffffff;
      transition: color linear 50ms, background-color linear 50ms;

      &:hover {
        background-color: #ffffff;
        color: #000000;
      }
    }
  }

  & .enable-container {
    box-shadow: 0px 16px 36px #00000079;
    border-radius: 8px;
    padding: 52px 67.2px;
    width: 420px;
    height: fit-content;

    & > p {
      margin: 0 0 24px;
      font-size: 18px;
    }

    & > div {
      border-radius: 8px;
      background-color: #3d6afd;
      color: #ffffff;
      padding: 17px 15px 15px;
      transition: background-color 50ms linear;

      &:hover {
        background-color: #375edd;
      }
    }
  }
}
