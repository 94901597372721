.digital-showroom__ads {
  .container-width {
    width: 90vw;
  }
  .gif-height {
    height: 13vh;
  }
  @media screen and (min-width: 767px) {
    .container-width {
      width: 187px;
    }
    .gif-height {
      height: 635px;
    }
    .margin-left-container {
      margin-left: 7.5vw;
    }
  }
}
.digital-showroom__ads__mb15rem {
  margin-bottom: 15rem;
}
.digital-showroom__ads__sticky_on_header {
  top: 75px;
  position: sticky;
}

.mb6-category-item-body {
  padding: 10px 0 24px;

  &.old-card-wrapper > section {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
    justify-items: center;
  }
}

@media screen and (max-width: 786px) {
  .mb6-category-item-body {
    padding: 20px;

    & > section {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.digital-showroom__ads_footer {
  width: 187px;
  height: 635px;
  &mt_-4px {
    margin-top: -4px;
  }
  &mt_-2px {
    margin-top: -2px;
  }
  .container-width {
    width: 90vw;
  }
  .gif-height {
    height: 13vh;
  }
  @media screen and (min-width: 767px) {
    .container-width {
      width: 187px;
    }
    .gif-height {
      height: 635px;
    }
    .margin-left-container {
      margin-left: 7.5vw;
    }
  }
}
