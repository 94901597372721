.exit-modal-close-icon {
  cursor: pointer;
  z-index: 999;

  &-white {
    color: white;
  }
}

.bg-img-template-wrapper {
  height: 100%;
  width: 100%;
}

.image-text-card-wrapper {
  width: 100%;
  height: 100%;
}

.template-container {
  width: 850px;
  aspect-ratio: 5/3;

  .bg-gradient {
    background-color: #000000;
  }
}

.text-card {
  gap: 32px;

  .logo-image {
    width: 60px;
    height: 60px;
  }

  .tempting-message {
    margin: 0;
    font-size: 20px;
    font-weight: 300;
  }

  .offer-info {
    gap: 16px;
    margin-bottom: 16px;

    .offer-text {
      margin: 0;
      font-size: 40px;
      font-weight: 600;
    }

    .offer-limit {
      margin: 0;
      font-size: 14px;
    }
  }

  .coupon-button-wrapper {
    gap: 12px;
    width: 50%;
    min-width: 300px;
    font-size: 12px;
    font-weight: 400;

    .coupon-name {
      border: 1px solid #bababa;
      padding: 10px;
      font-size: 18px;
    }

    .copy-coupon-button {
      cursor: pointer;
      color: white;
      border: none;
      background-color: black;
      padding: 11px;
      font-size: 18px;
    }
  }

  &-side-img {
    .tempting-message {
      font-size: 20px;
    }

    .offer-info {
      .offer-text {
        font-size: 32px;

        font-weight: 600;
      }
      .offer-limit {
        color: #808080;
        max-width: 80%;
        line-height: 20px;
      }
    }

    .coupon-button-wrapper {
      width: 60%;
      min-width: 180px;
    }
  }

  &-no-img {
    .offer-limit {
      color: #808080;
    }
  }

  &-bg-img {
    * {
      color: white;
    }
  }
}
