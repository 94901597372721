.guide-lines__deliveryPickup {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:#000000d9;
    z-index: 9999;
    // opacity: 0.8;

    &--text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-70%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        color: #FFFFFF;
        font-size: 16px;
        width: 66%;

        &>span {
            margin-bottom: 10px;
            text-align: end;
        }

        // &>span::after {
        //     content: "";
        //     position: absolute;
        //     width: 0px;
        //     height: 100%;
        //     border: 1px dashed #ffffff;
        //     z-index: 1;

        // }

        &>button {
            color: #FFFFFF;
            border-radius: 5px;
            border: 1px dotted #FFFFFF;
            background-color: transparent;
            padding: 10px 30px;
        }
    }

    &--line {
        position: absolute;
        top: 45px;
        right: 80px;
        height: 40%;
        border: 1px dashed #fff;
    }

    &--line::after {
        content: "";
        height: 15px;
        width: 15px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        bottom: -7px;
        left: -7px;
    }

    &--toggleSwitch {
        padding: 2px;
        background-color: #fff;
        position: absolute;
        right: 10px;
        top: 4px;
        border-radius: 30px;
    }
}