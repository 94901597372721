.redirection {
  &-container {
    display: flex;
    justify-content: center;
    padding-top: 20%;
  }
  &-success {
    color: green;
    font-weight: 600;
  }
}

.visible-mobile-block {
  display: none !important;
}
.visible-desktop-block {
  display: block !important;
}
.visible-mobile-flex {
  display: none !important;
}
.visible-desktop-flex {
  display: flex !important;
}
.visible-mobile-ib {
  display: none !important;
}
.visible-desktop-ib {
  display: inline-block !important;
}
.visible-mobile {
  display: none !important;
}
.visible-desktop {
  display: flex !important;
}

@media (max-width: 767px) {
  .visible-mobile {
    display: block !important;
  }
  .visible-desktop {
    display: none !important;
  }
  .visible-mobile-block {
    display: block !important;
  }
  .visible-desktop-block {
    display: none !important;
  }
  .visible-mobile-flex {
    display: flex !important;
  }
  .visible-desktop-flex {
    display: none !important;
  }
  .visible-mobile-ib {
    display: inline-block !important;
  }
  .visible-desktop-ib {
    display: none !important;
  }
}

.rm-input-num-arrows {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.buttonReset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  text-align: inherit;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}
