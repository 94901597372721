.CollectionCategorySideRender {
  position: sticky;
  top: 0;
  & .collection-accordion {
    border-bottom: 1px solid #e5e5e5;
    &__container {
      border-bottom: 1px solid #e5e5e5;
    }
    &__header {
      padding: 20px 24px;
      p {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        padding-right: 10px;
      }
      img {
        transition: transform 200ms ease-out;
      }
    }
    &__body {
      & .body-list {
        padding: 0 24px;
        list-style-type: none;
        &--item {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          margin-bottom: 14px;
          transition: color linear 150ms;
          &:hover {
            color: #c23fd9;
          }
          &.active {
            color: #a912c4;
          }
        }
      }
    }
  }
}
