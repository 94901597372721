.dukaanInfoPopUp {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  & > :first-child > .closeicon {
    top: 10px;
    right: 10px;
  }

  &-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    max-height: 400px;
    margin-bottom: 16px; 
    width: 100%;
    line-height: 1em;

    & > button {
      padding: 13px 0;
      font-size: 16px;
    }
  }

  &__dukaanicon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 78px;
    border: 3px solid #E6E6E6;
    border-radius: 10px;
    margin-bottom: 12px;

    & > img {
      max-width: 100%;
      height: auto;
      border-radius: 6px;
    }
  }

  &__dukaanName {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #2A2A2A;
    margin: 0 10%;
    text-align: center;
  }

  &__categoryDistance {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 5px 0 22px 0;

    & > span {
      color: #EA5151;
      font-size: 11px;
      font-weight: 600;
      white-space: nowrap;
    }

    &--dot {
      padding: 2px;
      border-radius: 20px;
      background-color: #EA5151;
      margin: 0 4px;
    }
  }

  &__address {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0 27px 0;
    margin: 0 10%;
    border-top: 1px dashed #cacaca;

    & > :first-child {
      font-size: 11px;
      letter-spacing: 0px;
      color: #2A2A2A;
      margin-bottom: 4px;
    }
    & > :last-child {
      font-size: 11px;
      letter-spacing: 0px;
      color: #808080;
      text-align: center;
    }
  }
}