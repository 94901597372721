.card-payment-box {
    .card-wrapper {
        padding: 20px;

        .card {
            padding: 15px;
            border-radius: 12px;
            border: 1px solid #e2e2e2;
            box-shadow: 0px 0px 4px #e2e2e2;

            .top-wrap {
                .icon-wrapper {
                    width: 48px;
                    height: 48px;
                    padding: 12px 0;
                    border-radius: 8px;
                    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
                    background-color: #fff;
                    text-align: center;
                    display: inline-block;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        display: block;
                        margin: auto;
                    }
                }
            }

            .form-wrap {
                padding: 20px 0;

                small.error {
                    color: red;
                    font-weight: 400;
                    font-size: 12px;
                    display: block;
                    line-height: 15px;
                    margin-top: 10px;
                }

                .form-control {
                    background-color: transparent;
                    border: 0;
                    border-radius: 0;
                    border-bottom: 1px solid #191b26;
                    padding-left: 0;
                    outline: none;
                    box-shadow: none;
                    font-size: 18px;
                }

                label {
                    font-size: 10px;
                    font-weight: 600;
                    letter-spacing: 1px;
                    color: #b9b8b8;
                    text-transform: uppercase;
                    margin-right: 10px;
                    padding-bottom: 4px;
                }
            }

            .inline-form-wrap {
                display: flex;

                .form-group {
                    width: 50%;
                    margin-right: 10px;
                    padding: 20px 0 10px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .form-control {
                        width: 82px;
                    }
                }
            }
        }
    }

    .cta-btn {
        margin-top: 24px;
        width: 100%;
        background-color: #191b26;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        padding: 14px;
        height: auto;
        border-radius: 12px;
        color: #fff;
        border: 0;
        text-transform: uppercase;
    }
}