.carousel {
  margin: 0 auto;
  max-width: 1400px;
  overflow: hidden;
  display: flex;

  > * {
    flex: 100% 0 0;
  }

  .group {
    display: flex;
    gap: 12px;
    left: 0;
    padding-right: 12px;
    will-change: transform;
    animation: scrolling linear infinite;
    animation-duration: 15s;
  }

  &:hover .group {
    animation-play-state: paused;
  }
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
