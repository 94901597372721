.premium-order-status-page {
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .header-mobile {
    .header {
      &__top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px;
        font-weight: 600;

        & > img {
          max-width: 24px;
          filter: invert(1);
          margin-right: 10px;
        }
      }
      &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 17px 20px 17px 20px;
        @media screen and (min-width: 768px) {
          background-color: #fff;
          width: 420px;
          border: 1px dashed #e2e2e2;
          border-top: none;
        }

        &--thank {
          font-size: 23px;
          position: relative;
          & > img {
            // position: absolute;
            right: 2px;
            bottom: 0;
            max-width: 100%;
            height: 26px;
            filter: grayscale(1);
          }
        }

        &--actions {
          display: flex;

          & > a {
            border: 1px solid #e2e2e2;
          }
        }
      }
      &--bottom-wrapper {
        display: flex;
        justify-content: center;
        @media screen and (max-width: 767px) {
          display: block;
          margin-top: 9vh;
        }
      }
    }
    .order-delivery-time {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 8px 20px;
      background-color: #353839;
      color: #ffffff;
      font-size: 12px;
      line-height: 1.4em;

      & > :first-child {
        padding-right: 10px;

        & > img {
          max-width: 16px;
          height: auto;
          margin-top: 2px;
        }
      }

      & > :last-child {
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          margin-left: 10px;
          max-width: 100%;
          height: 14px;
        }
      }
    }
  }

  main {
    .order-status {
      padding: 17px 20px;
      border: 1px dashed #e2e2e2;
      min-height: 55px;

      &__processing {
        &--top {
          & > .title-text {
          }
          & > .status {
            & > div > img {
              max-width: 100%;
              height: 10px;
            }
            .arrow-down {
              transform: rotate(90deg);
            }
            .arrow-up {
              transform: rotate(-90deg);
            }
          }
        }

        &--bottom {
          & > article {
            position: relative;
            flex: 1;
            &:after {
              content: '';
              position: absolute;
              top: 16px;
              right: -55%;
              width: 0px;
              width: 100%;
              border: 1px dashed #a5a5a5;
              z-index: 1;
            }
          }
          & > :first-child {
            justify-content: flex-start;
            padding-right: 20px;
          }
          & > :last-child {
            justify-content: flex-end;
            padding-left: 20px;
            &::after {
              border: 0;
            }
          }
        }
      }
    }

    .bill-wrapper {
      padding: 24px 20px 15px 20px;

      &__orderDetails {
        &--donwloadBillBtn {
          & > img {
            filter: invert(1);
            max-width: 100%;
            height: 13px;
          }
        }
      }
      .order-time {
        margin-top: 8px;
        @media screen and (max-width: 767px) {
          margin-top: 6px;
        }
      }
      @media screen and (min-width: 767px) {
        padding: 28px 20px 20px 20px;
      }
    }
    .order-bill {
      margin: 0px 20px;
      box-shadow: 0px 1px 3px #00000027;
      min-height: 100px;
      border-radius: 8px;
      padding: 18px 14px 18px 14px;
      border: 0.5px solid #e2e2e2;
      .items-details-container {
        padding-bottom: 4px;
        @media screen and (max-width: 767px) {
          padding-bottom: 2px;
        }
      }

      .responsive-minor-text {
        font-size: 12px;
        @media screen and (max-width: 767px) {
          font-size: 10px;
        }
      }
      .responsive-major-text {
        font-size: 14px;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }

      .bill-table {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        @media screen and (max-width: 767px) {
          font-size: 10px;
        }
      }
      .variant-name {
        letter-spacing: 0.28;
        font-weight: 400;
      }
      .item-name-container {
        max-width: 50%;
      }
      .conclusion {
        padding-top: 20px;
        .incl-gst {
          color: #797a7c;
        }
        @media screen and (max-width: 767px) {
          padding-top: 18px;
        }
      }
      .color-green {
        color: #007b13;
      }
      .f8 {
        font-size: 9px;
      }
      .yellow-dot {
        width: 7px;
        height: 7px;
        background-color: #ffd800;
        border-radius: 50%;
      }
      .overall-details-wrapper {
        border-top: 0.30000001192092896px dashed #9d9fa9;
        border-bottom: 0.5px solid #9d9fa9;
        padding: 18px 0px 18px 0px;
      }
      @media screen and (min-width: 767px) {
        margin: 0px 18px;
        padding: 18px 14px 20px 14px;
      }
      .bill-items-margin {
        padding-bottom: 14px;
        @media screen and (max-width: 767px) {
          padding-bottom: 12px;
        }
      }
    }

    .partial-payment-receipt {
      margin: 16px 20px;
      min-height: 100px;
      padding: 18px 14px 18px 14px;
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.19);
      border-radius: 8px;

      .icons-display {
        display: flex;
        justify-content: space-between;
        position: relative;

        .one::after {
          content: '';
          position: absolute;
          top: 12px;
          left: 34px;
          width: 80%;
          border: 1px dashed #e0e0e0;
          z-index: 1;
        }
      }

      .amount-display {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .partial-pay-amount {
          display: flex;
          flex-direction: column;
          .amount-value {
            font-weight: 600;
            color: rgba(0, 0, 0, 1);
          }
          .amount-text {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140.2%;
            color: rgba(130, 130, 130, 1);
          }
        }

        .remaining-pay-amount {
          text-align: right;
          .amount-value {
            font-weight: 600;
            color: rgba(0, 0, 0, 1);
          }
          .amount-text {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140.2%;
            color: rgba(130, 130, 130, 1);
          }
        }
      }
    }

    .delivery-info-tooltip {
      position: fixed;
      top: 115px;
      right: 10px;
      background-color: #f7f5f5;
      box-shadow: 3px 5px 12px 1px #0000002f;
      z-index: 999;
      max-width: 60%;
      padding: 8px;
      border-radius: 8px;

      &__text {
        font-size: 12px;
        color: #353839;
      }
      &__contact {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 5px;
        & > img {
          max-width: 100%;
          height: 20px;
          margin-right: 5px;
        }
        & > a {
          font-size: 13px;
          color: #ea5151;
          border-bottom: 1px dotted #ea5151;
        }
      }
    }
  }

  footer {
    position: fixed;
    width: 100%;
    bottom: 0;

    & > button {
    }
  }
}

@media screen and (min-width: 767px) {
  .premium-order-status-page {
    background-color: #f3f6fa;
    .web-header {
      margin: 0 -20px;
    }

    main {
      width: 100%;
      flex: 1;
      max-width: 420px;
      margin: 0 auto;
      background-color: #fff;
      padding: 0 0 55px;
      min-height: 100%;

      .order-status {
        padding: 20px 30px;

        &__processing {
          // max-width: 300px;
        }
      }
    }
  }
}
