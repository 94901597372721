.redirectionWebConsoleModal {
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9999999;

  &-container {
    background: transparent
      linear-gradient(69deg, #fcddc8 0%, #fdeadc 56%, #fef1e8 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 0px 16px 36px #0000005c;
    border-radius: 8px;
    width: 560px;
    padding: 59px 50px 68px;

    & .image-container {
      left: -20px;
    }

    & .progress-bar-container {
      width: 100%;
      height: 6px;
      position: relative;
      overflow: hidden;
      background: #ffffff;

      & .bar {
        position: absolute;
        width: calc(100% * 2 / 4);
        height: 100%;
        display: flex;
        animation: move 2s linear infinite;
        background-color: #3d6afd;
      }
    }

    & .sub-text {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.53);
    }
  }

  &--header {
    font-size: 28px;
  }
}

@keyframes move {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(calc(7 / 2 * 100%), 0);
  }
}
