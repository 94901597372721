.filter-sorting {
  position: relative;
  max-width: 278px;
  &__box {
    border: 1px solid #c7c7c7;
    padding: 17px 21px;
    @media screen and (max-width: 766px) {
      & {
        padding: 15px 21px;
      }
    }
    & p {
      font-size: 14px;
      @media screen and (max-width: 766px) {
        & {
          font-size: 12px;
        }
      }
    }

    & img {
      width: 15px;
      @media screen and (max-width: 766px) {
        & {
          width: 12px;
        }
      }
    }

    @media screen and (max-width: 766px) {
      & {
        border: 0.4px solid #c7c7c7;
      }
    }
  }
  &__dropdown {
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    position: absolute;
    display: none;
    width: 100%;
    z-index: 5;
    top: calc(100% + 2px);
    left: 0;
    padding: 14px 0;

    &.active {
      display: inline-block;
    }
    &__checkbox-container,
    &--item {
      padding: 14px 21px;
      background-color: #ffffff;
      transition: background-color 100ms linear;
      font-weight: 500;
      &:hover {
        background-color: #f4f4f4;
      }
    }
    &--item {
      &.active {
        color: #3b33ff;
      }
    }
    &__checkbox-container {
      & p {
        font-size: inherit;
        color: inherit;
      }
      & img {
        width: 20px;
        height: 20px;
      }
      & .checkbox-empty {
        border: 1px solid #c7c7c7;
        border-radius: 4px;
        width: 20px;
        height: 20px;
      }
    }
    hr {
      margin: 14px 0;
      border: none;
      border-top: 1px solid rgba(112, 112, 112, 0.17);
    }
  }
}
