.spotlightStoreSearch {
  min-height: 100vh;
  position: relative;

  &__header {
    padding-bottom: 50px;
    padding: 14px 20px 140px 12px;
    background: transparent linear-gradient(180deg, #fcdacb 0%, #ffffff00 100%)
      0% 0% no-repeat padding-box;

    &--title {
      display: flex;
      font-size: 21px;
      font-weight: 600;
      letter-spacing: 0.3px;
      color: #202124;
      margin: 16px 24px 20px 12px;

      & > .back--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        & > img {
          max-width: 100%;
          height: 24px;
          filter: invert(1);
          margin-right: 6px;
        }
      }
    }

    &--search {
      padding: 0 4px 0 12px;

      & > .searchbar-wrapper {
        width: inherit;
        left: 0;
        z-index: 99;

        & > .searchbar {
          box-shadow: 0px 1px 3px #00000029;
          border-radius: 20px;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 30vh;
    padding: 50px 20px;
    width: 100%;
    margin-top: -150px;

    & > .view-more-btn {
      opacity: 0;
      border: 0.800000011920929px solid #ea5151;
      font-size: 10px;
      width: auto;
      color: #ea5151;
      padding: 6px 10px;
      position: absolute;
      bottom: 35px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;

      &:hover {
        color: #ffffff;
        background-color: #ea5151;
      }
    }
  }

  &__footer {
    // position: absolute;
    // bottom: 0;
    padding: 20px;
    width: 100%;

    &--title {
      color: #a9a9a9;
      font-size: 13px;
    }

    &--chipsContainer {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-top: 10px;
      width: 100%;

      .category-chips {
        font-size: 11px;
        padding: 5px 14px;
        margin: 0 5px 5px 0;
        border-radius: 18px;
        border: 1px solid #ea5151;
        color: #ea5151;
      }
    }

    .category-chips-dummy {
      padding-top: 10px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      & > span {
        padding: 16px 50px;
        border-radius: 18px;
        margin: 0 5px 5px 0;
      }

      .animate {
        animation: load 1.8s linear infinite;
        animation-fill-mode: forwards;
        background: #e8e8e8;
        background-image: linear-gradient(
          to left,
          #dddddd 0%,
          #f5f5f5 20%,
          #efefef 40%,
          #dddddd 100%
        );
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}

@media screen and (min-width: 740px) {
  .spotlightStoreSearch {
    display: block;
    margin: auto;
    max-width: 420px;
    border: 2px solid #e2e2e2;
  }
}
