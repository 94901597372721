.spotlight {

  &__header {
    background: transparent linear-gradient(180deg, #FCDACB 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    padding-bottom: 50px;

    &--head {
      padding: 14px 20px 10px 12px;
      display: flex;
      justify-content: space-between;

      &>.back--icon {
        &>img {
          max-width: 100%;
          height: 24px;
          filter: invert(1);
        }
      }

      & > .search--icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #FFFFFF;
        width: 100%;
        padding: 10px;
        border-radius: 14px;
        box-shadow: 0 2px 2px 2px #ffd5c1;
        font-size: 14px;
        color: #8f8f8f;
        &>img {
          max-width: 100%;
          height: 16px;
          margin-right: 5px;
        }
      }
    }

    &--body {
      padding: 12px 24px;
    }

    &--wrap {
      display: flex;
      justify-content: space-between;
      & > .search--icon {
        &>img {
          max-width: 100%;
          height: 20px;
          margin-right: 5px;
        }
      }
    }

    &--contentwrapper {
      padding: 0 24px;
    }

    &--footer {
      display: block;
      padding: 15px 10px 0 10px;

      .bussiness__type {
        display: block;
        background-color: #FFFFFF;
        border-radius: 12px;
        box-shadow: 0 0px 6px 2px #00000029;
        min-height: 200px;

        &--box {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          padding: 14px 8px 8px 8px;
          grid-gap: 12px;
        }

        &--category {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          &-img {
            width: 57px;
            height: 57px;

            &>img {
              border-radius: 50px;
              box-shadow: 0px 1px 4px 1px #e2e2e2;
              max-width: 100%;
              height: 100%;
            }
          }

          &-name {
            font-size: 11px;
            font-weight: 400;
            text-align: center;
            padding-top: 14px;
            line-height: 1.4em;
          }
        }
      }
    }

    &--title {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.3px;
      color: #202124;
      margin: 0 0 8px 0px;
    }

    &--location {
      display: flex;
      align-items: center;
      flex-flow: wrap;

      &-name {
        font-size: 21px;
        font-weight: 700;
        color: #000000;
        padding-bottom: 3px;
        border-bottom: 2px solid #EA5151;
        margin-right: 12px;
      }

      &-dummy {
        width: 100px;
        padding: 10px;
        margin-right: 10px;
      }

      &-change-btn {
        border: 0.800000011920929px solid #EA5151;
        font-size: 10px;
        letter-spacing: 0.14px;
        max-height: 26px;
        width: auto;
        color: #EA5151;
        padding: 0 7px;
        margin: 12px 0;

        &>img {
          max-width: 100%;
          height: 12px;
          margin: 0 0 0 3px;

        }
      }
    }

    .animate {
      animation: load 1.8s linear infinite;
      animation-fill-mode: forwards;
      background: #eadfd9;
      background-image: linear-gradient(to left, #dddddd 0%, #f5f5f5 20%, #efefef 40%, #dddddd 100%);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

/** Slider CSS */
.bussiness__type>.slick-slider.slick-initialized {
  padding: 0px;
  background-color: transparent;
  border-radius: 8px;
}

.bussiness__type>.slick-initialized .slick-slide {
  padding: 0 !important;
}