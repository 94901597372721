.order-confirm-page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #59b528;
  text-align: center;
  min-height: 100vh;

  .container {
    .icon-wrap {
      margin-bottom: 40px;
    }

    h3 {
      font-size: 20px;
    }
  }

  svg {
    width: 100px;
    display: block;
    margin: 40px auto 0;
  }

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }

  .path.anime {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;

    &.circle {
      -webkit-animation: dash 1.2s ease-in-out;
      animation: dash 1.2s ease-in-out;
    }

    &.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash 1.2s 0.35s ease-in-out forwards;
      animation: dash 1.2s 0.35s ease-in-out forwards;
    }

    &.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check 1.2s 0.35s ease-in-out forwards;
      animation: dash-check 1.2s 0.35s ease-in-out forwards;
    }
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}
