.auth-modal-wrap {
  h2 {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .closeicon {
    position: absolute;
    top: 20px;
    right: 20px;
    img {
      max-width: 20px;
      filter: grayscale(100);
    }
  }
  .small-heading {
    color: #9ea6b9;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 15px;
    display: block;
  }
  .form-group {
    margin-top: 10px;
    position: relative;
    .form-control {
      width: 100%;
      background-color: #f6f6f6;
      padding: 15px;
      border: 1px solid #191b26;
      border-radius: 10px;
      // font-family: var(--regular-font);
      padding-left: 40px;
    }
    img {
      position: absolute;
      top: 15px;
      max-width: 20px;
      left: 15px;
    }
  }
  .bottom-txt {
    font-size: 12px;
    font-weight: 400;
    margin: 10px 0 30px 0;
    // font-family: var(--light-font);
    margin-bottom: 30px;
    display: block;
    line-height: 16px;
  }

  .catalog-custom-btn {
    padding: 15px;
    font-size: 18px;
    text-transform: uppercase;
    border-radius: 30px;
    font-weight: 600;
  }
}
