.search-page {
  justify-content: center;
  background-color: #fff;
  min-height: 100vh;

  .loader-wrapper {
    padding: 10px 20px;
  }

  .search-header {
    display: flex;
    background-color: #fff;
    align-items: center;
    font: normal normal bold 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #484848;
    padding: 16px 0 11px 20px;

    & > span {
      display: block;
      font-size: 17px;
      align-items: center;
      font: normal normal bold 18px/22px Montserrat;
      letter-spacing: 0px;
      color: #484848;
      & > img {
        max-height: 25px;
        filter: invert(1);
      }
    }
  }

  .search-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 24px;
  }

  .search-content {
    &__box {
      padding: 30px 16px 60px 16px;

      & > .catalog-item > .catalog-item__left-catalog {
        padding-left: 24px;
      }
    }
    &__notfound {
      padding: 24px 0 0 0;
      display: flex;
      flex-direction: column;

      & > span {
        align-self: center;
        font: normal normal normal 12px/18px Montserrat;
        letter-spacing: 0.36px;
        color: #777777;
      }

      & > div {
        margin-top: 10%;
      }
    }
  }

  .search-bottom {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    padding: 10px 16px;
    z-index: 99;

    & > button {
      border: 1px solid #484848;
      border-radius: 12px;
      font: normal normal 600 18px/22px Montserrat;
      letter-spacing: 1px;
      color: #484848;
      background-color: #fff;
      width: 100%;
      padding: 10px 0;

      &:hover {
        background-color: #000000;
        color: #fff;
      }
    }
  }
}

@media (min-width: 991px) {
  .search-page {
    .search-content {
      &__notfound {
        display: none;
      }

      &__box {
        width: 50%;
        transform: translateX(50%);
      }
    }
  }
}

.search-page .premium-search-bar {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 767px) {
    padding-top: 20px;
  }
}
