.product-item {
  width: 100%;
  background: #fff 0 0 no-repeat padding-box;
  border-radius: 6px;
  width: 100%;
  height: 100%;

  .top-wrap {
    min-height: 150px;

    .catalog-tem-img {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      background-color: #f6f6f6;
      height: auto;
      min-height: 140px;
      max-width: 100%;
      border-radius: 6px 6px 0 0;

      .image {
        max-width: 100%;
        height: auto;
        border-radius: 6px 6px 0 0;
        min-height: 130px;
      }

      .info-icon {
        position: absolute;
        top: 4px;
        right: 4px;
        max-width: 100%;
        height: 24px;
      }

      .discountInfo-chip {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        position: absolute;
        bottom: 0px;

        & > span {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.67px;
          color: #ffffff;
          background: #23bf44 0% 0% no-repeat padding-box;
          border-radius: 0px 11px 11px 0px;
          padding: 2px 7px 1px 3px;
        }
      }
    }
  }

  .bottom-wrap {
    padding-bottom: 24px;
  }

  .item-shadow {
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
    border-radius: 0 0 6px 6px;
  }

  .item-name {
    display: -webkit-box;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.7;
    letter-spacing: 0.36px;
    color: #000;
    margin-bottom: 5px;
    height: 44px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 4px;
    text-decoration: none;
  }

  .item-name-list {
    display: flex;
    width: 70%;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.36px;
    color: #202431;
    margin-bottom: 4px;
  }

  .price {
    font-size: 13px;
    letter-spacing: 0.67px;
    color: #797888;
    margin-right: 4px;
    font-weight: 600;

    .amount-price {
      text-decoration: line-through;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.67px;
      color: #6f6f7c;
      margin-right: 7px;
    }

    .percent-off {
      font-size: 13px;
      letter-spacing: 0.72px;
      font-weight: 400;
      color: #0dbf44;
    }
  }

  .without-image-item {
    .left-wrap {
      width: 70%;
      padding: 14px 0;
      border-bottom: 1px dashed #bfbfbf;
    }
    .right-wrap {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-basis: 125px;
      & .item-image {
        position: relative;
        top: 0rem;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        &.out-of-stock-item {
          filter: grayscale(100);
        }
        @media screen and (min-width: 551px) {
          width: 80px;
          height: 80px;
        }
        @media screen and (max-width: 550px) {
          width: 80px;
          height: 80px;
        }
      }
      & > .btn-comp {
        position: relative;
        bottom: 1rem;
      }
    }
  }

  .outofstock {
    color: #2e2e2e;
    opacity: 0.3;
  }

  .catalog-item-description {
    &-text {
      font-size: 12px;
      letter-spacing: 0.61px;
      color: #9c9c9c;
      padding: 4px 10px;
      width: 80%;
      line-height: 1rem;
    }

    &-readmore {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.61px;
      color: #fb3f4fbf;
      padding-left: 4px;
    }
  }
}

.product-cart-type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px 10px 10px;

  .left-wrap {
    flex: auto;
    margin-right: 20px;
    line-height: 1em;

    & > span {
      padding-bottom: 4px;
      width: 100%;
    }

    & > div {
      padding-bottom: 5px;
    }

    border-bottom: 1px dashed #dddddd;
  }

  .right-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.card-type {
  border: 0.4000000059604645px solid #e8e8e8;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
}

.product-item-desktop {
  display: flex;
  padding: 15px 0px;
  min-height: 95px;
  border-bottom: 1px solid #f2f2f2;

  .product-image {
    position: relative;
    min-width: 30%;
    height: 120px;
    background-color: #202020;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0 0 4px #e2e2e2;
    border-radius: 5px;

    & > img {
      width: 24px;
      height: 24px;
      float: right;
      max-width: 100%;
    }

    & > span {
      position: absolute;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.67px;
      color: #fff;
      background: #23bf44 0 0 no-repeat padding-box;
      border-radius: 0 11px 11px 0;
      padding: 2px 6px 2px 4px;
      margin-top: 5px;
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: auto;
    padding: 10px 0;

    &__name {
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.36px;
      color: #202431;
      margin-bottom: 4px;
    }

    &__options {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .price {
    font-size: 13px;
    letter-spacing: 0.67px;
    color: #797888;
    margin-right: 4px;
    font-weight: 600;

    .amount-price {
      text-decoration: line-through;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.67px;
      color: #6f6f7c;
      margin-right: 7px;
    }

    .percent-off {
      font-size: 13px;
      letter-spacing: 0.72px;
      font-weight: 400;
      color: #0dbf44;
    }
  }
  .catalog-item-description {
    &-text {
      font-size: 12px;
      letter-spacing: 0.61px;
      color: #9c9c9c;
      padding: 4px 0px;
      width: 80%;
      line-height: 1rem;
    }

    &-readmore {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.61px;
      color: #fb3f4fbf;
      padding-left: 4px;
    }
  }
}

.variant--count-icontext {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  padding: 4px 0;
  opacity: 0.5;
  & > img {
    max-width: 100%;
    height: 14px;
    margin-right: 2px;
  }
}

.pi-variant_name {
  font-size: 11px;
  font-weight: 300;
}
