.horizontal-category-display-widget {
  &__title {
    font-size: 18px;
    margin: 64px 0 72px 0;
  }
  & .arrow-btns {
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    padding: 20px;
    height: 5rem;
    @media screen and (max-width: 766px) {
      padding: 15px;
      height: 4rem;
    }
  }

  .scroll-wrapper {
    display: flex;
    min-height: 200px;
    overflow-y: hidden;
    padding: 10px 0;
    scrollbar-width: none;

    &:first-child {
      min-width: 50px;
    }

    & > article {
      margin-right: 24px;
      min-width: 300px;
    }

    &::-webkit-scrollbar {
      scrollbar-width: thin;
      width: 0px;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    & .view-more-box {
      &--inner {
        border: 0.4px solid #6f6e6e;
        width: 100%;
        &:hover {
          border-color: #000000;
        }
        & > span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &:hover {
        & > div > span {
          color: #000000;
          & > span {
            width: 100%;
          }
        }
      }
    }
  }
  & > .slick-initialized .slick-slide > div {
    margin: 0 0 0 15px;
  }
  & .category-card {
    margin-right: 30px;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    @media screen and (max-width: 767px) {
      min-width: 40vw;
      margin-right: 10vw;
    }
  }
}

@include respond-to('small') {
  .horizontal-category-display-widget {
    &__title {
      font-size: 26px;
    }

    .scroll-wrapper {
      & > .category-card-3 {
        margin-right: 24px;
        min-width: 400px;
      }
    }
  }
}
