.sortingTabMobile {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(209, 209, 209);
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
  width: 50vw;
  height: 48px;

  .sortingTabText {
    display: flex;
  }

  .dropDownContainerMobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 6;
    padding-left: 22px;
    top: -519%;
    margin-top: 10px;
    left: -51vw;
    min-width: 101vw;
    padding-top: 25px;
    border: 1px solid rgb(209, 209, 209);
    background: white;

    .sortByHeading {
      display: flex;
      flex-direction: column;
      font-size: 16px;

      .borderDiv {
        background-color: rgb(237, 237, 237);
        width: 100vw;
        height: 1px;
        margin-top: 16px;
        margin-bottom: 22px;
        transform: scaleX(1.2);
      }
    }
  }

  .dropDownArrow {
    display: flex;
    align-items: center;
  }
}