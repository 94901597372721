.pre-pdp-page {
  min-height: 100vh;
  padding: 0;

  .pdp__main {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 60px;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    &--content {
      display: flex;
      padding-bottom: 20px;

      & > div {
        flex: 1;
      }

      .flexAuto {
        flex: 0 1 auto;
      }

      &--leftContent {
        position: sticky;
        top: 96px;
        @media screen and (max-width: 766px) {
          position: static;
        }
        & > .box--price > .box--addToCart-btn {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 20px 0;
          width: 100%;

          & > button {
            width: 50%;
          }

          & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: auto;
            width: 50%;
          }

          .go-to-cart__btn {
            position: relative;
            background-color: #369d00;
            color: #ffffff;
            margin-left: 15px;
            cursor: pointer;
            font-size: 17px;
            font-weight: 500;
            padding: 18px 0;

            & > span {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 20px;
              width: 20px;
              padding: 5px;
              color: #ffffff;
              background-color: #e7b000;
              font-size: 10px;
              border-radius: 30px;
              position: absolute;
              top: 4px;
              left: 25px;
            }
          }
        }
      }

      &--remarks-input-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        &__input {
          border: 1px solid #e2e3e3;
          font-size: 14px;
          width: 100%;
          padding: 14px 66px 14px 18px;
          border-radius: 8px;

          &::placeholder {
            color: #b9b9b9;
          }
        }

        &__text-counter {
          position: absolute;
          color: #b1b1b1;
          font-size: 12px;
          right: 16px;
          top: 3.5px;
        }
      }
    }

    &--details {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 20px 40px;
      flex: 1;
      justify-content: flex-start;

      .details__box {
        &-gst-tax-inclusive {
          font-size: 14px;
          color: #797a7c;
          margin-top: 10px;
          @media screen and (max-width: 767px) {
            font-size: 12px;
            color: #525252;
            margin-top: 4px;
          }
        }
        &-gst-tax-exclusive {
          font-size: 12px;
          color: #797a7c;
          margin-top: 10px;
          @media screen and (max-width: 767px) {
            font-size: 12px;
            color: #525252;
            margin-top: 4px;
          }
        }
        .radio-groups {
          .radio-item {
            border: 1px solid #dedede;
            padding: 15px;
            border-radius: 8px;
            margin: 10px 0;
            font-size: 13px;

            & > input {
              margin-right: 10px;
            }

            & > input[type='radio']:checked {
              color: #ea5151;
              background-color: #ea5151;
              border-color: #ea5151;
            }
          }

          & > .active {
            border: 1px solid;
            font-weight: 500;
          }
        }

        &--name {
          font-size: 18px;
          font-weight: 500;
          color: #2a2a2a;
          padding-bottom: 5px;
          line-height: 1.4em;
        }

        &--categoryname {
          font-size: 14px;
          font-weight: 600;
          color: #a3a4a5;
        }

        &--store-closed {
          display: flex;
          flex: 1;
          padding-top: 10px;

          &-icon {
            width: 12px;
            height: 12px;
            margin-right: 10px;
          }

          &-para {
            & > p {
              font-size: 12px;
              color: #404040;
              font-weight: 400;
              line-height: 1.5;
              margin: 0;

              & > a {
                color: #000000;
                text-decoration: underline;
                font-weight: 500;
                cursor: pointer;

                &:visited,
                &:active,
                &:hover {
                  color: #000000;
                }
              }

              & > span {
                color: #000000;
                text-decoration: underline;
                font-weight: 500;
                cursor: pointer;
              }
            }
          }
        }

        &--price {
          display: flex;
          padding-top: 20px;

          @media screen and (max-width: 766px) {
            &-container {
              display: flex;
              flex-direction: column;
              margin-right: 20px;
            }
          }

          &-value {
            display: inline-block;
            height: fit-content;
          }

          &-discounted {
            font-weight: 500;
          }

          &-actual {
            text-decoration: line-through;
            color: #abadae;
            font-weight: 300;
          }

          &-percentage {
            font-weight: 500;
            color: #ffffff;
            background-color: #369d00;
            padding: 2px 7.5px;
          }

          & > span:not(:last-child) {
            margin-right: 15px;
          }
        }

        &--descTitle {
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            font-weight: 600;
          }
        }

        &--descText {
          font-size: 14px;
          margin-bottom: 0px;
          font-weight: 400;
          color: rgba(49, 49, 49, 1);
          line-height: 1.4em;
          white-space: pre-line;
          margin-top: 8px;
          word-break: break-word;

          & a,
          & a:visited,
          & a:link {
            color: rgb(0, 97, 224);
            font-weight: 600;
          }

          &-utility {
            font-size: 11px;
            font-weight: 600;
            color: #2a2a2a;
            line-height: 1.4em;
            cursor: pointer;
          }
        }

        &--additional-offer {
          margin-top: 14px;

          & > div {
            font-size: 14px;
            font-weight: 400;
            padding-bottom: 10px;

            & > span {
              color: rgb(61, 106, 253);
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    &--suggestion {
      padding: 20px 0px 20px 0px;
      border-top: 1px solid #eaeaea;

      & > h3 {
        font-weight: 500;

        @media screen and (max-width: 766px) {
          text-align: left;
          padding: 0 20px;
        }
      }

      .suggestion__box {
        display: flex;
        min-height: 200px;
        overflow-y: hidden;
        padding: 10px 0 40px;
        scrollbar-color: transparent transparent;

        &:hover {
          scrollbar-color: grey transparent;
          scrollbar-width: thin;
        }

        & > :first-child {
          min-width: 5px;
        }

        & > div {
          min-width: 200px;
          max-width: 200px;
          width: 200px;
          height: auto;
        }

        & > .suggestion__box--viewall {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          min-width: 100px;
          align-self: center;

          & > span {
            padding: 10px;
            font-size: 12px;
            font-weight: 600;
            border-radius: 31px;
            color: white;
            border: 1px solid #ea5151;
          }
        }
      }
    }
  }

  .pdp__footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;
    background-color: #ffffff;
    box-shadow: 0px -3px 6px #00000014;

    .out-of-stock {
      background-color: #000000;
    }

    .add-to-cart-btn {
      width: 100%;
      display: block;
      font-size: 18px;
      text-transform: uppercase;
      border-radius: 30px;
      font-weight: 600;
      padding: 15px;
    }

    &--addedToCart {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .addToCart-text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 13px;

        & > img {
          max-width: 100%;
          height: 22px;
          margin-right: 4px;
        }
      }
    }
  }

  .fade-gray {
    filter: invert(0.5);
  }
}

.utility-func {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 767px) {
  .html-description {
    overflow-wrap: break-word;
  }
  .pre-pdp-page {
    .pdp__main {
      &--content {
        flex-direction: row;
      }

      &--details {
        width: 60%;
        overflow: auto;

        .details__box {
          &--price {
            align-items: center;

            &-discounted {
              font-size: 18px;
              margin-right: 10px;
              font-weight: 600;
            }

            &-actual {
              font-size: 15px;
              margin-right: 10px;
            }

            &-percentage {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 766px) {
  .pre-pdp-page {
    .pdp__main {
      padding-top: 0px;
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      overflow: auto;
      overflow-x: hidden;

      .box--price > .box--addToCart-btn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 4;
        background-color: #ffffff;
        padding: 30px 20px;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.4);
        height: 48px;
        & > button {
          font-size: 12px;
        }
        .go-to-cart__btn {
          margin-left: 0px;
          padding: 16px 0px;
        }

        & > div {
          border: 1px solid #ededed;
        }
      }

      &--details {
        padding: 0px;

        & > :last-child {
          border-bottom: 0 !important;
        }

        .details__box {
          padding: 20px;

          &--price {
            &-discounted {
              font-size: 15px;
              font-weight: 600;
            }

            &-actual {
              font-size: 10px;
            }

            &-percentage {
              font-size: 10px;
            }
          }
        }
      }

      &--content {
        flex-direction: column;

        &--remarks-input-wrapper {
          position: relative;

          &__input {
            border: 1px solid #e2e3e3;
            font-size: 14px;
            width: 100%;
            padding: 14px 64px 14px 18px;
            border-radius: 8px;
            margin: 16px 16px 0;

            &::placeholder {
              color: #b9b9b9;
            }
          }

          &__text-counter {
            position: absolute;
            color: #b1b1b1;
            font-size: 12px;
            right: 2px;
            top: 18.5px;
          }
        }
      }
    }

    footer {
      margin-bottom: 80px;
    }
  }
}

@media screen and (max-width: 766px) {
  .html-description {
    overflow-wrap: break-word;
    padding: 0 20px;
  }

  .pdp__main {
    &--content {
      &--remarks-input-wrapper {
        max-width: 92%;

        &__input {
          max-width: 100%;
          margin: 0;

          &.no-count-shown {
            padding: 14px 18px 14px 18px;
          }
        }
      }
    }
  }
}
