.collection-header {
  & > div:first-child {
    position: relative;
    z-index: 1;
  }

  .dropdown {
    z-index: 0;
    position: fixed;
    top: 0px;
    height: 100vh;
    padding-top: 75px;
    left: 0;
    width: 100%;
    transition: left 150ms ease-in;

    &.active {
      left: -100vw;
    }

    & > .nav-state {
      width: 100vw;
      height: fit-content;
      position: absolute;
      left: 0;
      height: 100%;
      overflow-y: auto;
    }

    &--menu {
      list-style: none;
      padding: 10px 20px 30px 20px;
      border-bottom: 1px solid #dedede;
      box-shadow: 0px 3px 6px #00000029;
      background-color: #ffffff;

      & .menu-item {
        width: 100%;

        & > img {
          margin-left: 6px;
          width: 7px;
        }

        &:not(:first-child) {
          margin: 29px 0 0;
        }
      }
    }

    & .collection-list-container {
      height: 100%;
      width: 100vw;
      overflow: hidden;
      background-color: #ffffff;
      position: absolute;
      left: 100vw;
      padding-bottom: 5rem;

      &__header {
        padding: 20px;
        border-bottom: 1px solid #e5e5e5;

        & > p {
          margin: 0;
          font-size: 14px;
        }
      }

      &__body {
        padding: 25px 24px 100px 24px;
        height: 100%;
        overflow-y: auto;

        & .collection-item {
          width: 100%;
          position: relative;
          overflow: hidden;
          margin-bottom: 24px;

          & .collection-image {
            object-fit: cover;
            inset: 0px;

            & > img {
              object-fit: cover;
              width: 100%;
              height: 100%;
              object-position: center center;
            }
          }
        }
        &.collection-header-widget-1 {
          & .collection-item {
            height: 100px;
            & .collection-image {
              position: absolute;
              filter: brightness(0.5);
            }

            & .collection-name {
              margin: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              padding: 10px;
              transform: translate(-50%, -50%);
              z-index: 5;
              font-size: 14px;
              color: #ffffff;
              font-weight: 500;
              text-align: center;
            }
          }
        }

        &.collection-header-widget-2 {
          & .collection-item {
            height: auto;
            & .collection-image {
              height: 100px;
              position: relative;
            }
            & .collection-name {
              margin: 8px 0 0;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }

        &.collection-header-widget-3 {
          padding: 0px 0px 60px;
          & .collection-accordion {
            &__container {
              border-bottom: 1px solid #e5e5e5;
            }
            &__header {
              padding: 20px 24px;
              & > p {
                font-size: 14px;
                font-weight: 500;
                & + img {
                  width: 10px;
                }
              }
            }
            &__body {
              & .body-list {
                list-style-type: none;
                padding: 0 24px;
                &--item {
                  font-size: 14px;
                  font-weight: 400;
                  color: #000000;
                  margin-bottom: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
