.paper-list {
  display: flex;
  flex-direction: row;
  padding-top: 20px;

  & :first-child {
    padding-right: 4px;
    flex-grow: 2;
    width: 70%;
    text-align: left;
  }
  & > input {
    font: normal normal normal 13px/16px Montserrat;
    letter-spacing: -0.26px;
    flex-grow: 1;
    border-bottom: 1px solid #000;
    width: 30%;
    outline: 0;
    border: 0;
    margin: 0 16px;
    border-bottom: 1px solid #000;
    text-align: center;

    & ::placeholder {
      color: #989898;
    }
  }
}
