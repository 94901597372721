.addtocart-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.800000011920929px solid #bfbebe;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  padding: 4px 0;
  height: 27px;
  width: 70px;
  cursor: pointer;
  position: relative;

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #323131;
    font-size: 12px;
    font-weight: 600;
    width: 10px;
  }

  &__changeIcon {
    padding: 1px 10px;
    font: normal normal 600 12px Montserrat;
    letter-spacing: 0.72px;
    color: #f4071b;
    font-weight: 600;
    display: flex;
    align-items: center;

    & > span {
      margin-right: 4px;
    }
    & > div > img,
    & > img {
      // font: normal normal 600 20px/16px Montserrat;
      display: inline;
      width: 9px;
    }
  }
}

.pdp-addtocart-btn {
  // background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #ea5151;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  height: 48px;
  width: 153px;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  position: relative;

  &__text {
    display: flex;
    justify-content: center;
    color: #323131;
    font-size: 20px;
    font-weight: 600;
    width: 45px;
  }

  &__changeIcon {
    padding: 1px 10px;
    font: normal normal 600 12px Montserrat;
    letter-spacing: 0.72px;
    color: #f4071b;
    font-weight: 600;
    display: flex;
    align-items: center;

    & > span {
      color: #ffffff;
      font-size: 20px;
      letter-spacing: 2px;
    }
    & > img {
      display: inline;
      width: 16px;
    }
  }
}

.addtocart-btn,
.pdp-addtocart-btn {
  .loading-wrapper {
    width: 20%;
    padding: 1px;
    background: red;
    position: absolute;
    left: 20%;
    bottom: 0;
    transform: translateX(0%);
    -webkit-animation: paymentProgressSlide 1s infinite;
    animation: paymentProgressSlide 1s infinite;
  }
}
