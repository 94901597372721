.variantModal {
  position: relative;

  .variantModal-section {
    display: flex;
    padding: 0px 20px;
    position: relative;

    .variantModal-closeicon {
      position: absolute;
      top: 0px;
      right: 10px;
      cursor: pointer;

      img {
        max-width: 20px;
        filter: grayscale(100);
      }
    }
  }

  .variant-list {
    padding: 0px 20px 100px 20px;
    max-height: 400px;
    overflow: auto;
  }

  .add-to-cart {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 999;
    background-color: #ffffff;
    padding: 20px 20px 0 20px;
    box-shadow: 0px -3px 6px #00000014;
    & button {
      width: 100%;
      padding: 15px 0;
      background-color: #ea5151;
      color: #ffffff;
      font-size: 15px;
      font-weight: 600;
      border: none;
      border-radius: 30px;
    }
  }
}
