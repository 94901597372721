.wb-sprite {
  background: url('../../../../public/assets/images/builder-sprite.svg') no-repeat;
  background-size: 2727px 525px;
  text-decoration: none;
  border: none;
  outline: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
  &.forward-arrow {
    background-position: -809px -34px;
    width: 20px;
    height: 20px;
    &.inverted {
      transform: rotate(-180deg);
    }
  }
  &.backword-arrow {
    background-position: -893px -36px;
    width: 20px;
    height: 20px;
  }
  &.ic-info-grey {
    background-position: -694px -36px;
  }
  &.ic-six-dots {
    background-position: -1097px -32px;
    width: 24px;
    height: 24px;
  }
  &.ic-arrow-down {
    background-position: -865px -36px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &.inverted {
      transform: rotate(-180deg);
    }
  }
  &.ic-align-left {
    background-position: -646px -60px;
    &.faded {
      opacity: 0.7;
    }
  }
  &.ic-align-center {
    background-position: -694px -60px;
    &.faded {
      opacity: 0.7;
    }
  }
  &.ic-align-right {
    background-position: -622px -84px;
    &.faded {
      opacity: 0.7;
    }
  }
  &.ic-delete-grey {
    background-position: -1033px -36px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    &:hover {
      background-position: -1065px -36px;
    }
  }
  &.ic-cross-black {
    background-position: -921px -36px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  &.ic-redirect-blue {
    background-position: -694px -108px;
  }
  &.ic-plus-blue {
    background-position: -447px -38px;
    width: 12px;
    height: 12px;
  }
  &.ic-checkbox-checked {
    background-position: -622px -56px;
    width: 24px;
    height: 24px;
  }
  &.ic-box-checked {
    background-position: -598px -156px;
    width: 16px;
    height: 16px;
  }
  &.ic-color-icon {
    background-position: -694px -180px;
    width: 16px;
    height: 16px;
  }
  &.ic-color-icon-active {
    background-position: -646px -180px;
    width: 16px;
    height: 16px;
  }
  &.ic-transparent {
    background-position: -670px -180px;
    width: 16px;
    height: 16px;
  }
  &.ic-transparent-active {
    background-position: -598px -204px;
    width: 16px;
    height: 16px;
  }
  &.ic-none {
    background-position: -1481px -32px;
    width: 32px;
    height: 32px;
  }
  &.ic-underline {
    background-position: -2385px -32px;
    width: 68px;
    height: 36px;
  }
  &.ic-underline-header {
    background-position: -2553px -32px;
    width: 68px;
    height: 36px;
  }
  &.ic-background-box {
    background-position: -2469px -32px;
    width: 68px;
    height: 36px;
  }
  &.ic-bold {
    background-position: -1161px -68px;
    width: 24px;
    height: 24px;
  }

  &.ic-done-white {
    background-position: -467px -38px;
    width: 12px;
    height: 12px;
  }
  &.ic-lock-icon {
    background-position: -865px -64px;
    width: 20px;
    height: 20px;
  }
  &.ic-whatsapp {
    background-position: -1065px -68px;
    width: 24px;
    height: 24px;
  }
  &.ic-cart-16 {
    background-position: -622px -228px;
    width: 16px;
    height: 16px;
  }
  &.ic-cart-20 {
    background-position: -837px -92px;
    width: 20px;
    height: 20px;
  }
  &.ic-cart {
    background-position: -1129px -68px;
    width: 24px;
    height: 24px;
  }
  &.ic-cart-28 {
    background-position: -1329px -36px;
    width: 28px;
    height: 28px;
  }
  &.ic-cart-32 {
    background-position: -1529px -80px;
    width: 32px;
    height: 32px;
  }

  &.ic-search-16 {
    background-position: -694px -204px;
    width: 16px;
    height: 16px;
  }
  &.ic-search-20 {
    background-position: -921px -64px;
    width: 20px;
    height: 20px;
  }
  &.ic-search-20-grey {
    background-position: -893px -64px;
    width: 20px;
    height: 20px;
  }
  &.ic-search {
    background-position: -1033px -68px;
    width: 24px;
    height: 24px;
  }
  &.ic-search-28 {
    background-position: -1257px -36px;
    width: 28px;
    height: 28px;
  }
  &.ic-search-32 {
    background-position: -1577px -32px;
    width: 32px;
    height: 32px;
  }
  &.ic-user-16 {
    background-position: -598px -228px;
    width: 16px;
    height: 16px;
  }
  &.ic-user-20 {
    background-position: -809px -92px;
    width: 20px;
    height: 20px;
  }
  &.ic-user {
    background-position: -1097px -68px;
    width: 24px;
    height: 24px;
  }
  &.ic-user-28 {
    background-position: -1293px -36px;
    width: 28px;
    height: 28px;
  }
  &.ic-user-32 {
    background-position: -1481px -80px;
    width: 32px;
    height: 32px;
  }

  &.ic-align-left-new {
    height: '16px';
    width: '16px';
    background-position: -622px -156px;
    &.faded {
      opacity: 0.6;
    }
  }
  &.ic-align-center-new {
    height: '16px';
    width: '16px';
    background-position: -646px -156px;
    &.faded {
      opacity: 0.6;
    }
  }
  &.ic-image {
    height: '24px';
    width: '24px';
    background-position: -811px -66px;
    &.faded {
      opacity: 0.6;
    }
  }

  &.ic-align-right-new {
    height: '16px';
    width: '16px';
    background-position: -670px -156px;
    &.faded {
      opacity: 0.6;
    }
  }
  &.ic-cross-circle {
    background-position: -597px -107px;
    width: 18px;
    height: 18px;
  }
  &.ic-search-16 {
    background-position: -670px -36px;
    width: 18px;
    height: 18px;
  }
  &.ic-hamburger {
    background-position: -1161px -36px;
    width: 24px;
    height: 24px;
  }
  &.ic-info-black {
    background-position: -622px -108px;
    width: 16px;
    height: 16px;
  }
  &.ic-button-sharp {
    background-position: -2385px -84px;
    width: 68px;
    height: 28px;
  }
  &.ic-button-rounded {
    background-position: -2469px -84px;
    width: 68px;
    height: 28px;
  }
  &.ic-button-pill {
    background-position: -2553px -84px;
    width: 68px;
    height: 28px;
  }
  &.ic-edit-icon {
    background-position: -1033px -100px;
    width: 24px;
    height: 24px;
  }
  &.ic-delete {
    background-position: -1033px -36px;
    width: 24px;
    height: 24px;
  }
  &.ic-square {
    background-position: -1065px -100px;
    width: 24px;
    height: 24px;
  }
  &.ic-PortraitTall {
    background-position: -1097px -100px;
    width: 24px;
    height: 24px;
  }
  &.ic-PortraitShort {
    background-position: -1129px -100px;
    width: 24px;
    height: 24px;
  }
  &.ic-landscape {
    background-position: -1161px -100px;
    width: 24px;
    height: 24px;
  }
  &.ic-landscape2 {
    background-position: -1033px -132px;
    width: 24px;
    height: 24px;
  }
  &.ic-circle {
    background-position: -1065px -132px;
    width: 24px;
    height: 24px;
  }
  &.ic-blob {
    background-position: -1097px -132px;
    width: 24px;
    height: 24px;
  }
  &.ic-arch {
    background-position: -1129px -132px;
    width: 24px;
    height: 24px;
  }
  &.ic-simple-arrow {
    background-position: -1161px -132px;
    width: 24px;
    height: 24px;
  }
  &.ic-arrow-circle {
    background-position: -1033px -164px;
    width: 24px;
    height: 24px;
  }
  &.ic-arrow-rounded {
    background-position: -1065px -164px;
    width: 24px;
    height: 24px;
  }
  &.ic-arrow-square {
    background-position: -1097px -164px;
    width: 24px;
    height: 24px;
  }
  &.section-icon {
    background-position: -598px -276px;
    width: 16px;
    height: 16px;
  }
  &.global-style-icon {
    background-position: -670px -276px;
    width: 16px;
    height: 16px;
  }
  &.global-style-dropdown-icon {
    background-position: -865px -92px;
    width: 20px;
    height: 20px;
  }
  &.plus-icon {
    background-position: -646px -252px;
    width: 16px;
    height: 16px;
  }
  &.bag-icon {
    background-position: -670px -252px;
    width: 16px;
    height: 16px;
  }
  &.cart-icon {
    background-position: -694px -252px;
    width: 16px;
    height: 16px;
  }
  &.tag1-icon {
    background-position: -1033px -196px;
    width: 24px;
    height: 24px;
  }
  &.tag2-icon {
    background-position: -1065px -196px;
    width: 24px;
    height: 24px;
  }
  &.tag3-icon {
    background-position: -1097px -196px;
    width: 24px;
    height: 24px;
  }
  &.tag-shape1 {
    background-position: -2385px -128px;
    width: 28px;
    height: 16px;
  }
  &.tag-shape3 {
    background-position: -2419px -128px;
    width: 28px;
    height: 16px;
  }
  &.tag-shape2 {
    background-position: -2453px -128px;
    width: 28px;
    height: 16px;
  }
  &.review-count1 {
    background-position: -2385px -156px;
    width: 60px;
    height: 20px;
  }
  &.review-count2 {
    background-position: -2453px -156px;
    width: 62px;
    height: 20px;
  }
  &.avg-rating {
    background-position: -2487px -128px;
    width: 38px;
    height: 20px;
  }
  &.star-rating {
    background-position: -2531px -128px;
    width: 92px;
    height: 18px;
  }
  &.redirect-indicator {
    background-position: -694px -108px;
    width: 16px;
    height: 16px;
  }
  &.ic-cross-bg-black-lg {
    background-position: -1933px -32px;
    width: 40px;
    height: 40px;
  }
  &.mute-lg {
    background-position: -1989px -32px;
    width: 40px;
    height: 40px;
  }
  &.unmute-lg {
    background-position: -2045px -32px;
    width: 40px;
    height: 40px;
  }
}
