.lead-email-templates {
  .error-msg .error-icon {
    padding: 2px 6px;
    font-size: 10px;
  }
  .closeicon {
    filter: brightness(0) invert(1);
    z-index: 9;
    position: absolute;
    top: -8%;
    right: -8%;
    img {
      max-width: 24px;
    }
  }

  .template {
    h1 {
      font-size: 40px;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 32px;
    }
    p {
      font-size: 24px;
      font-weight: 300;
      text-align: center;
      line-height: 32px;
      margin-bottom: 32px;
    }
    input {
      max-width: 395px;
      max-height: 50px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #7070707d;
      border-radius: 4px;
      margin-bottom: 11px;
      padding: 15px 38px 15px 11px;
      width: 100%;
    }
    button {
      width: 395px;
      height: 50px;

      border-radius: 4px;
      color: #fff;
      border: none;
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    .template {
      h1 {
        font-size: 26px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .template2 {
      p {
        margin-bottom: 45px;
      }
    }
  }
  .template1 {
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
    }
    .details {
      margin: 28px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    img {
      max-width: 220px;
      max-height: 430px;
      object-fit: cover;
    }

    @media screen and (max-width: 500px) {
      img {
        max-width: 300px;
        max-height: 124px;
        width: 100%;
        object-fit: cover;
      }
      h1 {
        font-size: 26px;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .template2 {
    .error-msg .error-icon {
      margin-left: 24px;
    }
    input {
      max-width: 395px;
    }
    button {
      max-width: 395px;
    }
    @media screen and (max-width: 500px) {
      .error-msg .error-icon {
        margin-left: 2px;
      }
    }
  }

  .template3 {
    .error-msg {
      margin-left: -58%;
    }
    position: relative;
    text-align: center;
    margin-top: -4%;
    width: 600px;
    img {
      max-width: 600px;
      max-height: 300px;
      width: 100%;
      object-fit: cover;
    }
    .overlay {
      top: 0%;
      width: 100%;
      height: 100%;
      max-height: 300px;
      opacity: 0.5;
    }
    .details {
      position: absolute;
      top: 42%;
      max-width: 400px;
      width: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    input {
      max-width: 370px;
      margin-right: 10px;
    }
    button {
      max-width: 166px;
    }
    .input-group {
      margin-top: 34px;
      margin-bottom: 12px;
    }

    @media screen and (max-width: 500px) {
      .error-msg {
        margin-left: -12%;
      }
      img {
        max-width: 300px;
        max-height: 230px;
      }
      margin-top: -5%;
      .overlay {
        max-width: 300px;
        max-height: 230px;
      }
      .details {
        max-width: 300px;
        top: 35%;
      }

      h1 {
        font-size: 26px;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        line-height: 22px;
      }
      input {
        max-width: 267px;
        margin-right: 0px;
      }
      button {
        max-width: 267px;
      }
      .input-group {
        margin-top: 42px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 500px) {
    overflowy: auto;
  }
}
