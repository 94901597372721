.emiSelection {
  position: relative;

  .page-header {
    padding: 12px 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px #0000000f;

    & > .header-text {
      font-size: 17px;
    }
  }

  &__header {
    background-color: #ffffff;
    z-index: 1;
    display: block;
    border-bottom: 0.4px solid #0000003d;

    &--item {
      cursor: pointer;
      display: inline-block;
      padding: 16px 32px;
      font-size: 14px;
      color: #00000061;
      font-weight: 500;

      &.active {
        color: #000000;
      }

      & .active-banner {
        background-color: #000000;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        position: absolute;
      }

      @media screen and (max-width: 767px) {
        width: 50%;
        text-align: center;
      }
    }
  }

  &__body {
    padding-top: 26px;

    @media screen and (max-width: 767px) {
      padding-left: 16px;
      padding-right: 16px;
    }

    &--inner {
      max-width: 556px;
      width: 100%;
    }

    & .accordion {
      margin-bottom: 45px;

      &-item {
        margin-bottom: 10px;
        border-radius: 7px;
        border: 0.4px solid #c2c2c2;
        overflow: hidden;
      }

      &__header {
        padding: 19px 24px;
        background-color: #f7f7f7;
      }

      &__body {
        border-top: 0.4px solid #c2c2c2;
        padding: 23.5px 23px;

        & .emi-table {
          width: 100%;
          border-collapse: collapse;

          & * {
            text-align: left;
          }

          & thead {
            & th {
              padding-top: 13px;
              padding-bottom: 13px;
              font-weight: 400;
              color: #7b7b7b;
            }
          }

          & tbody {
            & tr:not(:last-child) {
              & td {
                border-bottom: 0.4px solid #c2c2c2;
              }
            }

            & td {
              padding-top: 15px;
              padding-bottom: 11px;
            }
          }
        }

        @media screen and (max-width: 767px) {
          padding: 10px 14px 20px;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    &__header {
      position: sticky;
      top: 0;
      left: 0;
      padding-top: 1rem;
    }
  }

  @media screen and (max-width: 767px) {
    & {
      padding-top: 100px;

      .page-header > svg {
        width: 22px;
      }

      &__header {
        position: fixed;
        top: 48px;
        left: 0;
        width: 100%;
      }
    }

    & .accordion {
      &__header--title {
        font-size: 14px;
      }
    }

    & .emi-table {
      & thead {
        & th {
          font-size: 12px;
          padding-left: 4px;
          padding-right: 4px;
        }
      }

      & tbody {
        & td {
          font-size: 12px;
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }
  }
}
