.dd-showrom__layout {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  & .mobile-screen {
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    & > section {
      margin: 20px 0;
      width: 420px;
      max-height: calc(100vh - 50px);
      height: 100vh;
      overflow-y: auto;
    }
  }
}
