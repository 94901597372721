.payment-process {
  &-screen {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    text-align: center;
    height: 100vh;
    padding: 10px 20px;
  }

  &__container {
    &--title {
      font-size: 27px;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      color: #000000;
      margin-top: 12px;
      margin-bottom: 20px;
    }

    &--subtitle {
      line-height: normal;
      color: #000000;
      margin-bottom: 36px;
      font-size: 16px;
      font-weight: 400;
      margin-top: 20px;
      opacity: 0.5;
    }
  }

  &__progress {
    position: fixed;
    width: 260px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;

    &--slideContainer {
      position: relative;
      height: 4px;
      margin-top: 5px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 2px;
    }

    &--slide {
      position: absolute;
      left: 0;
      height: 4px;
      width: 30%;
      border-radius: 2px;
      background: #000;
      -webkit-animation: paymentProgressSlide 1s infinite;
      animation: paymentProgressSlide 1s infinite;
    }
  }
}

@keyframes paymentProgressSlide {
  50% {
    transform: translateX(220%);
  }
}
