.variantSelectedListModal {
    .title {
        font-size: 18px;
        font-weight: 600;
        padding: 10px 20px 20px 20px;
    }

    .item-list {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        max-height: 400px;
        overflow: auto;

        .item {
            display: flex;
            padding: 12px 0;
            .left {
                padding-right: 8px;
                width: 80%;
                .item-name {
                    font-size: 14px;
                    font-weight: 500;
                    padding-bottom: 6px;
                }
                .variant-name {
                    font-size: 12px;
                    font-weight: 300;
                }
            }
            .right {
                display: flex;
                flex: auto;
                justify-content: center;
                align-items: center;
                padding-left: 4px;

            }
        }
    }
}