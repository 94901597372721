.dd-miviewer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__side {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 20px 0 20px;

    &--image {
      min-height: 60px;
      min-width: 60px;
      margin-right: 10px;
      border: 1px solid;
      border-radius: 6px;
      max-width: 90px;
      max-height: 90px;
      img {
        border-radius: 5px;
      }
    }
  }
  &__main {
    display: flex;
    flex-grow: 3;
    min-height: 50vh;
  }

  &__empty {
    & > img {
      max-height: 400px;
    }
  }
}

@media screen and (min-width: 766px) {
  .dd-miviewer {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    max-height: 500px;

    &__side {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;

      &--image {
        min-height: 60px;
        min-width: 60px;
        margin-right: 10px;
        border: 1px solid;
        border-radius: 6px;
        margin: 5px 0;
        max-width: 90px;
        max-height: 90px;
        img {
          border-radius: 5px;
        }
      }
    }
    &__main {
      display: flex;
      flex-grow: 3;
      min-height: 50vh;
      min-width: 50vh;
    }
  }
}
