.product-header {
    background-color: #ffffff;

    &__bottom {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #EA5151;
        background-size: cover;
        padding: 20px 0;
        
        &--storenameText {
            text-align: center;
            font: normal normal bold 20px/28px Montserrat;
            letter-spacing: -0.48px;
            color: #FFFFFF;
            text-transform: capitalize;
            width: 80%;
            padding: 10px 0 5px 0;
        }
        
        &--box {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            justify-content: center;
            padding: 0 20px;

            & > div {
                display: flex;
                align-items: center;
                width: 88px;
                height: 88px;
                background-color: #ffffff;
                padding: 1px;
                box-shadow: 0px 3px 6px #00000029;

                & > img {
                    max-width: 100%;
                    height: auto;
                    border: 4px solid #FFFFFF;
                }
            }
        }

        &--searchbox {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 18px 0;
            background-color: #ffffff;
            margin-bottom: 8px;

            &-scrollbarfix {
                display: flex;
                align-items: center;
                justify-content: flex-start !important;
                width: 100%;
                margin: 0px 10px !important;
                background-color: #E9E9E9;
                padding: 5px 10px;
                border: 1px solid transparent !important;
                color: #0000005e !important;
                border-radius: 20px;
                & > img {
                    margin-right: 10px !important;
                    max-width: 100%;
                    height: 18px;
                }
            }

            & > span {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size:13px;
                font-weight: 600;
                padding: 6px 13px 6px 13px;
                letter-spacing: 0.41px;
                color: #EA5151;
                border: 1px solid #EA5151;
                border-radius: 21px;
                margin-left: 14px;

                & > img {
                    width: 10px;
                    margin-left: 5px;
                    margin-top: 4px;
                }
            }

            &-searchicon {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 30px !important;
                border: 1px solid #EA5151;
                margin-right: 28px;
                width: 35px;
                height: 35px;
                padding: 4px !important;
                
                & > img {
                    max-width: 100%;
                    height: 14px;
                    margin-right: 0 !important;
                }
            }

            &-dummy {
                height: 70px;
            }
        }
    }

    &__freeDeliveryOnAllOrder {
        background-color: #FBF5F5;

        &--wrapper {
         
            display: inline-flex;
            justify-content: center;
            align-items: center;

            & > img {
                max-width: 100%;
                height: 24px;
                margin-right: 5px;
            }
        }
    }

    &__deliveryNotAvialable {
        background-color: #353839;
        & > img {
            max-width: 100%;
            height: 20px;
        }
    }

    // special for diwali
    &__diwali--banner {
        height: auto;
        margin-top: 6px;
        background: transparent linear-gradient(259deg, #2F0138 0%, #320E39 100%) 0% 0% no-repeat padding-box;

        & > img {
            max-width: 90%;
        }
    }
}

.fix-bar {
    position: fixed;
    width: 100%;
    top: 0;
    padding: 12px  0;
    z-index: 100;
    box-shadow: 0px 2px 8px #50505038;
}

@media screen and (min-width: 991px){ 
    .fix-bar {
        max-width: 416px;
    }
  }
