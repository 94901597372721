.vertical-categoryWidget {
  padding: 0 20px;
  margin-bottom: 64px;
  &__container {
    width: 100%;
    max-width: 1400px;
  }
  &__title {
    font-size: 18px;
    margin: 0 0 32px;
  }
  &__box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 25px;
  }
  & .see-more-btn {
    padding-top: 2rem;
    & .expandable-btn {
      border: 1px solid black;
      // border-radius: 30px;
      padding: 15px 20px;
      cursor: pointer;
      background-color: #ffffff;
      position: relative;
      color: #000;

      & > span {
        z-index: 2;
        position: relative;
        color: inherit;
      }
    }

    .expandable-btn:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background: #000000;
      transition: all 0.35s;
    }

    .expandable-btn:hover {
      color: #fff;
    }

    .expandable-btn:hover:after {
      width: 100%;
      // border-radius: 30px;
    }
  }
}

.vertical-categoryWidget2 {
  padding: 0 20px;
  margin-bottom: 64px;
  &__container {
    width: 100%;
    max-width: 1400px;
  }
  &__title {
    font-size: 18px;
    margin: 0 0 32px;
  }
  &__box {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto;
    column-gap: 23px;
    row-gap: 34px;

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 10px;
      row-gap: 20px;
    }
  }

  & .expandable-btn--container {
    padding-top: 2rem;
  }

  .expandable-btn {
    border: 0.4px solid #e5e5e5;
    // border-radius: 30px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #ffffff;
    position: relative;
    color: #000;
    width: 100%;

    & > span {
      z-index: 2;
      position: relative;
      color: inherit;
      font-size: 12px;
    }

    @media screen and (min-width: 768px) {
      & {
        padding: 15px 20px;
        max-width: 330px;
        & > span {
          font-size: 18px;
        }
      }
    }
  }

  .expandable-btn:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #000000;
    transition: all 0.35s;
  }

  .expandable-btn:hover {
    color: #fff;
  }

  .expandable-btn:hover:after {
    width: 100%;
    // border-radius: 30px;
  }
}

@include respond-to('small') {
  .vertical-categoryWidget {
    margin-bottom: 72px;
    &__title {
      font-size: 26px;
      margin: 0 0 48px;
    }
    & .see-more-btn {
      padding-top: 3rem;
    }
  }
  .vertical-categoryWidget2 {
    margin-bottom: 72px;
    &__title {
      font-size: 26px;
      margin: 0 0 48px;
    }
    & .expandable-btn--container {
      padding-top: 3rem;
    }
  }
}
