.premium-auth-modal-wrap {
  color: #000;
  @media screen and (min-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 52px;
  }
  @media screen and (max-width: 767px) {
    gap: 40px;
    .mobile-close-icon {
      filter: grayscale(100);
      opacity: 0.5;
    }
  }
  .form-header {
    gap: 32px;
    .header-text {
      gap: 8px;
    }
  }
  .form-container {
    gap: 24px;
    @media screen and (max-width: 767px) {
      gap: 20px;
    }
  }
  .separator {
    gap: 24px;
    .line {
      background: #e9e9e9;
      width: 217px;
    }
    .text {
      color: #999999;
    }
  }

  .additional-login-options {
    gap: 20px;
    .email-login-button {
      padding: 12px 0;
      gap: 16px;
      border-radius: 4px;
      border: 1px solid #d2d2d2;
      background-color: white;
    }
  }
  h2 {
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 600;

    @media screen and (min-width: 767px) {
      font-size: 26px;
    }

    @media screen and (max-width: 766px) {
      font-size: 30px;
      margin-bottom: 0px;
    }
  }
  .login-otp-number-wrap {
    gap: 16px;
    .otp-header {
      color: #6b6b6b;
    }
    .contact-edit-btn-wrapper {
      .edit-btn {
        gap: 6px;
        color: #6b6b6b;
      }
    }
  }

  .translateY-10 {
    transform: translateY(10px);
  }
  .translateY-15 {
    transform: translateY(15px);
  }
  .send-otp-mobile-translate {
    transform: translate(-4px, 31px);
  }
  .bottom-text-otp-margin-top {
    margin-top: 0px !important;
  }
  .closeicon {
    position: absolute;
    top: 20px;
    right: 20px;
    img {
      max-width: 30px;
      filter: grayscale(100);
      opacity: 0.5;
    }
    @media screen and (max-width: 766px) {
      top: 12px;
      right: 12px;
    }
  }
  .small-heading {
    font-weight: 300;
    color: #9ea6b9;
    display: block;

    @media screen and (min-width: 767px) {
      font-size: 24px;
      margin-bottom: 15px;
    }

    @media screen and (max-width: 766px) {
      font-size: 14px;
      margin-bottom: 25px;
    }
  }
  .cursor-auto {
    cursor: auto;
  }
  .otp-form-group {
    gap: 24px !important;
    .input-resend-wrapper {
      gap: 20px;
      .label-input-wrapper {
        gap: 8px;
        label {
          color: #999;
        }
      }
      .message-resend-wrapper {
        color: #6b6b6b;
        .pointer {
          color: #2f80ed;
        }
      }
    }
  }
  .form-group {
    gap: 8px;
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    .form-control-otp {
      background: white;
      width: 100%;
      padding: 26px 21px 26px 12px;
      font-size: 16px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      // padding-left: 40px;
      &:focus {
        border: 1px solid rgba(0, 0, 0, 1);
      }
      @media screen and (max-width: 766px) {
        padding: 19px 15px;
        font-size: 16px;
      }
      @media screen and (min-width: 766px) {
        padding: 19px 15px;
        font-size: 16px;
      }
    }
    .form-control-otp-invalid {
      background: white;
      width: 100%;
      padding: 26px 21px 26px 12px;
      font-size: 16px;
      border: 1px solid red;
      border-radius: 6px;
      // padding-left: 40px;
      @media screen and (max-width: 766px) {
        padding: 19px 15px;
        font-size: 16px;
      }
      @media screen and (min-width: 766px) {
        padding: 19px 15px;
        font-size: 16px;
      }
    }
    .form-control {
      background: white;
      width: 100%;
      padding: 10px 12px;
      font-size: 16px;
      border-radius: 4px;
      border: 1px solid #e9e9e9;
      // padding-left: 52px;
      &:focus {
        border: 1px solid rgba(0, 0, 0, 1);
      }
      @media screen and (max-width: 766px) {
        padding: 20px 38px 20px 12px;
        font-size: 15px;
      }
    }
    img {
      position: absolute;
      @media screen and (max-width: 766px) {
        top: 20px;
        max-width: 20px;
        left: 13px;
      }
      @media screen and (min-width: 767px) {
        top: 17px;
        max-width: 26px;
        left: 13px;
      }
    }
  }
  .bottom-txt {
    font-weight: 400;
    display: block;
    color: #bababa;

    @media screen and (min-width: 767px) {
      font-size: 14px;
      line-height: 1.23;
    }

    @media screen and (max-width: 766px) {
      font-size: 14px;
      text-align: initial;
    }
    &-margin-bottom-55 {
      @media screen and (max-width: 766px) {
        margin-bottom: 40px !important;
      }
    }
  }
  @media screen and (max-width: 766px) {
    .heading {
      overflow-wrap: anywhere;
      &-login-whatsapp {
        transform: translateY(5px);
        text-align: initial;
        .mobile-close-icon {
          filter: grayscale(100);
          opacity: 0.5;
        }
      }
    }
  }
  .margin-left-50vw {
    margin-left: 50vw;
  }
  .visibile-hidden {
    visibility: hidden !important;
  }
}
