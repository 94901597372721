.create-paperlist {
    width: 100%;

    &__add {
        display: flex;
        margin-top: 26px;
        padding-left: 16px;
    }
}

.addmore-btn {
    align-items: center;
    text-align: center;
    display: flex;
    font-weight: 600;
    font-size: 15px;
    color: #EA5151 !important;
    justify-content: center;

   & > img {
       width: 22px;
       padding: 4px;
   }
   
}