.single-select-radio-groups {
  // padding: 10px 20px 20px 20px;
  .radio-item {
    display: flex;
    border: 1px solid #dedede;
    padding: 12px 5px 12px 15px;
    min-height: 80px;
    border-radius: 8px;
    margin: 10px 0;
    font-size: 13px;
    & > input {
      margin-right: 10px;
    }
    & > input[type='radio']:checked {
      color: #ea5151;
      background-color: #ea5151;
      border-color: #ea5151;
    }
  }

  & > .active {
    border: 1px solid #ea5151;
    color: #ea5151;
    font-weight: 500;
  }
  .out-of-stock {
    border: 1px dashed #bbbbbbcb !important;
    background-color: #eeeeee;
    opacity: 0.6;
  }

  .inventory-change {
    margin: 8px 0 0 0;
    font-size: 12px;
  }
}
