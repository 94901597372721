.heroBanner8 {
  background: transparent linear-gradient(105deg, #ea5151 0%, #752975 100%) 0% 0%
    no-repeat padding-box;

  &__wrapper {
    max-width: 930px;
  }
}

.heroBannerWrapper {
  position: relative;
  margin-bottom: 72px;
  @media screen and (max-width: 766px) {
    margin-bottom: 64px;
    &.herobanner6 {
      margin-bottom: 0;
    }
  }

  &.placeholder {
    height: 100vh;
  }

  .arrow-btns {
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.herobanner_slider--icons {
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  bottom: -8px;
}

.herobanner_slider--icons li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
}

.herobanner_slider--icons li button {
  border: none;
  background: rgba(255, 255, 255, 0.6);
  color: transparent;
  cursor: pointer;
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 5px;
  padding: 0;
}

.herobanner_slider--icons li.slick-active button {
  padding: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 8px;
  height: 8px;
}

.herobanner_slider--icons li button:hover,
.herobanner_slider--icons li button:focus {
  background: #ffffff;
  outline: 0;
}

@media screen and (min-width: 766px) {
  .herobanner_slider--icons {
    bottom: 20px;
  }
}
