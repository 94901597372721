.header11 {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 5px 0px #00000040;
  z-index: 90;

  &__component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: clamp(15px, 5%, 40px);
    padding-right: clamp(15px, 5%, 40px);

    & .menu-list {
      display: flex;
      align-items: center;
      @media screen and (min-width: 787px) {
        & {
          margin-left: 20px;
        }
      }
    }

    & .store-name {
      max-width: 224px;
    }

    & .menu-item {
      text-transform: uppercase;
      font-weight: 500;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      padding: 0 10px;
      white-space: nowrap;

      & > img {
        margin-left: 8px;
        width: 8px;
      }

      &-active {
        color: #3b33ff;
      }

      & .nav-name {
        font-size: 12px;
      }
    }
  }

  & .search-comp {
    max-width: 250px;
    margin: 0 40px 0 15px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    flex: 1 1 250px;

    & > img {
      width: 21px;
      margin-right: 12px;
    }

    & > span {
      font-size: 14px;
      font-weight: 500;
    }

    @media screen and (max-width: 767px) {
      & {
        margin: 0 20px 0 15px;
        padding: 10px 20px;

        & > img {
          width: 12px;
        }

        & > span {
          font-size: 12px;
        }
      }
    }
  }

  & .icon-set {
    flex: 1 1 100%;
    & > .right-section-component {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      &:last-child {
        padding-left: 24px;
        @media screen and (max-width: 786px) {
          & {
            padding-left: 12px;
          }
        }
      }

      & .header-text {
        font-size: 14px;
        font-weight: 500;
        padding-right: 14px;
        margin: 0;

        @media screen and (max-width: 400px) {
          display: none;
        }
      }

      @media screen and (max-width: 767px) {
        &:first-child {
          display: none;
        }

        &:last-child {
          margin-left: 0px;
        }
      }

      & img {
        width: 20px;

        @media screen and (max-width: 767px) {
          width: 17px;
        }

        &.arrow-icon {
          width: 14px;
          position: relative;
          transform: translateY(2px);
        }
      }

      & > .cart-items {
        background: #000000;
        color: #ffffff;
        font-size: 12px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -10px;
        right: -10px;
      }
    }
  }

  .dropdown {
    &--menu {
      list-style: none;
      padding: 10px 15px;

      & .menu-item {
        width: 100%;

        & > img {
          margin-left: 6px;
          width: 7px;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
