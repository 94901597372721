.pageContainer {
  width: 100%;
  container: pageContainer / inline-size;
  overflow-x: hidden;
  // margin: auto;
  margin-top: 0px;
  border-radius: 20px;
  height: 86vh;
  transition: max-width 300ms;

  margin-top: -48px;
  // border-radius: var(--borderRadius-md);

  &.forNewAdminBuilder {
    border-radius: 8px;
    margin-top: -60px;
    height: 89.4vh;
  }

  &.desktop {
    // max-width: 1440px;
    box-shadow: 0px 4px 18px rgba(18, 18, 18, 0.08);
  }

  &.mobile {
    max-width: 360px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  }
}
.builderPage {
  flex: 1;
  width: 100%;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 24px 33px;
  padding-bottom: 0px;

  &.forNewAdminBuilder {
    padding: 6px 12px 0px 0;
  }

  .builderUtilitySection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;

    .domainInfo {
      background: #eaf2fd;
      border: 1px solid #bfd8f9;
      border-radius: 60px;
      padding: 11px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .widgetBox {
    position: relative;
    --widget-highlight-color: #4285f4;
    &:hover {
      box-shadow: 0 0 0px 1px var(--widget-highlight-color);
      background-color: rgba(66, 133, 244, 0.05);
      background: repeating-linear-gradient(
        45deg,
        rgba(66, 133, 244, 0.05) 1%,
        rgba(66, 133, 244, 0.1) 2%
      );
    }
    &:not(:global(.rendered)) {
      display: flex;
      height: 5rem;
      border: 2px solid #888;
      overflow: hidden;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      border-radius: 0.5rem;
      height: auto;
      width: auto;

      .widgetThumb {
        height: 3rem;
      }
    }
  }

  :global(.placeholder) {
    opacity: 0.75;
    margin: 0.5em;
    border-radius: 0.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.1);
  }

  :global(.widget-2-col) {
    min-height: 5rem;
    border: 1px solid #999;
    display: flex;
    flex-direction: row;

    :global(.widget-2-col) {
      padding-top: 0.5rem;
      display: flex;
      flex-direction: row;
    }

    :global(.col-2-child) {
      flex: 1;
      min-height: 5rem;

      &:empty {
        background-color: rgba(66, 133, 244, 0.2);
        border-radius: 0.5rem;
        margin: 0 0.25rem;
        border: 0.25rem dashed rgba(66, 133, 244, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: rgba(66, 133, 244, 0.25);
        }
        &:before {
          content: 'Drag here to add a widget.';
        }
      }
    }
  }

  :global(.col-2-child) .widgetBox {
    height: 100%;
    width: 100%;
  }

  :global(.removingWidget) {
    overflow: hidden;
    max-height: 0 !important;
    background-color: rgba(255, 0, 0, 0.5);
    transition: 0.2s;
  }

  :global(#page-builder) {
    width: 100%;
    &:empty {
      min-height: 10rem;
      background: repeating-linear-gradient(
        135deg,
        rgba(0, 0, 0, 0.05) 1%,
        rgba(0, 0, 0, 0.1) 2%
      );
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }

  :global(.sortable-ghost) {
    transition: 0.25s;
    opacity: 0.5;
  }
}

.widgetDnDHeader {
  padding: var(--spacing-8);
  box-shadow: var(--shadows-appBar);
  z-index: var(--zIndex-appBar);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .widgetDnDHeaderActions {
    display: flex;
    gap: var(--spacing-8);
  }
}

.devicesSelector {
  display: flex;
  gap: 0.5rem;
  .deviceIcon {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    margin-right: var(--spacing-8);
    border-radius: var(--borderRadius-md);

    &:hover {
      background-color: rgba(0, 0, 0, 0.15);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.25);
    }

    &.selected {
      box-shadow: 0 0 0 2px #666;
    }
  }
}

@media screen and (min-width: 768px) {
  .pageContainer :global(.hide-desktop) {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .pageContainer :global(.hide-mobile) {
    display: none;
  }
}
@container pageContainer (min-width: 768px) {
  .pageContainer :global(.hide-desktop) {
    display: none;
  }
}
@container pageContainer (max-width: 767px) {
  .pageContainer :global(.hide-mobile) {
    display: none;
  }
}

.visible-mobile-block {
  display: none !important;
}
.visible-desktop-block {
  display: block !important;
}
.visible-mobile-flex {
  display: none !important;
}
.visible-desktop-flex {
  display: flex !important;
}
.visible-mobile-ib {
  display: none !important;
}
.visible-desktop-ib {
  display: inline-block !important;
}
.visible-mobile {
  display: none !important;
}
.visible-desktop {
  display: flex !important;
}

@container pageContainer (max-width: 767px) {
  .visible-mobile {
    display: block !important;
  }
  .visible-desktop {
    display: none !important;
  }
  .visible-mobile-block {
    display: block !important;
  }
  .visible-desktop-block {
    display: none !important;
  }
  .visible-mobile-flex {
    display: flex !important;
  }
  .visible-desktop-flex {
    display: none !important;
  }
  .visible-mobile-ib {
    display: inline-block !important;
  }
  .visible-desktop-ib {
    display: none !important;
  }
}

.addWidgetViaMainSection {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;
  display: block;
  width: 100%;
  cursor: pointer;

  & .content {
    display: flex;
    align-items: center;
    position: absolute;
    left: 48.54%;
    top: -15px;
    z-index: 1000;
    gap: 6px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;

    & > span {
      // display: none;
      font-size: 12px;
      color: #fff;
      opacity: 0;
      padding-right: 10px;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      background-color: #4285f4;
      .iconImg {
        fill: #4285f4;
      }
      span {
        opacity: 1;
        // display: inline;
      }
    }
  }
}

.iframe-content {
  margin: 0 auto;
  max-width: 427px;
  width: 100%;
  overflow: auto;
  &.desktop-view {
    width: 80%;
    max-width: 1000px;
  }
  iframe {
    width: 100%;
  }
}
