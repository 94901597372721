.pay-now-box {
  border-bottom: none;
  padding-bottom: 8px;
}

.single-more-options {
  height: 46px;
}

.all-more-options {
  height: 80px;
}

.more-options-box {
  display: flex;
  width: 418px;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  flex-direction: column;
  padding: 0px 272px 15px 16px;
  gap: 10px;

  .more-options-heading-box {
    display: flex;
    align-items: center;
    gap: 12px;

    .more-options-heading {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
      white-space: nowrap;
    }

    .below-arrow {
      width: 24px;
      height: 24px;
      max-width: 100%;
      height: 10px;
    }
    .arrow-down {
      transform: rotate(90deg);
    }
    .arrow-up {
      transform: rotate(-90deg);
    }
  }

  .cancel-order {
    color: var(--kinetic-colors-gray-gray-500, #222);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    align-self: flex-start;
  }
}

.cancel-option-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  .cancel-option-modal {
    display: flex;
    width: 551px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 19px;
    border-radius: 8px;
    background: #fff;
    position: absolute;
    max-height: 500px;
    overflow: auto;
    overflow-x: hidden;

    position: relative;
    min-height: 430px;

    .cancel-modal-static {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      align-self: stretch;

      .cancel-modal-headings {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;

        .cancel-modal-main-heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          .cancel-modal-heading-text {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 83.333% */
          }

          .cancel-icon {
            width: 24px;
            height: 24px;
          }
        }

        .cancel-modal-description {
          align-self: stretch;
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
        }
      }

      .cancel-modal-options-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        .cancel-modal-option {
          display: flex;
          align-items: center;
          gap: 8px;

          > input {
            width: 24px;
            height: 24px;
            accent-color: #000;
          }
          > label {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
          }
        }
      }
    }

    .cancel-order-div {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      background: white;
      position: sticky;
      bottom: -6%;
      width: 109%;
      transform: translateX(-4%);
      left: 0%;
      padding-bottom: 11px;
      padding-top: 12px;

      .cancel-order-btn {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        width: 92%;
        gap: 8px;
        align-self: stretch;
        background: rgba(14, 14, 14, 0.4);
        border: 0;

        .cancel-order-btn-txt {
          color: var(--mobile-kinetic-ui-naturals-white, #fff);
          font-feature-settings:
            'clig' off,
            'liga' off;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    .cancel-order-div-selected {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      background: white;
      position: sticky;
      bottom: -6%;
      width: 109%;
      transform: translateX(-4%);
      left: 0%;
      padding-bottom: 11px;
      padding-top: 12px;

      .cancel-order-btn-selected {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        width: 92%;
        gap: 8px;
        align-self: stretch;
        background: #0e0e0e;
        border: 0;

        .cancel-order-btn-txt-selected {
          color: var(--mobile-kinetic-ui-naturals-white, #fff);
          font-feature-settings:
            'clig' off,
            'liga' off;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    .custom-cancel-input-box {
      display: flex;
      width: 503px;
      height: 197px;
      padding: 20px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      min-height: 80px;
      padding-top: 15px;

      .placeholder {
        color: rgba(0, 0, 0, 0.4);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }
    }
  }
}

.cancel-option-modal-overlay-mobile {
  // width: 360px;
  // height: 779.432px;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey background */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 2;

  .cancel-option-modal-mobile {
    display: flex;
    width: 100%;
    // height: 562px;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-end;
    gap: 32px;
    flex-shrink: 0;
    border-radius: 12px 12px 0px 0px;
    background: #fff;
    position: absolute;

    .cancel-modal-main-heading-mobile {
      display: flex;
      align-items: center;
      gap: 32px;
      align-self: stretch;

      .cancel-modal-heading-text-mobile {
        flex: 1 0 0;
        color: var(--mobile-kinetic-ui-grey-grey-500, #222);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 160% */
      }

      .cancel-icon-mobile {
        width: 24px;
        height: 24px;
      }
    }

    .cancel-modal-body-mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      flex: 1 0 0;
      align-self: stretch;

      .cancel-modal-description-mobile {
        align-self: stretch;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }

      .cancel-modal-options-mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        .cancel-modal-option-mobile {
          display: flex;
          align-items: center;
          gap: 8px;

          > input {
            width: 24px;
            height: 24px;
            accent-color: #000;
          }

          > label {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
          }
        }
      }

      .custom-cancel-input-box-mobile {
        display: flex;
        padding: 12px;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--mobile-kinetic-ui-grey-grey-50, #e9e9e9);
        background: var(--mobile-kinetic-ui-naturals-white, #fff);

        .placeholder {
          flex: 1 0 0;
          color: rgba(0, 0, 0, 0.4);
          font-feature-settings:
            'clig' off,
            'liga' off;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }

      .cancel-order-btn-mobile {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        background: rgba(14, 14, 14, 0.4);
        align-self: stretch;
        border: 0;

        .cancel-order-btn-txt-mobile {
          color: var(--mobile-kinetic-ui-naturals-white, #fff);
          font-feature-settings:
            'clig' off,
            'liga' off;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }
      }

      .cancel-order-btn-mobile-selected {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        background: #0e0e0e;
        align-self: stretch;

        .cancel-order-btn-txt-mobile-selected {
          color: var(--mobile-kinetic-ui-naturals-white, #fff);
          font-feature-settings:
            'clig' off,
            'liga' off;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
}

.more-options-bottom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey background */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 2;

  .more-options-bottom {
    display: flex;
    width: 100%;
    height: 84px;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-end;
    gap: 32px;
    border-radius: 12px 12px 0px 0px;
    background: #fff;

    .cancel-option-mobile {
      display: flex;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      .cancel-option-icoon-mobile {
        width: 24px;
        height: 24px;
      }
      .cancel-option-mobile-text {
        color: var(--kinetic-colors-gray-gray-500, #222);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }
    }
  }
}
