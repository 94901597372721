.notify-product-container {
  @media screen and (max-width: 766px) {
    margin-left: 20px;
    margin-right: 20px;
    // margin: 0px auto;
  }

  .heading {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #414141;
  }

  .subheading {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.14;
    color: #999999;
  }

  .notify-product-input-container {
    display: flex;

    @media screen and (max-width: 330px) {
      flex-direction: column;
    }

    .notify-product-input-box {
      padding: 6px 12px;
      width: 278px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 4px;
      font-size: 14px;
      margin-right: 12px;
    }

    .notify-cta-btn {
      width: 154px;
      height: 40px;
      background: #000000;
      border: none;
      border-radius: 5px;
      // font-family: 'Nunito';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-transform: capitalize;
      color: #ffffff;
      cursor: pointer;
      @media screen and (max-width: 330px) {
        width: 278px;
        margin-top: 16px;
      }
    }
  }
  .invalid-email-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #eb5757;
  }
}
.Toastify__toast-body {
  font-family: Montserrat, sans-serif;
}
.Toastify__toast--warning {
  background: #3d6afd !important;
  color: white;
  border-radius: 4px;
  width: 450px;
  font-family: Montserrat, sans-serif;

  @media screen and (max-width: 766px) {
    width: 100%;
    margin: 10px auto;
  }
}
