.float-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position:fixed;
	width:56px;
	height:56px;
    bottom:80px;
	right:20px;
	color:#FFF;
    border-radius:50px;
    border: 0;
    background: #EA5151 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 5px #00000033;

    & img {
        width: 60%;
    }
    
}