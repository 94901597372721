.additional {
  &--wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    @media screen and (max-width: 766px) {
      margin-top: 48px;
    }
    &.home-page {
      margin-top: 0 !important;
    }
  }
  &-main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px 10px 0px;
    flex: 3 1 100%;
    width: 100%;

    & p {
      word-wrap: break-word;
    }
  }
}
