.dukaanCard {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  line-height: 1em;

  &-wrap {
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    width: 100%;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 76px;
    width: 76px;
    background: transparent 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 16px #00000029;
    border-radius: 8px;

    &>img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }

  &__body {
    margin: 0 0 0 11px;
    width: 75%;
  }

  &__name {
    font-size: 14px;
    color: #2A2A2A;
    font-weight: 600;
    text-decoration: none;
  }

  &__categoryDistance {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;

    &>span {
      color: #EA5151;
      font-size: 11px;
      font-weight: 600;
    }

    &--dot {
      padding: 2px;
      border-radius: 20px;
      background-color: #EA5151;
      margin: 1px 4px;
      display: -webkit-inline-box;
    }

    &--name {
      white-space: nowrap;
    }
  }

  &__address {
    font-size: 10px;
    font-weight: 600;
    color: #3c404291;
    padding-top: 3px;
  }

  &__bottom-line {
    width: 80%;
    float: right;
    border-bottom: .5px dashed #bfbfbf;
  }
}

.dukaanCard-loader {
  display: flex;
  padding: 15px 0;

  &__img {
    height: 76px;
    width: 76px;
    // box-shadow: 0px 10px 16px #00000029;
    border-radius: 8px;
    margin-right: 11px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    width: 75%;
  }

  &__name {
    width: 60%;
    padding: 6px;
    // box-shadow: 0px 10px 16px #00000029;
  }

  &__type {
    width: 50%;
    padding: 4px;
    // box-shadow: 0px 10px 16px #00000029;
    margin: 5px 0 10px 0;
  }

  &__address {
    width: 85%;
    padding: 15px;
    // box-shadow: 0px 10px 16px #00000029;
  }

  .animate {
    animation: load 1.4s linear infinite;
    animation-fill-mode: forwards;
    background: #e2e1e1;
    background-image: linear-gradient(to left, #dddddd 0%, #f5f5f5 20%, #efefef 40%, #dddddd 100%);
    background-repeat: no-repeat;
    background-size: cover;
  }
}