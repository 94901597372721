.CollectionCategoryProductWidget1 {
  @media screen and (min-width: 787px) {
    margin-top: 96px;
  }
  @media screen and (max-width: 786px) {
    margin-top: 56px;
  }
  &__body {
    display: flex;
    & .left-section {
      border-right: 1px solid #e5e5e5;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      flex: 0 0 248px;
    }
    & .right-section {
      flex: 1 1 auto;
      padding: 0 20px;
      margin: 0 auto;
      max-width: 940px;
      width: 100%;
      & .collection-category-items {
        & .collection-heading {
          text-align: center;
          font-size: 24px;
          font-weight: 600;
          margin: 0 0 38px;

          @media screen and (max-width: 786px) {
            font-size: 14px;
          }
        }
        & .category-items-section {
          & .category-item {
            &-header {
              font-size: 18px;
              font-weight: 500;
              margin: 0;

              @media screen and (min-width: 1201px) {
                // padding-left: 40px;
              }

              @media screen and (max-width: 786px) {
                font-size: 12px;
              }
            }
            &-body {
              padding: 16px 0 24px;
              & > section {
                display: grid;
                justify-self: center;
                justify-items: center;
                grid-auto-rows: auto;

                @media screen and (min-width: 787px) {
                  grid-template-columns: repeat(3, 1fr);
                  column-gap: 30px;
                  row-gap: 20px;
                }
                @media screen and (max-width: 786px) {
                  grid-template-columns: repeat(2, 1fr);
                  column-gap: 20px;
                  row-gap: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
