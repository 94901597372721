.promoApplyWidget {
  .applyNowView {
    .not-available {
      border-color: #ffec83;
      background-color: #fff6c1;
      color: #202431;
    }
    &-detailCta {
      .details {
        .apply-offer-text {
          color: #ea5151;
        }
      }
      & > :first-child {
        width: fit-content;
        & > img {
          max-width: 100%;
          height: 24px;
        }
      }
      & > :last-child {
        width: 40px;
        img {
          max-width: 100%;
          height: 24px;
          filter: invert(1);
        }
      }
    }
  }
  .payment-chips-container {
    padding: 0px 16px 26px 56px;
    overflow: auto;
    white-space: nowrap;
  }
  .promoBox {
    min-width: 160px;
    height: 46px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #0000001f;
    border: 1px solid #e5e5e5;
    border-radius: 7px;
    padding: 10px 10px 10px 10px;
    margin-right: 8px;
    &.appliedPromoBox {
      flex-wrap: 0;
      box-shadow: 0px 2px 4px #00000029;
      border: 1px solid #0dbf44;
      border-radius: 3px;
    }
    .payment-promo-image {
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }
    .card-text {
      font-size: 10px;
      margin-top: 3px;
      color: #939393;
    }
    .callout-text {
      max-width: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .section-divider {
    border-top: 0.5px solid #e9ecf2;
  }

  .appliedView {
    &-detailCta {
      .details {
        .apply-offer-text {
          color: #0dbf44;
        }

        .promo-code {
          background-color: #ecfff0;
          border: 1px solid #0dbf44;
          color: #0dbf44;
          padding: 1px 3px;
          border-radius: 4px;
          margin-right: 5px;
        }
      }
      & > :first-child {
        width: fit-content;
        & > img {
          max-width: 100%;
          height: 24px;
        }
      }
      & > :last-child {
        color: #ea5151;
      }
    }
  }
}

.allOfferPageContainer {
  width: 100%;
  margin: 0 auto;
  @media (min-width: 990px) {
    padding-bottom: 6rem;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    margin: 0 auto;
    .offerBannerImg {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    .availableOfferText {
      left: 150px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .modal-header {
    .back-arrow {
      padding: 13px 16px;
    }
    .availableOfferText {
      left: 40px;
      top: 48px;
    }
  }
  .green-tick-image {
    width: 20px;
    height: 20px;
  }
  .applied-text {
    color: #0dbf44;
  }
  .promoAppliedBox {
    background: #edfff0;
    padding: 16px 12px;
    margin-bottom: 15px;
    border-radius: 9px;
    article,
    .applied-amount-txt {
      color: #0dbf44;
    }
    .applied-amount-txt {
      font-size: 12px;
    }
    .remove-btn {
      color: #ea5151;
      font-size: 14px;
      font-weight: 400;
    }
    .promo-code {
      background-color: #ecfff0;
      border: 1px solid #0dbf44;
      color: #0dbf44;
      padding: 1px 3px;
      border-radius: 4px;
      margin-right: 5px;
    }
    .promoBox {
      min-width: 160px;
      height: 46px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 3px #0000001f;
      border: 1px solid #e5e5e5;
      border-radius: 7px;
      padding: 10px 10px 10px 10px;
      margin-right: 8px;
      &.appliedPromoBox {
        flex-wrap: 0;
        box-shadow: 0px 2px 4px #00000029;
        border: 1px solid #0dbf44;
        border-radius: 3px;
      }
      .payment-promo-image {
        width: 26px;
        height: 26px;
        margin-right: 8px;
      }
      .card-text {
        font-size: 10px;
        margin-top: 3px;
        color: #939393;
      }
    }
  }
  .tab-container {
    border-bottom: 1px solid #e6e6e6;
    div {
      padding: 20px 15px;
    }
  }
  .storePromotionMainContainer {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for IE, Edge and Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .couponDetails-overlay {
      .topdesign {
        position: relative;
        background-color: #ffcece;
        margin: -15px -15px 0 -15px;

        & > img {
          object-fit: cover;
        }

        &::before {
          content: '';
          position: absolute;
          width: 40px;
          height: 40px;
          background-color: #000000f2;
          z-index: 999;
          border-radius: 0 50% 50% 0;
          bottom: -20px;
          clip-path: circle(50% at 0 50%);
        }

        &::after {
          content: '';
          position: absolute;
          width: 40px;
          height: 40px;
          background-color: #000000f2;
          z-index: 999;
          border-radius: 50% 0 0 50%;
          bottom: -20px;
          right: 0;
          clip-path: circle(50% at 99% 50%);
        }
      }

      .cross-icon {
        bottom: -85px;
        left: 50%;
        transform: translateX(-50%);

        // & > span {
        //     width: 24px;
        //     height: 24px;
        //     background-color: #000000f2;
        // }
      }
    }
    padding: 30px 22px 50px;
    max-height: 65vh;
    overflow: auto;
    max-width: 1000px;
    margin: 0 auto;
    .main {
      .input-box {
        &__cta {
          color: #ea5151;
          cursor: pointer;
        }
      }

      .error-msg {
        & > span {
          height: 18px;
          width: 18px;
          border-radius: 50%;
        }
      }

      .coupons-list {
        .pb0px {
          padding-bottom: 0px !important;
        }
        .coupon-item {
          padding: 20px 0px 16px;
          .details {
            .left {
              .promo-code {
                & > span {
                  font-size: 11px;
                  background-color: #ea5151;
                }
              }
            }

            .right {
              color: #ea5151;
            }
          }
          .w-53 {
            max-width: 53px;
          }
          .h-67 {
            height: 67px;
          }

          .coupon-note {
            display: inline-block;
            padding: 6px 14px;
            font-size: 11px;
            border-radius: 13px;
            margin: 0px;
            color: #ea5151;
          }
        }

        & > :last-child {
          border: none;
        }
      }
    }
  }

  .paymentPromotionMainContainer {
    padding: 10px 22px 50px;
    max-width: 1000px;
    margin: 0 auto;
    .singlePaymentPromo {
      padding: 24px 0px 16px;
      .promoDetails {
        margin-top: 2px;
      }
      .promoImage {
        width: 60px;
        height: 60px;
        margin-right: 14px;
      }
      .promo-desc {
        color: #939393;
        font-size: 10px;
        margin-top: 2px;
      }
    }
    .cardPaymentBox {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #ffffff;
      z-index: 111;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      height: 420px;
    }
  }
}

.cardVerifyModal {
  .close-icon {
    background-color: #c5c5c529;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -72px;
    border-radius: 50%;
    width: 44px;
    height: 44px;

    & > img {
      height: 25px;
    }
  }
  .card-wrapper {
    padding: 0;
    .cta-btn {
      margin-top: 40px;
    }
    .form-group {
      display: flex;
      flex-direction: column;
    }
  }
}
