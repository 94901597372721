.order-thankyou-page {
  min-height: 100vh;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 23px;
      font-weight: normal;
      line-height: 25px;
      color: #444;
      img {
        max-width: 20px;
        filter: grayscale(1);
        vertical-align: middle;
      }
    }
    .right-wrap {
      display: flex;
      align-items: center;
      a {
        border: 1px solid #e2e2e2;
        display: inline-block;
        img {
          vertical-align: middle;
        }
      }
    }
  }
  .center-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    flex: 1;
    margin-top: 60px;
  }
  .middle-content {
    width: 100%;
    h2 {
      font-size: 20px;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 10px;
      span {
        font-weight: 500;
      }
    }
    p {
      font-size: 15px;
      margin: 5px 0;
      color: #999;
    }
  }
  .bill-wrap {
    border-top: 1px dashed #ddd;
    padding-top: 20px;
    margin-top: 20px;
    .details {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #999;
      margin-bottom: 20px;
      align-items: center;
      a {
        border: 1px solid #000;
        padding: 5px 10px;
        border-radius: 50px;
        color: #000;
        font-weight: 500;
        text-decoration: none;
        img {
          filter: invert(1);
          max-width: 14px;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
    }
  }
  footer {
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    button {
      border: 0;
      box-shadow: none;
      background-color: #6e27ff;
      color: #fff;
      width: 100%;
      padding: 18px;
      border-radius: 50px;
    }
    &.stick-bottom {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 20px;
      box-shadow: 0 0 10px 0 #ddd;
    }
  }
}
