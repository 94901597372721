* {
  box-sizing: border-box;
}

:root {
  --primary-color: #59b528;
  --dark-color: #211b3e;
  --pale-grey-two: #eeecf9;
  --pale-grey: #f8f7fa;
  --blue-grey: #8d8b97;
  --dusk: #585371;
  --dark: #211b3e;
  --cloudy-blue: #c0becd;
  --pale-grey-two: #eeecf9;
  --white: #fff;
  --normal-font: normal-font;
  --light-font: light-font;
  --regular-font: regular-font;
  --bold-font: bold-font;
  --extra-bold-font: extra-bold-font;
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background: #fff;
}
