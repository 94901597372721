.havesomething-in-mind {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__box {
        display: flex;
        flex-direction: row;
        background-color: #FFF6C1;
        justify-content: space-between;
        box-shadow: 0px 1px 3px #00000029;
    
        &:first-child {
            display: flex;
            justify-content: space-between;
            margin: 0 10px;
            padding: 0 2px 0 10px;
            border: 1px solid #FFEC83;
            border-radius: 4px;
    
            & span{
                font: normal normal 600 12px Montserrat;
                letter-spacing: 0.36px;
                color: #202431;
                font-weight: 600;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
    
                &:first-child {
                    font: normal normal normal 12px Montserrat;
                    letter-spacing: 0.36px;
                    color: #202431;
                    margin-bottom: 6px;
                }
            }
        } 

        &-image {
            display: flex;
            
            & > img{
                margin: 8px 0 0 0;
                width: 50px;
                height: 50px;
            }
        }
    }

    &__arrow {
        width: 12px;
        height: 12px;
        filter: invert(1);
        margin-left: 2px;
    }
}
    

.web-havesomethingInMind{

    &--wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    border: 1px solid #FFEC83;
    border-radius: 15px;
    padding-right: 10px;

   & > .havesomething-in-mind__box {
     border-radius: 0;
     box-shadow: 0 0 0 transparent;
     border: 0;
   }
 }

 &--btnwrap {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
   flex-grow: 1;

   & > button {
     padding: 10px;
     border-radius: 30px;
     max-width: 200px;
     margin: 10px 0;
   }
 }
}