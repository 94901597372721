.empty-widget-container {
  margin-bottom: 72px;
  & .no-spacing-top {
    margin-top: -72px;
  }

  video {
    background-color: #ffffff;
  }

  @media screen and (max-width: 766px) {
    & {
      margin-bottom: 64px;
      & .no-spacing-top {
        margin-top: -64px;
      }
    }
  }
}
