.footerStoreInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  background-color: #ffffff;
  margin: 14px 0 4px 0;
  background-size: cover;

  &__name {
    font-size:13px;
    font-weight: 600;
    letter-spacing: 0.36px;
    color: #000000;
    padding: 0 20px;
    text-align: center;
  }

  &__address {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    font-size:11px;
    letter-spacing: 0.11px;
    color: #6A7279;
    padding: 6px 30px;

    & > img {
      max-width: 12px;
      margin-right: 3px;
      padding-top: 1px;
    }

    & > span {
      text-align: center;
    }
  }

  &__phone {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: #6A7279;
    border: 1px solid #6a727961;
    border-radius: 22px;
    padding: 2px 7px;

    & > img {
      max-width: 9px;
      margin-right: 4px;
      filter: invert(0.6);
    }
  }

  &_paymentOption {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;

    & > span {
      font-size: 11px;
      font-weight: 700;
    }
    & > img {
      max-width: 100%;
      height: 30px;
    }
  }

}