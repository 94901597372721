.category-header {
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 766px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  & .category-breadcrumb {
    white-space: nowrap;
    color: #000000;
    font-weight: 400;
    font-size: 22px;

    & .bottomborder {
      border-bottom: 1.2px solid #000000;
      width: 0;
      position: absolute;
      bottom: -2px;
      left: 0;
      transition: width 300ms ease-in-out;
    }

    &:hover {
      & .bottomborder {
        width: 100%;
      }
    }

    @media screen and (max-width: 766px) {
      font-size: 14px;
      margin-bottom: 11px;
      margin-right: 0;
    }
  }

  & .category-name {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #000000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .product-count {
    font-size: 18px;
    color: #000000;
    @media screen and (max-width: 766px) {
      text-align: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &.category-header-3,
  &.category-header-4 {
    & .divider {
      width: 33.3333%;
      @media screen and (max-width: 766px) {
        width: 100%;
        margin-bottom: 15px;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
  &.category-header-1 {
    padding: 40px 0;
    @media screen and (min-width: 767px) {
      padding-left: 20%;
      padding-right: 20%;
    }
    & .category-breadcrumb-1 {
      margin-bottom: 10px;
      font-size: 15px;

      @media screen and (max-width: 766px) {
        font-size: 12px;
        margin-bottom: 7px;
      }
    }
    & .category-name-1 {
      width: 80%;
      font-size: 26px;
      margin: 0 auto 10px;
      font-weight: 600;

      @media screen and (max-width: 766px) {
        font-size: 17px;
        margin-bottom: 7px;
      }
    }
    & .product-count-1 {
      font-size: 15px;

      @media screen and (max-width: 766px) {
        font-size: 12px;
      }
    }
    & .filter-sorting {
      @media screen and (min-width: 767px) {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-30%, -50%);
      }
      @media screen and (max-width: 766px) {
        margin-top: 14px;
        max-width: 100%;

        &__box {
          border-right: none;
          border-left: none;
          justify-content: center;
          & > p {
            margin-right: 10px;
          }
        }
      }
    }
  }
  &.category-pb6px {
    padding-bottom: 6px !important;
  }

  &.category-header-2 {
    padding: 20px 0 40px;

    @media screen and (max-width: 766px) {
      padding-left: 16px;
      padding-right: 16px;
      justify-content: flex-start;
      align-items: flex-start;
    }

    & .block-style {
      margin-bottom: 12px;
      @media screen and (max-width: 766px) {
        margin-bottom: 7px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    & .category-breadcrumb-2 {
      font-weight: 400;
      font-size: 18px;

      @media screen and (min-width: 767px) {
        margin-right: 0.5rem;
      }

      @media screen and (max-width: 766px) {
        font-size: 12px;
      }
    }
    & .category-name-2 {
      font-size: 18px;
      font-weight: 600;
      padding-left: 3px;

      @media screen and (max-width: 766px) {
        font-size: 16px;
        padding-left: 0;
      }
    }
    & .product-count-2 {
      font-size: 15px;

      @media screen and (max-width: 766px) {
        font-size: 12px;
        text-align: left;
        margin: 0;
      }
    }
    .filter-sorting {
      @media screen and (min-width: 767px) {
        position: absolute;
        right: 3%;
        top: -30%;
        transform: translateY(-50%);
      }
      @media screen and (max-width: 766px) {
        max-width: 220px;
      }
    }
  }

  &.category-header-3 {
    display: flex;
    padding: 20px 48px 40px;

    @media screen and (max-width: 766px) {
      padding-left: 16px;
      padding-right: 16px;
      flex-direction: column;
    }

    & .divider {
      @media screen and (max-width: 766px) {
        align-items: flex-start;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }

    & .category-breadcrumb-3 {
      font-weight: 400;
      font-size: 18px;

      @media screen and (min-width: 767px) {
        margin-right: 0.5rem;
        padding-right: 3px;
      }

      @media screen and (max-width: 766px) {
        font-size: 20px;
        display: none;
      }
    }
    & .category-name-3 {
      font-size: 18px;
      font-weight: 600;
    }
    & .product-count-3 {
      font-size: 16px;

      @media screen and (max-width: 766px) {
        font-size: 12px;
      }
    }
    .filter-sorting {
      @media screen and (min-width: 767px) {
        margin-left: auto;
      }
      @media screen and (max-width: 766px) {
        max-width: 100%;
      }
    }
  }

  &.category-header-4 {
    display: flex;
    padding: 40px 70px;

    @media screen and (min-width: 767px) {
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: 766px) {
      display: block;
      padding: 20px;
    }

    & .category-breadcrumb-4 {
      font-weight: 400;
      font-size: 22px;

      @media screen and (min-width: 767px) {
        margin-right: 0.5rem;
      }

      @media screen and (max-width: 766px) {
        font-size: 20px;
      }
    }
    & .category-name-4 {
      font-size: 22px;
      font-weight: 600;

      @media screen and (max-width: 766px) {
        font-size: 20px;
      }
    }
    & .product-count-4 {
    }
  }
}
