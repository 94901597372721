.blogPage-main {
  max-width: 867px;
  margin: 20px auto;
  padding: 80px 16px;

  &__image {
    width: 100%;

    & .date-box {
      min-width: 89px;
      width: fit-content;
      height: 79px;
      background: #0d0e0f 0% 0% no-repeat padding-box;
      box-shadow: 0px -3px 30px #0000000f;
      border-radius: 15px;
      border-left: hidden;
      color: #fff;
      padding: 7px;
      position: absolute;
      bottom: -25px;
      left: 36px;
    }
  }

  &__body {
    & > h3 {
      font-size: 24px;
      font-weight: 600;
    }

    & > p {
      color: #818181;
      font-size: 18px;
      white-space: pre-wrap;
    }
  }
}

@media screen and (min-width: 767px) {
  .blogPage-main__body {
    padding: 0 36px;
  }
}
