.bankOfferModal {
  position: relative;
  &__header {
    border-radius: 10px 10px 0 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f8f8f8;
    padding: 25px 30px;
    & > p {
      margin: 0;
      font-weight: 600;
      font-size: 18px;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    padding: 92px 30px 25px;
    overflow-y: auto;
  }
  &__list {
    border-bottom: 0.4px solid #c3c3c3;
    padding-bottom: 17px;
    margin-bottom: 21px;
    &--header {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    &--desc {
      font-size: 14px;
    }
  }
}
