.promoList {
  max-width: 1000px;
  margin: 0 auto 64px;
  @include respond-to('small') {
    & {
      margin-bottom: 72px;
    }
  }
  .header {
    .promoList__title {
      margin-bottom: 2rem;
      font-size: 28px;
      @include respond-to('small') {
        & {
          margin-bottom: 3rem;
          font-size: 40px;
        }
      }
    }
    .action-wrapper {
      & > img {
        filter: invert(1);
      }
    }
  }

  .slider-scrollbar {
    // background-color: #e2e2ee;
    height: 6px;
  }

  .couponDetails-overlay {
    .topdesign {
      position: relative;
      background-color: #ffcece;
      margin: -15px -15px 0 -15px;

      & > img {
        object-fit: cover;
      }

      &::before {
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #000000f2;
        z-index: 999;
        border-radius: 0 50% 50% 0;
        bottom: -20px;
        clip-path: circle(50% at 0 50%);
      }

      &::after {
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #000000f2;
        z-index: 999;
        border-radius: 50% 0 0 50%;
        bottom: -20px;
        right: 0;
        clip-path: circle(50% at 99% 50%);
      }
    }

    .cross-icon {
      bottom: -85px;
      left: 50%;
      transform: translateX(-50%);

      // & > span {
      //     width: 24px;
      //     height: 24px;
      //     background-color: #000000f2;
      // }
    }
  }
}

/** Slider CSS */
.promoList > .slick-slider.slick-initialized {
  padding: 0px;
  background-color: transparent;
  border-radius: 8px;
}

.promoList .slick-track {
  display: flex;
}

.promoList .slick-track .slick-slide {
  height: inherit;
}

.promoList .slick-track .slick-slide > div {
  height: 100%;
}

.promoList > .slick-initialized .slick-slide {
  padding: 0 !important;
}

.promoList > .slick-initialized .slick-slide > div {
  margin: 0 0px 0 15px;
}
