.carousel-widget-slider {
  .slick-slider,
  .slick-track,
  .slick-list,
  .slick-slide {
    height: 100%;
  }

  .slider-container {
    height: 100%;
    position: relative;
  }
  /* temporary hack for double container */
  .slider-container + .slider-container {
    height: 100%;
    display: none;
  }

  .arrow-btns {
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .image-container {
    position: relative;
    display: flex;
    // height: 100%;
    width: 100%;
  }
  .storeName-container {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .storeName-image-container {
    width: 4rem;
    height: 4rem;
  }
  .storeName-name {
    color: #fff;
  }
  .storeName-description {
    width: 100%;
    max-width: 20rem;
  }

  /* background-image: url(https://cdn.dotpe.in/longtail/additional_pages/7588027/4Jag4N54.jpeg);
    height: 400px;
    background-size: cover;
    background-position: center center; */
}
