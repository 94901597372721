.desktopView {
  display: none;
  position: absolute;
  bottom: 0;
  top: 100%;
  width: 100%;
  height: 100vh;
  background-color: hsla(0, 0%, 0%, 0.45);
  // z-index: 999;
  overflow-y: auto;

  .collectionListWrapper {
    max-width: 250px;
    width: 100%;
    // background-color: #f8f8f8;
    max-height: 100%;
    height: 100%;
    overflow: scroll;
    box-shadow: 4px -1px 5px -1px rgba(0, 0, 0, 0.04);

    .collectionNameMenu {
      display: flex;
      gap: 4px;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;

      &:hover {
        background: #f8f8f8;
        cursor: pointer;
      }

      &.highlighted {
        background: #f8f8f8;
      }

      .collectionName {
        display: flex;
        max-width: 80%;
        overflow: scroll;
        scrollbar-width: none;
        align-items: center;
        color: #414141;
        // font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // text-transform: capitalize;
      }

      .leftCaratIcon {
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .collectionContentWrapper {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-height: 100%;
    height: 100%;
    overflow: scroll;
    width: 100%;

    .collectionContent {
      // height: 220px;
      width: 100%;
      display: flex;
      gap: 24px;

      .collectionImage {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 210px;
        width: 210px;

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }

      .categoriesDisplay {
        display: flex;
        flex-direction: column;
        gap: 16px;
        // max-width: 90%;
        // overflow: hidden;
        .collectionName {
          color: #2b2b2b;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          cursor: pointer;
        }

        .categoriesWrapper {
          display: flex;
          width: '700px';
          gap: 24px;

          .categoryColumn {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .categoryItem {
              color: #6d6d6d;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
              min-width: 140px;
              max-width: 140px;
              cursor: pointer;

              &:hover {
                color: #333;
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

.mobileView {
  width: 100%;
  max-width: 100%;
  max-height: 400px;
  overflow: scroll;
  display: none;
  padding-right: 4px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .collectionMobileAccordionContainer {
    width: 100%;
    .collectionAccordionItem {
      // padding: 12px 16px;
      width: 100%;
      max-width: 100%;

      .collectionWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .collectionImageAndName {
          display: flex;
          gap: 12px;
          align-items: center;

          .collectionImage {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 2px;

            img {
              max-height: 28px;
              max-width: 28px;
              border-radius: 4px;
            }
          }

          .collectionName {
            color: #111c36;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
          }
        }
      }

      .categoriesWrapper {
        color: #111c36;
        display: none;
        gap: 12px;

        .categoryImage {
          width: 32px;
          height: 32px;
          border-radius: 2px;
          opacity: 0;
        }

        .categoryItem {
          // border-left: 1px solid #e2e2e2;
          // padding-left: 1rem;

          .categoryName {
            color: #6a6a6a;
            font-size: 13px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-transform: capitalize;
          }
        }

        &.open {
          display: flex;
        }
      }
    }
  }
}

.desktopView.open {
  display: block;
}

.mobileView.open {
  display: block;
}
