.login-component {
  &__dropdown {
    background: white;
    top: 3.3rem;
    right: 0;
    box-shadow: 0px 2px 6px 0px #0000001f;
    &--arrow {
      position: absolute;
      height: 1px;
      width: 20px;
      transform: translate(94px, -5px) rotateZ(180deg);
      margin-top: -0.4rem;
      right: 7rem;
      // &::before {
      //   content: '';
      //   border-top: 10px solid white;
      //   border-right: 10px solid transparent;
      //   border-left: 10px solid transparent;
      //   border-bottom: 10px solid transparent;
      // }
    }
  }
}
