.error-page-wrap {
    display: flex;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 30px;
    color: #333;
    background-color: #eaece7;
    text-align: center;
    h1 {
      font-weight: 600;
      font-size: 16px;
      opacity: 0.39;
    }
    p {
      font-weight: 700;
      font-size: 20px;
      
    }
    img {
      max-width: 200px;
      margin-bottom: 20px;
    }
  }
  