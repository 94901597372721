// html, body{
//     width: 100%;
//     height: 100%;
//     margin: 0px;
// }

// body{
//     display: table;
// }

.toggleTextSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
    /*switch styles*/

    & > label {
        font-size: 11px;
        color: #424242;
        font-weight: 500;
    }

    .btn-color-mode-switch {
        display: inline-block;
        margin: 0px;
        position: relative;
    }

    .btn-color-mode-switch>label.btn-color-mode-switch-inner {
        margin: 0px;
        width: 148px;
        height: 40px;
        background: #E0E0E0;
        border-radius: 26px;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease;
        /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
        display: block;
    }

    .btn-color-mode-switch>label.btn-color-mode-switch-inner:before {
        content: attr(data-on);
        position: absolute;
        font-size: 11px;
        font-weight: 600;
        top: 12px;
        right: 10px;
        color: #A3A3A3;
    }

    .btn-color-mode-switch>label.btn-color-mode-switch-inner:after {
        content: attr(data-off);
        width: 70px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EA5151;
        color: #fff;
        border-radius: 26px;
        position: absolute;
        left: 0px;
        top: 0px;
        text-align: center;
        transition: all 0.3s ease;
        box-shadow: 0px 0px 6px -2px #111;
        padding: 5px 0px;
        font-weight: 600;
    }

    .btn-color-mode-switch>.alert {
        display: none;
        background: #FF9800;
        border: none;
        color: #fff;
    }

    .btn-color-mode-switch input[type="checkbox"] {
        cursor: pointer;
        width: 50px;
        height: 25px;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 0px;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner {
        background: #EA5151;
        color: #fff;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:after {
        content: attr(data-on);
        left: 77px;
        background: #EA5151;
        color: #fff;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:before {
        content: attr(data-off);
        right: auto;
        left: 10px;
        // background: #EA5151;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner {
        background: #EEEEF1;
        color: #424242;
        // color: #fff;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked~.alert {
        display: block;
    }
}