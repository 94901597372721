.horizontal-reason-modal {
  position: absolute;
  background: #fff;
  box-shadow: 0px 3px 6px #00000015;
  width: 395px;
  height: 190px;
  border: 1px solid #f0f0f0;
  margin-left: -158px;
  margin-top: 1px;
  .left-section {
    height: 187px;
  }
  .right-section {
    height: 187px;
  }
  .left-section-text-body {
    color: #666666;
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    width: 160px;
  }
  .right-removead-section {
    border: 1px solid #e4e5ec;
    border-radius: 8px;
    color: #397ef5;
  }
}

.vertical-reason-modal {
  position: absolute;
  background: #fff;
  box-shadow: 0px 3px 6px #00000015;
  margin-left: -10.7rem;
  margin-top: 2.1rem;
  width: 11.8rem;
  height: 14rem;
  display: flex;
  .top-section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .vertical-removead-section {
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    color: #397ef5;
  }
}

.mobile-reason-modal {
  position: absolute;
  background: #fff;
  margin-top: 1.4rem;
  margin-left: -1rem;
  min-height: 9rem;
  box-shadow: 0px 3px 6px #00000015;
  border: 1px solid #f0f0f0;
  color: #666666;
  .top-section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .remove-adsection {
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    color: #397ef5;
  }
}

.middle-body-mobile-reason-modal {
  position: absolute;
  background: #fff;
  margin-top: 1.4rem;
  margin-left: -1.15rem;
  height: 126px;
  box-shadow: 0px 3px 6px #00000015;
  border: 1px solid #f0f0f0;
  color: #666666;
  .top-section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .remove-adsection {
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    color: #397ef5;
  }
}
