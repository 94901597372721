.filterDrawerWrapper {
  display: flex;
  border-right: 1px solid #eaeaea;
  position: sticky;
  height: 100vh;
  width: 300px;
  background-color: white;

  @media screen and (max-width: 767px) {
    width: 100vw;
    top: 0px;
  }

  .filterDrawerMobileCtaContainer {
    display: flex;
    position: absolute;
    bottom: 0%;
    height: 48px;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background: white;

    .filterDrawerMobileCta {
      all: unset;
      display: flex;
      height: 48px;
      width: 50vw;
      justify-content: center;
      align-items: center;
      background: white;
      border: 1px solid #e2e2e2;

      :last-child {
        border-left: none;
      }
    }
  }
}