.Header8 {
  height: 75px;
  position: sticky;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 48px;
  z-index: 90;
  box-shadow: 0px 3px 6px #00000029;

  &__mobile {
    display: none;
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & > &--subsection {
      display: flex;
      align-items: inherit;

      & > span {
        display: flex;
        align-items: center;

        & > img {
          width: 25px;
          height: 25px;
          cursor: pointer;
          margin-left: 22px;
        }
      }
      .header-8-arrowimage {
        width: 6px;
        height: 11px;
        cursor: pointer;
      }
      @mixin header-8-orderdropdown-container {
        background: white;
        transform: translate(-123px, 145px);
        box-shadow: 0px 3px 6px #00000029;
        .header-8-orderdropdown-item {
          border-top: 1px solid rgba(0, 0, 0, 0.11);
          width: 200px;
          height: 60px;
          padding: 10px 10px 10px 20px;
          &:nth-child(2) {
            height: 96px;
            border-top: none;
          }
          &:nth-child(3) {
            margin-top: -20px;
            border-top: none;
          }
        }
        .header-8-orderdropdown-container-arrow {
          height: 1px;
          width: 20px;
          margin-left: 2.5rem;
          transform: translate(94px, -5px) rotateZ(180deg);
          margin-top: -0.4rem;
        }
        .header-8-orderdropdown-container-arrow::before {
          content: '';
          border-top: 10px solid white;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid transparent;
        }
      }
      .header-8-orderdropdown-container {
        @include header-8-orderdropdown-container;
      }
      .header-8-orderdropdown-container-nologin {
        @include header-8-orderdropdown-container;
        transform: translate(-123px, 117px);
      }
      .header-8-arrowsvg {
        width: 10px;
      }

      & .cart-item-length-comp {
        height: 15px;
        width: 15px;
        border-radius: 999px;
        background-color: #ff5757;
        top: -7px;
        right: -9px;
        left: 48px;
        color: #ffffff;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 767px) {
          height: 20px;
          width: 20px;
          top: -9px;
          left: 47px;
        }
      }
    }
  }
  &__name-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 25vw;
  }
  & .store-logo {
    height: 50px;
    width: 50px;
    margin-right: 15px;
  }
  & .store-name {
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    width: 260px;
    line-height: 18px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
}

@media screen and (max-width: 767px) {
  .Header8 {
    height: 56px;
    padding: 0 30px;
    flex-direction: column;

    &__mobile {
      display: inline-block;
      &.navigation__container {
        position: fixed;
        z-index: inherit;
        top: 56px;
        background-color: rgba(0, 0, 0, 0.62);
        width: 100%;
        height: calc(100vh - 56px);
      }
      .navigation__container--inner {
        background-color: #ffffff;
        padding: 10px 20px;
        height: fit-content;
        overflow-y: auto;
        max-height: calc(100vh - 56px);
        .mobile-login-navigation-container {
          border-top: 1px solid #ebebeb;
          width: 100%;
          padding-top: 20px;
          padding-bottom: 15px;
        }
      }
    }

    &__desktop {
      display: none;
    }
    &__inner {
      & > &--subsection {
        & > img {
          width: 20px;
          height: 20px;
          margin-left: 22px;
        }
      }
    }
  }
}
