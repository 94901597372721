.widgetFormGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
  align-items: flex-start;

  label {
    font-size: 0.75em;
    margin-bottom: 0.125rem;
  }

  input,
  select,
  :global(.jsonFormBuilder-input) {
    border-radius: 0.25em;
    font-size: 0.8rem;
    padding: 0.125em 0.25em;
    border: 1px solid #666;
  }
}

.widgetFormLabel {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
}

.widgetFormGroupArrayItem {
  background-color: #ffffff;
  margin-bottom: var(--spacing-16);

  .dragHandle {
    color: #999;
    cursor: move;
    text-align: center;
    box-shadow: 0 -2px 0 -1px #ddd inset;
  }
}
