.ds-fullscreenMultiMediaViewer {
  .content-wraaper {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .right-detials {
      .close-icon {
        filter: grayscale(1);
      }
    }
  }
}

@media screen and (min-width: 766px) {
  .ds-fullscreenMultiMediaViewer {
    .content-wraaper {
      max-width: 1102px;
      max-height: 658px;

      .image-slider {
        .back-icon {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: #ffffff44;
          img {
            filter: invert(1);
            opacity: 0.7;
          }
          &:hover {
            background-color: #ffffff80;
          }
        }
        .forward-icon {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          background-color: #ffffff44;
          img {
            filter: invert(1);
            opacity: 0.7;
          }
          &:hover {
            background-color: #ffffff80;
          }
        }
      }
    }

    .right-detials {
      .close-icon {
        filter: grayscale(1);
      }
      .image-selector-wrap {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        // grid-column-gap: 5px;
        grid-row-gap: 20px;
        // overflow-y: scroll;
        overflow-y: auto;
        max-height: 450px;

        .img-wrap {
          height: 86px;
          width: 86px;

          & img {
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 766px) {
  .ds-fullscreenMultiMediaViewer {
    .right-detials {
      .image-selector-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // overflow-y: scroll;
        overflow-x: auto;
        width: 450px;

        .img-wrap {
          height: 60px;
          width: 60px;
          margin-right: 10px;

          & img {
            object-fit: cover;
          }
        }
      }
    }
  }
}

/** Slider CSS */
.ds-fullscreenMultiMediaViewer {
  .image-slider > .slick-slider > .slick-list,
  .slick-track {
    height: 100%;

    & img {
      object-fit: contain;
    }
  }
  .image-slider > .slick-slider {
    width: 100%;
    height: 100%;
  }
  .image-slider > .slick-slider.slick-initialized {
    padding: 0px;
    background-color: transparent;
    border-radius: 8px;
  }
  .image-slider > .slick-initialized .slick-slide {
    padding: 0 !important;
    & .react-transform-wrapper {
      height: 100%;
      & > .react-transform-component {
        height: 100%;
        & > img {
          object-fit: contain;
        }
      }
    }
    & .iiz {
      width: 100%;
      height: 100%;
      & > div {
        width: 100%;
        height: 100%;
        & > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .image-slider > .slick-initialized .slick-slide > div {
    // margin: 0 0px 0 15px;
    height: 100%;
  }
}
