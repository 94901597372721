.horizontal-CategoryWidget {
  margin-bottom: 64px;
  .title {
    font-size: 18px;
    margin: 0 0 48px;
    & {
      margin-bottom: 32px;
    }
  }

  & .arrow-btns {
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    padding: 20px;
    height: 5rem;
    @media screen and (max-width: 766px) {
      padding: 15px;
      height: 4rem;
    }
  }

  .scroll-wrapper {
    display: flex;
    min-height: 200px;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &:first-child {
      min-width: 50px;
    }

    & > article {
      margin-right: 24px;
      min-width: 300px;
      max-width: 300px;
      width: 300px;
    }

    & .view-more-box {
      &--inner {
        border: 0.4px solid #6f6e6e;
        width: 100%;
        &:hover {
          border-color: #000000;
        }
        & > span {
          position: relative;
          margin: 0 auto;
          color: #6f6e6e;
          font-size: 22px;
          letter-spacing: 0;
          cursor: pointer;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          & > span {
            position: absolute;
            bottom: -4px;
            left: 0;
            background-color: #000000;
            width: 0;
            height: 1px;
            transition: width ease-in-out 300ms;
          }
        }
      }
      &:hover {
        & > div > span {
          color: #000000;
          & > span {
            width: 100%;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 0px;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  & > .slick-initialized .slick-slide > div {
    margin: 0 0 0 15px;
  }
}

.horizontal-CategoryWidget2 {
  position: sticky;
  .title {
    font-size: 18px;
    margin: 64px 0 14px 0;
  }

  & .arrow-btns {
    top: 66px;
    z-index: 10;
    padding: 20px;
    height: 5rem;
    @media screen and (max-width: 766px) {
      padding: 15px;
      height: 4rem;
    }
  }

  .scroll-wrapper {
    display: flex;
    overflow-y: hidden;
    padding: 10px 0 20px;

    &:first-child {
      min-width: 50px;
    }

    & > article {
      margin-right: 24px;
      min-width: 300px;
      max-width: 300px;
      width: 300px;
    }

    &::-webkit-scrollbar {
      width: 0px;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &.custom-scroll {
      border-bottom: 1px solid #70707040;
    }
  }
}

@include respond-to('small') {
  .horizontal-CategoryWidget {
    margin: 0 0 72px;
    .title {
      font-size: 26px;
      margin: 0 0 48px;
    }

    .scroll-wrapper {
      min-height: fit-content;
      & > .category-card-3 {
        margin-right: 24px;
        min-width: 400px;
      }
    }
  }
  .horizontal-CategoryWidget2 {
    .title {
      font-size: 26px;
      margin: 70px 0 40px 0;
    }

    .scroll-wrapper {
      padding-bottom: 30px;
    }
  }
}
