.common-header-icons-mobile {
  & .mobile-icons {
    & > img {
      width: 25px;
      height: 25px;
      cursor: pointer;
      margin-left: 20px;
    }

    & > .cart-item-length-comp {
      background: #000000;
      color: #ffffff;
      font-size: 12px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -10px;
      right: -4px;
    }
  }
}
