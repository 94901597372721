.loader-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffffeb;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  .loadersmall {
    border: 5px solid #47a517;
    animation: spin 1s linear infinite;
    border-top: 5px solid #ffffff;
    border-radius: 50%;
    width: 65px;
    height: 65px;
  }
  .image-component {
    position: fixed;
    max-width: 50px;
    max-height: 50px;
    height: 100%;
    width: 100%;
  }
  span {
    position: fixed;
  }
}
