.pre-dd-miviewer {
  display: flex;
  flex-direction: column;
  min-height: 400px;

  &__side {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 20px 0 20px;

    &--image {
      min-height: 60px;
      min-width: 60px;
      margin-right: 10px;
      border: 1px solid;
      border-radius: 6px;
      max-width: 90px;
      max-height: 90px;
      img {
        border-radius: 5px;
      }
    }
  }
  &__main {
    display: flex;
    flex-grow: 3;
    .variant-count {
      font-size: 12px;
    }
    & .image-box {
      & > div {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
        & > img {
          height: 100% !important;
          object-fit: contain;
        }
      }
    }
  }
}

@media screen and (min-width: 767px) {
  .pre-dd-miviewer {
    display: flex;
    flex-direction: row-reverse;
    padding: 20px;
    border: 1px solid #e2e2e2;
    border-radius: 6px;

    &__side {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      justify-content: flex-start;
      align-items: center;
      padding: 0 20px 20px 0;

      &--image {
        min-height: 60px;
        min-width: 60px;
        margin-right: 10px;
        border: 1px solid;
        border-radius: 6px;
        margin: 5px 0;
        max-width: 90px;
        max-height: 90px;
        img {
          border-radius: 5px;
        }
      }
    }
    &__main {
      display: flex;
      flex-grow: 3;
      height: 100%;
      max-height: 400px;

      & > img {
        width: 100%;
        object-fit: cover;
        height: 400px;
      }
    }
  }
}

@media screen and (max-width: 766px) {
  .pre-dd-miviewer {
    width: 100%;
    &__main {
      height: 50vh;
      min-height: 350px;
      border-bottom: 0.5px solid #e2e2e2;

      & > img {
        margin: 0 auto;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
