.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #EA5151;
  transform: translate(0, -50%);
}
.custom-radio-btn {
  position: relative;
  label {
    padding-left: 36px;
    position: relative;
    input {
      display: none;
    }
  }
}

.custom-radio-btn input[aria-checked="true"] ~ .checkmark:after {
  background-color: #EA5151;
}

.custom-radio-btn .checkmark:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
}
