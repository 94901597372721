.addressAndContactBtnStrip {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 16px;
    box-shadow: 0px 3px 6px #00000029;

    &--address {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 10px;

        & > img {
            max-width: 100%;
            height: 20px;
        }
        & > span {
            display: -webkit-box;
            margin-left: 5px;
            font-size: 12px;
            letter-spacing: 0.33px;
            color: #282E33;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}