.paymentPromoDetailsModal {
  .close-icon {
    background-color: #c5c5c529;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -72px;
    border-radius: 50%;
    width: 44px;
    height: 44px;

    & > img {
      height: 25px;
    }
  }
  .promoCodeBox {
    display: inline-block;
    border: 1px dashed #d5d5d5;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 24px 0px;
    font-size: 21px;
  }

  .logo-coupon-desc {
    img {
      flex-shrink: 0;
    }
  }
  .notesList {
    padding: 20px 20px 0px;
    border-top: 0.5px solid #9ea6b9;
    li {
      font-size: 14px;
      color: #939393;
      font-size: 400;
      margin-bottom: 18px;
      // &::marker {
      //   content: ".";
      //   font-size: 1.5rem;
      //   color: black;
      //   vertical-align: bottom;
      // }
    }
  }
}

@media screen and (min-width: 767px) {
  .paymentPromoDetailsModal {
    padding: 40x 60px !important;
  }
}
