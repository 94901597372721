.store-desc {
  .desc-text {
    display: block;
    color: #ececec;
    line-height: 1.2rem;

    &__readmore {
      color: #ffffff;
      padding: 2px;
      border-bottom: 1px dotted #ffffff;
      font-weight: 600;
      font-size: 12px;
    }
  }

  // modal ----
  .storeDesc {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 999;
    background: #000000b5;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__modal {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 9px;
      margin: 0 22px;
      padding: 9px 10px 0 10px;
      width: 90%;
      max-width: 400px;

      &--cancelIcon {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        &>img {
          width: 16px;
          filter: grayscale(1);
        }
      }

      &--imgLogo {
        max-width: 85px;
        max-height: 85px;
        box-shadow: 0px 3px 6px #00000029;
        border: 4px solid #FFFFFF;

        &>img {
          max-width: 100%;
        }
      }

      &--qoute {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0px;
        color: #000000;
        margin: 18px 0 7px 0;
      }

      &--text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.31px;
        color: #868D9B;
        padding: 0 10px;
      }

      &--linebrk {
        display: block;
        width: 70%;
        border-bottom: 1px dashed #ADB1B9;
        margin: 15px 0;
      }

      &--button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        ;
        border-radius: 30px;
        color: #EA5151;
        font-size: 15px;
        font-weight: 600;
        margin: 21px 0 25px 0;
        padding: 12px 0;
        width: 90%;
        border: 1px solid #EA5151;

        &>img {
          max-width: 100%;
          height: 20px;
          margin-right: 8px;
        }
      }


      &--address {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        font-size: 11px;
        letter-spacing: 0.11px;
        color: #6A7279;
        padding: 6px 30px;

        &>img {
          max-width: 16px;
          margin-right: 3px;
          padding-top: 1px;
        }

        &>span {
          text-align: center;
          font-size: 12px;
          color: #606060;
        }
      }
    }
  }
}