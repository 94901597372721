.SubHeaderComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 0rem;
  width: 100%;

  marquee {
    & > div {
      width: 150vw;
    }

    @media screen and (min-width: 767px) {
      font-size: 13px;
    }

    @media screen and (max-width: 766px) {
      font-size: 12px;
    }
  }

  & > span {
    @media screen and (min-width: 767px) {
      font-size: 13px;
    }

    @media screen and (max-width: 766px) {
      font-size: 12px;
    }
  }

  .pre-marquee-tag {
    justify-content: center;
    transition: all 0.2s ease;

    @media screen and (min-width: 767px) {
      width: 40%;
    }

    @media screen and (max-width: 766px) {
      width: 80%;
    }
  }
}
