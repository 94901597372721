.backgroundPreview {
  width: 10rem;
  height: 1.5rem;
}

.colorPickerPopover {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
}
