.emiCardPayment {
  position: relative;

  .page-header {
    padding: 12px 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px #0000000f;

    & > .header-text {
      font-size: 17px;
    }
  }

  &__header {
    margin: 0 0 16px;
    font-size: 16px;
    font-weight: 600;

    & > img {
      filter: grayscale(1);
      width: 14px;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  & .card-header-text {
    font-size: 14px;
    color: #848484;
    margin-bottom: 10px;
  }

  & .card-information-section {
    &--first {
      flex: 4 1 100%;
      padding-right: 15px;
    }

    &--second {
      flex: 1 1 auto;
      padding-left: 15px;
    }
  }

  .card-header-edit {
    font-size: 14px;
    color: #3d6afd;
    position: relative;
    margin-bottom: 10px;
  }

  .emi-input,
  .emi-select {
    border-radius: 7px;
    border: 0.4px solid #c2c2c2;
    background-color: #ffffff;
    padding: 18px 22px;
  }

  .emi-select {
    position: relative;
    width: 100px;
    -webkit-appearance: none;
    appearance: none !important;

    & ~ img {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
    }
  }

  .emi-input {
    &.invalid {
      border-color: #dd0001;
    }

    &::placeholder {
      color: #848484;
    }
  }

  .affirmation-btn {
    background-color: #000000;
    font-size: 16px;
    color: #ffffff;
    padding: 10px 30px;
    border: 1px solid #000000;
    outline: none;
  }

  .edit-btn {
    border: 1px solid #000000;
    background-color: #ffffff;
    color: #000000;
    font-weight: 600;
    padding: 10px 30px;
    font-size: 16px;
    width: fit-content;
    white-space: nowrap;
  }

  .emi-option-accordion {
    &__header {
      background-color: #f7f7f7;
      padding: 20px 24px;
      border-bottom: 0.5px solid #d6d6d6;

      & > p {
        font-size: 16px;
      }
    }

    &__body {
      padding: 30px 24px;
      background-color: #ffffff;

      .emi-table {
        border-collapse: collapse;

        & * {
          text-align: left;
        }

        & thead {
          & th {
            width: 33.33%;
            padding: 0 0 15px;
            font-weight: 400;
            color: #7b7b7b;
          }
        }
      }
    }
  }

  .emi-step-completed {
    border-radius: 7px;
    border: 0.4px solid #c2c2c2;
    background-color: #fafafa;
    padding: 18px 22px;

    & > p {
      margin: 0;
    }
  }

  .card-image {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  & small {
    position: absolute;
    left: 0;
    top: calc(100% + 10px);

    &.error {
      color: #ee7f7f;
    }
  }

  .cvvShowcasePopup {
    background-color: #ffffff;
    max-width: 370px;
    border-radius: 8px;
    width: 100%;
    padding: 20px;
    position: absolute;
    top: -180px;
    left: 0;
    box-shadow: 0px 0px 10px -2px rgb(0 0 0 / 50%);

    &::before {
      content: '';
      bottom: -14px;
      left: 102px;
      transform: translateX(-50%);
      position: absolute;
      border-top: 15px solid #fefefe;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
    }

    & > p {
      margin: 0 0 10px;
      font-size: 12px;
    }

    & > img {
      width: 70%;
    }
  }
}

@media screen and (max-width: 767px) {
  .emiCardPayment {
    padding: 80px 16px 90px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    & .mobile-affirm-btn-container {
      position: fixed;
      bottom: 0;
      background-color: #ffffff;
      padding: 20px 0;
      width: calc(100% - 32px);
      left: 50%;
      transform: translateX(-50%);

      & > .affirmation-btn {
        width: 100%;
        padding: 13px;
      }
    }

    &__header {
      font-size: 15px;
    }

    .card-information-section--first {
      padding-right: 0;
      margin-bottom: 36px;
    }

    .card-information-section--second {
      padding-left: 0;
    }

    .emi-option-accordion {
      &__header {
        padding: 18px 10px;

        & > .custom-radio {
          flex: 0 0 auto;
          width: 18px;
        }

        & > p {
          font-size: 14px;
        }
      }

      &__body {
        padding: 24px 9px;

        .emi-table {
          & thead {
            & th {
              font-weight: 600;
              font-size: 12px;
              color: #7b7b7b;
              padding: 0 5px 15px;

              &:first-child {
                width: 40%;
              }

              &:not(:first-child) {
                width: 30%;
              }
            }
          }

          & tbody {
            & td {
              font-size: 14px;
              padding-left: 5px;
              padding-right: 5px;
            }
          }
        }
      }
    }

    .affirmation-btn {
      font-size: 14px;
      padding: 8px 26px;
    }

    .emi-step-completed {
      padding: 19px 15px;

      & > p {
        font-size: 14px;
        line-height: 20px;
      }
    }

    & .emi-input,
    & .emi-select {
      padding: 18px 16px;
    }

    & small {
      top: 110%;
    }

    .cvvShowcasePopup::before {
      left: 25%;
    }
  }
}
