.widgetBtnShadowWrapper {
  transition: box-shadow 600ms;
  position: relative;

  &:hover {
    box-shadow: 0px 3px 24px 0px #00000047;
  }
}

.widgetProductBtnShadowWrapper {
  transition: box-shadow 600ms;
  position: relative;

  &:hover {
    box-shadow: 0px 2px 6px 0px #111c363d;
  }
}

.widgetBtnMoveUpWrapper {
  position: relative;
  transition: transform;
  transition-duration: 0.4s !important;

  &:hover {
    transform: translate(0, -4.5px);
  }
}

.widgetBtnFillDownWrapper {
  position: relative;

  &::before {
    content: ' ';
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--before-bg, rgba(17, 28, 54, 0.2));
    border-radius: var(--border-radius, '0px');
    transform: scaleY(0);
    transform-origin: top left;
    transition: transform 0.5s;
    transition-timing-function: ease;
    // z-index: -1;
    // transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    z-index: 1;
  }

  &:hover::before {
    transform: scaleY(1);
  }
}

.widgetBtnFillUpWrapper {
  position: relative;

  &::before {
    content: ' ';
    bottom: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--before-bg, rgba(17, 28, 54, 0.2));
    border-radius: var(--border-radius, '0px');
    transform: scaleY(0);
    transform-origin: bottom left;
    transition: transform 0.5s;
    transition-timing-function: ease;
    // z-index: -1;
    // transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    z-index: 1;
  }

  &:hover::before {
    transform: scaleY(1);
  }
}

.widgetBtnFillInWrapper {
  position: relative;

  &::before {
    content: ' ';
    bottom: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--before-bg, rgba(17, 28, 54, 0.2));
    border-radius: var(--border-radius, '0px');
    transform: scale(0);
    transform-origin: 50% 50%;
    transition: transform 0.5s;
    transition-timing-function: ease;
    // z-index: -1;
    // transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    z-index: 1;
  }

  &:hover::before {
    transform: scale(1);
  }
}

.widgetBtnFillRightWrapper {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--before-bg, rgba(17, 28, 54, 0.2));
    border-radius: var(--border-radius, '0px');
    transition: all 0.5s;
    z-index: 1;
  }
  &:hover::before {
    width: 100%;
  }
}

.widgetBtnUnderlineRightWrapper {
  position: relative;
  transition: transform 0.25s;
  border: 0 !important;

  & p,
  .textualElement {
    width: max-content;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 0;
      width: 0%;
      height: 1.1px;
      background-color: #333a4f;
      transition: all 0.5s;
      z-index: 99;
    }
  }

  &:hover .textualElement::before {
    width: 100%;
  }

  &:hover p::before {
    width: 100%;
  }
}

.widgetBtnUnderlineRightWrapper2 {
  position: relative;
  transition: transform 0.25s;
  border: 0 !important;

  & p,
  .textualElement {
    width: max-content;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 0;
      width: 100%;
      height: 1.1px;
      background-color: #333a4f;
      z-index: 99;
    }
  }

  &:hover .textualElement::before {
    animation: animateUnderlineRightWrapper2 0.5s;
  }

  &:hover p::before {
    animation: animateUnderlineRightWrapper2 0.5s;
  }
}

@keyframes animateUnderlineRightWrapper2 {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
