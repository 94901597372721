.header-1 {
  > img {
    max-width: 80% !important;
    transform: translateY(3px);
  }
  &-arrow-img {
    width: 10px !important;
  }
  @mixin header-1-orderdropdown-container {
    background: white;
    transform: translate(281px, 145px);
    box-shadow: 0px 3px 6px #00000029;
    &-arrow {
      height: 1px;
      width: 20px;
      margin-left: 2.5rem;
      transform: translate(110px, -5px) rotateZ(180deg);
      margin-top: -0.4rem;
    }
    &-arrow::before {
      content: '';
      border-top: 10px solid white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
    &-item {
      cursor: pointer;
      border-top: 1px solid rgba(0, 0, 0, 0.11);
      width: 200px;
      height: 60px;
      padding: 10px 10px 10px 20px;
      &:nth-child(2) {
        height: 96px;
        border-top: none;
      }
      &:nth-child(3) {
        margin-top: -20px;
        border-top: none;
      }
    }
  }
  &-orderdropdown-container {
    @include header-1-orderdropdown-container;
  }
  &-orderdropdown-container-nologin {
    @include header-1-orderdropdown-container;
    transform: translate(281px, 117px);
  }
}
.header-1-mobile-login-navigation-container {
  border-top: 1px solid rgba(0, 0, 0, 0.11);
  width: 100vw;
  transform: translate(-32px);
  padding-left: 34px;
  padding-top: 20px;
  padding-bottom: 14px;
  height: 26px;
  .mobile-translate-logout-text {
    transform: translate(41px, -25px);
  }
}

.header-2 {
  > img {
    max-width: 80% !important;
    transform: translateY(3px);
  }
  &-arrow-img {
    width: 10px !important;
  }
  @mixin header-2-orderdropdown-container {
    background: white;
    transform: translate(290px, 145px);
    box-shadow: 0px 3px 6px #00000029;
    &-arrow {
      height: 1px;
      width: 20px;
      margin-left: 2.5rem;
      transform: translate(110px, -5px) rotateZ(180deg);
      margin-top: -0.4rem;
    }
    &-arrow::before {
      content: '';
      border-top: 10px solid white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
    &-item {
      border-top: 1px solid rgba(0, 0, 0, 0.11);
      width: 200px;
      height: 60px;
      padding: 10px 10px 10px 20px;
      &:nth-child(2) {
        height: 96px;
        border-top: none;
      }
      &:nth-child(3) {
        margin-top: -20px;
        border-top: none;
      }
    }
  }
  &-orderdropdown-container {
    @include header-2-orderdropdown-container;
  }
  &-orderdropdown-container-nologin {
    @include header-2-orderdropdown-container;
    transform: translate(290px, 117px);
  }
}
.header-2-mobile-login-navigation-container {
  border-top: 1px solid rgba(0, 0, 0, 0.11);
  width: 100vw;
  transform: translate(-32px);
  padding-left: 34px;
  padding-top: 20px;
  padding-bottom: 14px;
  height: 26px;
  .mobile-translate-logout-text {
    transform: translate(41px, -25px);
  }
}

.header-3 {
  > img {
    max-width: 80% !important;
    transform: translateY(3px);
  }
  &-arrow-img {
    width: 10px !important;
  }
  @mixin header-3-orderdropdown-container {
    background: white;
    transform: translate(293px, 142px);
    box-shadow: 0px 3px 6px #00000029;
    &-arrow {
      height: 1px;
      width: 20px;
      margin-left: 2.5rem;
      transform: translate(110px, -5px) rotateZ(180deg);
      margin-top: -0.4rem;
    }
    &-arrow::before {
      content: '';
      border-top: 10px solid white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
    &-item {
      border-top: 1px solid rgba(0, 0, 0, 0.11);
      width: 200px;
      height: 60px;
      color: #000000;
      padding: 10px 10px 10px 20px;
      &:nth-child(2) {
        height: 96px;
        border-top: none;
      }
      &:nth-child(3) {
        margin-top: -20px;
        border-top: none;
      }
    }
  }
  &-orderdropdown-container {
    @include header-3-orderdropdown-container;
  }
  &-orderdropdown-container-nologin {
    @include header-3-orderdropdown-container;
    transform: translate(293px, 117px);
  }
}
.header-3-mobile-login-navigation-container {
  border-top: 1px solid rgba(0, 0, 0, 0.11);
  width: 100vw;
  transform: translate(-32px);
  padding-left: 34px;
  padding-top: 20px;
  padding-bottom: 14px;
  height: 26px;
  .mobile-translate-logout-text {
    transform: translate(41px, -25px);
  }
}

.header-7 {
  > img {
    max-width: 80% !important;
    transform: translateY(3px);
  }
  &-arrow-img {
    width: 10px !important;
  }
  @mixin header-7-orderdropdown-container {
    background: white;
    transform: translate(-11px, 145px);
    box-shadow: 0px 3px 6px #00000029;
    &-arrow {
      height: 1px;
      width: 20px;
      margin-left: 2.5rem;
      transform: translate(100px, -5px) rotateZ(180deg);
      margin-top: -0.4rem;
    }
    &-arrow::before {
      content: '';
      border-top: 10px solid white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
    &-item {
      border-top: 1px solid rgba(0, 0, 0, 0.11);
      width: 200px;
      height: 60px;
      color: #000000;
      padding: 10px 10px 10px 20px;
      &:nth-child(2) {
        height: 96px;
        border-top: none;
      }
      &:nth-child(3) {
        margin-top: -20px;
        border-top: none;
      }
    }
  }
  &-orderdropdown-container {
    @include header-7-orderdropdown-container;
  }
  &-orderdropdown-container-nologin {
    @include header-7-orderdropdown-container;
    transform: translate(-11px, 117px);
  }
}
.header-7-mobile-login-navigation-container {
  border-top: 1px solid rgba(0, 0, 0, 0.11);
  width: 100vw;
  transform: translate(-32px);
  padding-left: 34px;
  padding-top: 20px;
  padding-bottom: 14px;
  height: 26px;
  .mobile-translate-logout-text {
    transform: translate(41px, -25px);
  }
}

.header-logo-image {
  height: 50px;
  width: 50px;
}
