.collectionWidget1 {
  position: relative;
  @media screen and (min-width: 768px) {
    margin-bottom: 72px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 10px;
    margin-bottom: 64px;
  }
  &--header {
    margin: 0;
    text-align: center;
    @media screen and (min-width: 768px) {
      font-size: 24px;
      margin-bottom: 60px;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 26px;
    }
  }
  &__body {
    display: flex;
    min-height: 200px;

    &.horizontal-view {
      column-gap: 20px;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 20px 40px;
      & .cardWidget {
        width: 220px;
        min-width: 220px;
      }
    }

    &.grid-view {
      padding: 0 20px;
      max-width: 1160px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-gap: 15px;
      grid-template-columns: repeat(5, 1fr);
      grid-auto-rows: max-content;

      & .cardWidget {
        max-width: 100%;
      }
    }
    @media screen and (min-width: 768px) {
      padding: 60px 0;
      align-items: center;
    }
    @media screen and (max-width: 767px) {
    }
  }
}
