.premium-add-delivery-page {
  padding: 0px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  .premium-add-delivery-header {
    display: flex;
    padding: 15px;
    background-color: #ffffff;
    align-items: center;
    font: normal normal bold 13px/16px Montserrat;
    letter-spacing: 1.08px;
    color: #000000;
    position: fixed;
    width: 100%;
    top: 0;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 99;

    .premium-add-delivery-label {
      display: block;
      font-weight: 600;
      font-size: 16px;
      align-items: center;

      img {
        max-height: 24px;
        filter: invert(100);
        margin-right: 15px;
      }
    }
  }
  .premium-address-container {
    font-weight: 400;
    border-top: none;
    border-radius: 8px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    @media screen and (max-width: 767px) {
      border: none;
    }

    .premium-heading {
      font-size: 28px;
      font-weight: 500;
      padding: 20px 0;
    }

    &__wrap {
      padding: 20px 16px 16px 16px;
      background-color: #fff;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      /* Hide scrollbar for IE, Edge and Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      @media screen and (max-width: 767px) {
        padding-top: 32px;
        width: 100%;
      }

      label {
        margin-top: 20px;

        button {
          padding: 0;
          text-align: left;
          font-size: 13px;
        }
      }

      .premium-form-flex-row {
        display: flex;
        gap: 16px;
      }

      .add-more-btn {
        color: #ea5151;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }

    &__typewrap {
      padding: 20px;
      background-color: #ffffff;
      border-radius: 8px;
      display: flex;
      .btn-group {
        display: flex;

        button {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-right: 10px;
          background-color: #ffffff;
          padding: 5px 10px;
          border: 1px dashed #ccd2de;
          border-radius: 4px;

          img {
            max-width: 100%;
            max-height: 17px;
            margin-right: 8px;
          }

          &.active {
            color: #ea5151;
            border: 1px solid #ea5151;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 767px) {
  .premium-add-delivery-page {
    padding: 0;
    .premium-add-delivery-header {
      padding: 0;
    }
  }

  .premium-address-container {
    padding-top: 0px;
  }

  .premium-wrapper-around {
    width: 100%;
    max-width: 866px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 766px) {
  .premium-add-delivery-page footer {
    padding: 20px;
  }
}
