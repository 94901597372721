.flexRowAlign {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexRow {
  display: flex;
}

.flex1 {
  flex: 1;
}

.txtCenter {
  text-align: center;
}

.theme-btn {
  background: #ea5151;
  color: #fff;
  font-size: 14px;
  padding: 10px 15px;
  display: inline-block;
  border-radius: 4px;
  margin-top: 10px;
  border-radius: 30px;
}

.theme-bg {
  background: #ea5151;
}

.ml10 {
  margin-left: 10px;
}

.loading-container {
  margin: 20px 0;
  border-radius: 0;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.2);

  .loading-block {
    width: 100%;
    height: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .header-block {
    height: 15px;
  }

  .block-short {
    max-width: 50%;
  }

  .load-animate {
    -webkit-animation: load 1s linear infinite;
    animation: load 1s linear infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    background: #dddddd;
    background-image: linear-gradient(
      to left,
      #dddddd 0%,
      #f5f5f5 20%,
      #efefef 40%,
      #dddddd 100%
    );
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@keyframes load {
  0% {
    background-position: -680px 0;
  }

  100% {
    background-position: 680px 0;
  }
}

@-webkit-keyframes load {
  0% {
    background-position: -680px 0;
  }

  100% {
    background-position: 680px 0;
  }
}

.loading-container {
  display: flex;
  background-color: transparent;
  box-shadow: none;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 25px;
    left: 19px;
    width: 0px;
    height: 100%;
    border: 1px dashed #a5a5a5;
    z-index: 1;
  }

  &.noborder:before {
    height: 0;
  }

  .left-wrap {
    width: 20%;

    .loading-block {
      height: 40px;
      width: 40px;
      border-radius: 50px;
      position: relative;
      z-index: 2;
    }
  }

  .right-wrap {
    flex: 1;

    .loading-block {
      height: 40px;
      border-radius: 3px;
    }

    .loading-block.small {
      height: 20px;
    }
  }
}

.aths.add-btn-wrap {
  margin: 0 -20px;
  margin-bottom: -11px;

  button.add-button {
    width: 100%;
    display: block;
    font-size: 12px;
    border: 0;
    margin-top: 10px;
    padding: 10px;
    text-align: left;
    background: #000;
    color: #fff;
    border: 0;
    box-shadow: none;
    line-height: 20px;
    padding: 20px;
    font-weight: 600;

    .logo-green {
      max-width: 20px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .close-icon {
    position: absolute;
    max-width: 20px;
    bottom: 21px;
    right: 15px;
    filter: brightness(100);
  }
}

.backdrop-container {
  position: fixed;
  z-index: 505;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-animation: fadeIn 1.2s ease-in-out;
  animation: fadeIn 1.2s ease-in-out;
}

.Marquee {
  // background: linear-gradient(-135deg, #008ed9, #8b00db);
  width: 100vw;
  box-sizing: border-box;
  font-weight: 200;
  display: flex;
  align-items: center;
  overflow: hidden;

  &-content {
    display: flex;
    animation: marquee 5s linear infinite running;

    &:hover {
      animation-play-state: paused;
    }
  }

  &-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.5);
      transform: scale(1.1);
      cursor: pointer;
    }
  }
}

.global-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 199999;
  background: rgba(0, 0, 0, 0.349);
}

.fullpage-global-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 199999;

  div {
    height: 75px;
    width: 75px;
    background: #ffffff;
    border-radius: 50%;
    padding: 16px;
    opacity: 0.7;

    img {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 766px) {
      & {
        height: 50px;
        width: 50px;
        padding: 10px;
      }
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translate(-50%);
  }
}

@media screen and (min-width: 740px) {
  .rodal {
    max-width: 420px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

.selected-text {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.pointer-none {
  cursor: none;
  pointer-events: none;
}

.op5 {
  opacity: 0.5;
}

.ql-font-monospace {
  font-family: monospace;
}

.ql-font-serif {
  font-family: serif;
}

.filter-invert-1 {
  filter: invert(1);
}

.filter-invert-0 {
  filter: invert(0);
}

.skeleton {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  color: transparent !important;
  user-select: none;
  pointer-events: none;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.show-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
