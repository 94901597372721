.PremiumAboutUs {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 59px 0 0;
    min-height: 100vh;
    margin: 0 auto;
    width: 100%;

    &--inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;

      .section-top {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
        width: 100%;
        padding: 20px;
        max-width: 900px;
        margin-bottom: 40px;

        @media screen and (min-width: 767px) {
          flex: 3;
        }

        @media screen and (max-width: 766px) {
          min-height: 30vh;
        }
      }

      .section-bottom {
        height: fit-content;
        display: flex;
        width: 100%;
      }
    }
  }

  &--heading {
    text-align: center;
    text-transform: capitalize;
    margin: 0;
    font-weight: 600;

    @media screen and (min-width: 767px) {
      font-size: 40px;
      letter-spacing: 0.96px;
      margin-bottom: 1rem;
    }

    @media screen and (max-width: 766px) {
      font-size: 27px;
      letter-spacing: -0.54px;
      margin-bottom: 10px;
    }
  }

  &--paragraph {
    opacity: 0.65;
    margin: 0;
    text-align: left;
    white-space: pre-line;

    @media screen and (min-width: 767px) {
      font-size: 20px;
    }

    @media screen and (max-width: 766px) {
      font-size: 14px;
    }
  }
}
