html,
body,
div,
span,
applet,
object,
iframe,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  scroll-padding: 105px;
  scroll-behavior: smooth;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}

.lesspadding {
  padding-right: 15px !important;
  padding-left: 0px !important;
}

/* make sure to set some focus styles for accessibility */

:focus {
  outline: 0;
}

* {
  box-sizing: border-box;
}

#_next {
  height: 100%;
}

// @media screen and (min-width: 991px){
//   #root {
//     display: block;
//     margin: auto;
//     max-width: 420px;
//     border: 2px solid #e2e2e2;
//   }
// }
