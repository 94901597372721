@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;700&family=Fredoka:wght@400;500&family=Manrope:wght@300;400;500;600;700;800&family=Mina:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@700&family=Alata&family=M+PLUS+Rounded+1c:wght@400;500&family=Open+Sans:wght@400;700&family=Oswald:wght@700&family=Outfit:wght@300;500&family=Philosopher:wght@700&family=Poly&family=Poppins:wght@100&family=Rosario:wght@400;500&family=Rubik:wght@400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=DM+Sans:opsz,wght@9..40,400;9..40,600&family=Montserrat+Alternates:wght@400;600&family=Montserrat:wght@400;600&family=MuseoModerno:wght@300&family=Noto+Sans:wght@300;600&family=Sofia&family=Work+Sans:wght@400;600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Croissant+One&family=M+PLUS+Rounded+1c&family=Meie+Script&family=Monsieur+La+Doulaise&family=Sofia&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oleo+Script&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz@0,9..144;1,9..144&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Truculenta:opsz@12..72&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Amaranth&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap');

@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wdth,YTLC@0,6..12,75..125,440..540;1,6..12,75..125,440..540&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Sevillana&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');
