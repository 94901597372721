.megaMenuScrollStyle {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #000000 transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
}
