.product-customization-modal-initial {
  @media all and (min-width: 768px) {
    .rodal-dialog {
      max-width: 600px !important;
      max-height: 92% !important;
      background: #fff;
    }
  }
  @media all and (max-width: 767px) {
    .rodal-dialog {
      max-width: 100% !important;
      padding: 1.5rem 1rem !important;
      background: #fff;
    }
  }
}

.product-customization-modal-card {
  @media all and (min-width: 768px) {
    .rodal-dialog {
      max-width: 586px !important;
      max-height: 92% !important;
      background: #f7f7f7;
    }
  }
  @media all and (max-width: 767px) {
    .rodal-dialog {
      max-width: 100% !important;
      background: #f7f7f7;
    }
  }
}

.personalization__input--textbox::placeholder {
  color: #999999;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
