.desktopPaymentModal {
  .content-grid {
    display: grid;
    grid-template-columns: 30% auto;
    gap: 20px;
    max-height: 800px;

    .option-card {
      width: 100%;
      padding: 16px 10px;
      margin: 0 0 20px 0;
      border: none;
      box-shadow: 0px 3px 6px #0000001c;
      border-radius: 8px;
      border: 0.4px solid #efefef;
      cursor: pointer;

      .input-wrap {
        padding: 0 10px 0 12px;

        // & > input[aria-checked='true'] {
        //   background-color: #b8d8a7;
        // }
      }

      .content-wrap {
        display: flex;
        flex: auto;
        flex-direction: column;
        justify-content: space-between;

        .icons-wrap {
          display: flex;
          flex: auto;
          padding-top: 10px;

          & > span {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            padding: 8px 5px;
            background-color: #ffffff;
            border: 1px solid #f1f1f1;
            border-radius: 5px;
            margin-right: 8px;

            & > img {
              max-width: 100%;
              height: 20px;
            }
          }
        }
      }

      .transaction-charge-text {
        margin-top: 10px;
        font-size: 14px;
        color: grey;
        padding-left: 2.5rem;
      }
    }

    .selected {
      border: 0.4px solid #b8d8a7;
      background-color: #baff951c;
    }

    .card-payment-exceptions {
      font-size: 12px;
      color: rgb(39, 45, 59, 0.57);
    }
  }
  .partial-payment-section {
    padding: 0px 12px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 24px;
    .title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 24px;
    }
    .radio-input-wrapper {
      display: flex;
      gap: 40px;

      @media screen and (max-width: 766px) {
        flex-direction: column;
        gap: 20px;
      }

      .radio-input-item {
        display: flex;
        align-items: center;
        gap: 10px;

        .radio-content-wrap {
          display: flex;
          gap: 10px;
          img {
            cursor: pointer;
          }
          .__react_component_tooltip {
            background: #4f4f4f;
            border-radius: 5px;
            padding: 12px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            opacity: 1;
          }
          article {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #2c2c2c;
          }
        }
      }
    }
  }
}

.desktopPaymentPage {
  .rodal-mask {
    background: #fff;
  }
}

.extra-cod-charges {
  color: #6B6B6B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  align-self: stretch;
}

.extra-cod-charge-container {
  flex-shrink: 0;
  background-color: #FFF;

  .header {
    height: 47.101px;
    flex-shrink: 0;
    background-color: #F0F0F0;
    display: flex;
    justify-content: center;
    align-items: center;
    .header-text {
      height: 21.195px;
      flex-shrink: 0;
      color: #272D3B;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: center;
    }

  }

  .cost-breakup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    margin: 36px;

    .sub-header {
      color: #000;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 109.091% */
    }

    .cost-breakup-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      width: 100%;

      .cost-breakup-sub-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 100%;

        .cost-breakup-sub-info-detail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          .cost-breakup-sub-info-detail-text {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 120% */
          }

          .cost-breakup-sub-info-detail-value {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 120% */
          }
        }

      }

      .grand-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .grand-total-text {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 120% */
        }

        .grand-total-value {
          color: #000;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 120% */
        }
      }

    }
  }

  .confirm-cod-btn {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 200px;
    background: var(--Kinetic-Colors-Gray-gray-900, #0E0E0E);
    bottom:0%;
    margin: 36px;
    .confirm-cod-btn-text {
      color: var(--Kinetic-Colors-Natural-white, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 111.111% */
    }
  }
}
