@media screen and (min-width: 768px) {
  .cfe-dropdown-nav {
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px -3px 6px #00000012;
    background-color: #ffffff;
    max-width: 200px;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   border: 10px solid transparent;
    //   border-bottom-color: #ffffff;
    //   top: -20px;
    //   left: 10px;
    // }

    &__items {
      // height: 222px;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-color: #000000 transparent;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000000;
      }
    }

    &__item {
      display: block;
      width: 100%;
      cursor: pointer;
      padding: 4px 14px;
      margin: 0;
      font-size: 12px;
      color: #000000;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        background-color: #fafafa;
      }
      &:not(:last-child) {
        margin: 0 0 10px;
      }
    }
  }
}
