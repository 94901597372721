.header12 {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 5px 0px #00000040;
  background-color: #ffffff;
  z-index: 90;

  &__top-component {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    padding-top: 20px;
    padding-left: clamp(15px, 5%, 80px);
    padding-right: clamp(15px, 5%, 80px);
    align-items: center;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 25% 50% 25%;
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 20px;
    }
  }

  & .search-comp {
    margin: 0 15px;
    background-color: #f7f7f7;
    border: 0.4px solid #b4b4b4;
    border-radius: 10px;
    padding: 10px 20px;

    & > img {
      width: 14px;
    }

    & > span {
      font-size: 12px;
      font-weight: 500;
    }

    @media screen and (max-width: 767px) {
      & {
        margin: 0 clamp(15px, 5%, 80px) 17px;
        padding: 10px 20px;

        & > img {
          width: 12px;
        }

        & > span {
          font-size: 12px;
        }
      }
    }
  }

  & .icon-set {
    & > span {
      cursor: pointer;
      position: relative;

      &:first-child {
        margin-right: 24px;
      }

      &:last-child {
        margin-left: 24px;
      }

      @media screen and (max-width: 767px) {
        &:first-child {
          display: none;
        }

        &:last-child {
          margin-left: 0px;
        }
      }

      & .icon-set-image {
        width: 20px;

        @media screen and (max-width: 767px) {
          width: 17px;
        }
      }

      & > .cart-items {
        background: #000000;
        color: #ffffff;
        font-size: 12px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -10px;
        right: -10px;
      }
    }
  }

  &__bottom-component {
    padding-top: 25px;

    &--menu {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;

      & > .menu-item {
        text-transform: uppercase;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;
        padding: 0 12px 16px;
        margin: 0 7px;
        border-bottom: 4px solid transparent;

        & > img {
          margin-left: 8px;
          width: 8px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .logo_image {
    max-width: 70px;
    max-height: 50px;
    margin: -25px auto;
  }
  .logo_image_desktop {
    max-width: 100px;
    max-height: 60px;
    margin-bottom: -45px;
    margin-left: 40px;
  }
}
