.ds-order-item {
  box-shadow: 0 16px 36px rgb(0 0 0 / 16%);
  border-radius: 10px;
  padding: 20px;
  border: 0;
  margin: 20px 0;
  cursor: pointer;

  &__head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top-wrap {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;

      .oid {
        font-weight: 700;
        font-size: 15px;
        color: #313131;
      }
      span {
        font-weight: 600;
        color: #EA5151;
        font-size: 14px;

        &.warning {
          color: #d61d1d;
        }
      }
    }
    .time-wrap {
      font-size: 12px;
      color: #999;
      margin: 5px 0;
    }
  }

  &__body {
      margin-top: 10px;
      line-height: 1.2em;
      span {
        color: #777777;
        font-size: 12px;
        display: inline-block;
        word-break: break-all;
      }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

  }

  .reorder-btn-wrap {
    border: 0;
    box-shadow: none;
    font-size: 14px;
    background-color: transparent;
    margin-top: 10px;
    font-weight: 600;
    color: #EA5151;
    border: 1px dotted #EA5151;
    padding: 7px 12px;
    border-radius: 30px;
  }
}