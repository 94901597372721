.premium-delivery-details-page {
  min-height: 100vh;
  & > .premium-header {
    display: flex;
    background-color: #ffffff;
    align-items: center;
    color: #000000;
    position: fixed;
    width: 100%;
    top: 0;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 99;

    & > .premium-label {
      display: block;
      font-weight: 600;
      font-size: 16px;
      align-items: center;

      & > img {
        max-height: 24px;
        filter: invert(100);
        margin-right: 15px;
      }
    }
  }

  main {
    padding: 0 0 60px;
    @media screen and (max-width: 766px) {
      & {
        padding: 60px 0 93px;
        min-height: 100vh;
      }
    }

    .premium-selected-address__container {
      max-width: 800px;
      width: 100%;
      margin: 10px auto;
      padding: 0 20px;

      .premium-heading {
        font-size: 28px;
        font-weight: 500;
        padding: 20px 0;
      }

      .premium-selected-address-wrap {
        display: flex;
        padding: 20px 15px;
        border-radius: 7px;
        font-size: 14px;
        color: #777;
        position: relative;
        background-color: #ffffff;
        line-height: 1.4em;
        box-shadow: 0px 3px 6px #00000029;

        .premium-selected-address {
          width: 90%;
          font-size: 14px;
          text-align: left;
          padding: 0 6px;

          .name {
            font-weight: 600;
            color: #000;
          }

          .address {
            padding: 4px 0;
            margin-bottom: 10px;
          }

          .phone {
            padding: 4px 0;
          }

          .type {
            border: 1px solid #0dbf44;
            border-radius: 10px;
            color: #0dbf44;
            font-size: 12px;
            padding: 1px 10px;
            text-transform: capitalize;
          }
        }

        .checkmark1 {
          display: flex;
          flex: 0 0 10%;
          justify-content: center;
          align-items: flex-start;
          width: 30px;
          padding: 5px 0;
          margin-right: 10px;

          & > img {
            max-width: 100%;
            height: 20px;
          }
        }
      }

      .change-address {
        text-align: center;
        padding: 20px 0;

        & > span {
          font-size: 14px;
          font-weight: 600;
          color: #ea5151;
          padding: 2px 0;
          border-bottom: 1px dotted #ea5151;
        }
      }
    }
  }

  footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    left: 0px;
    background: #fff;
    background-color: #fff;
    z-index: 9;
    box-shadow: 2px 3px 5px 4px #cacaca;

    button.confirm-btn {
      background: #ea5151;
      border: 0;
      color: #fff;
      width: 100%;
      padding: 12px 10px;
      border-radius: 30px;
      font-weight: 600;
      font-size: 15px;
    }
  }
}

@media screen and (min-width: 767px) {
  .premium-delivery-details-page main .premium-selected-address__container {
    padding-top: 20px;
  }

  .premium-selected-address {
    & > .name {
      font-size: 20px;
      margin-bottom: 5px;
    }

    & > .phone,
    & > .address {
      font-size: 16px;
    }
  }

  .premium-delivery-details-page
    main
    .premium-selected-address__container
    .change-address
    > span {
    font-size: 20px;
  }
}

@media screen and (max-width: 766px) {
  .premium-delivery-details-page {
    header {
      padding: 15px;
    }
    footer {
      padding: 20px;
    }
  }
}
