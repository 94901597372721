.waterSplashLoader {
    display: inline-block;
    position: relative;
    width: 25px;
    height: 25px;
  }
  .waterSplashLoader div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: waterSplashLoader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .waterSplashLoader div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes waterSplashLoader {
    0% {
      top: 10px;
      left: 10px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 36px;
      height: 36px;
      opacity: 0;
    }
  }
  

  //ellipsis loader

  .ds-ellipsis-loader {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
  }
  .ds-ellipsis-loader div {
    position: absolute;
    // top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ea5151;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ds-ellipsis-loader div:nth-child(1) {
    left: 8px;
    animation: ds-ellipsis-loader1 0.6s infinite;
  }
  .ds-ellipsis-loader div:nth-child(2) {
    left: 8px;
    animation: ds-ellipsis-loader2 0.6s infinite;
  }
  .ds-ellipsis-loader div:nth-child(3) {
    left: 32px;
    animation: ds-ellipsis-loader2 0.6s infinite;
  }
  .ds-ellipsis-loader div:nth-child(4) {
    left: 56px;
    animation: ds-ellipsis-loader3 0.6s infinite;
  }
  @keyframes ds-ellipsis-loader1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ds-ellipsis-loader3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ds-ellipsis-loader2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }