.category {
  &-container {
    overflow-y: auto;
    max-height: 400px;
  }
  &-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: fit-content;

    & > ul {
      display: block;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.5px;
      color: #ea5151;
      text-transform: uppercase;
      font-size: 15px;
      list-style: none;
      text-align: center;
      width: 100%;

      &:first-child {
        margin: 30px 0 0 0;
      }
      &:last-child {
        margin: 0 0 30px 0;
      }
    }
    & > ul > li {
      padding: 10px 0;
      border-bottom: 0.5px dashed #9ea6b9;
      background-color: #ffffff;
      transition: background-color 30ms linear;
      &:hover {
        background-color: #ececec7a;
      }
    }
    & > ul > .product-tag-item:last-child {
      border-bottom: none;
    }

    & .product-tag-item-divider {
      width: 100%;
      margin: 4px 0;
      border-bottom: 1px solid #9da6b8;
    }
  }
}

.closeicon {
  position: absolute;
  top: 20px;
  right: 20px;
  img {
    max-width: 20px;
    filter: grayscale(100);
  }
}

.category-havesomthing {
  margin: 0 -15px;
  & > div {
    margin: 0;
  }
}
