.order-receipt {
  header {
    img {
      max-width: 100%;
      height: 24px;
    }

    & > :last-child :last-child {
      height: 20px;
      filter: invert(1);
    }
  }

  main {
    .donwloadBillBtn {
      & > img {
        max-width: 100%;
        height: 16px;
        filter: invert(1);
      }
    }
  }
  .digital-receipt-pdf {
    .react-pdf__Page__canvas {
      width: 300px !important;
      height: 20% !important;
    }
    .react-pdf__Page__textContent {
      position: fixed !important;
    }
  }
}

@media (min-width: 991px) {
  .order-receipt {
    &__imagePhone {
      max-width: 400px;
    }
  }
}
