@import './../common/color.scss';

.coupon-card1 {
  padding: 16px 21px;
  background-color: #ffe0e0;

  & > svg {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    height: 25px;
  }

  &::after {
    content: '';
    width: 10px;
    height: 20px;
    border-radius: 30px 0 0 30px;
    background-color: #ffffff;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.coupon-card2 {
  height: 125px;
  overflow: hidden;

  svg {
    top: 8px;
    left: 4px;
  }

  .left {
    width: 55%;

    .codeText {
      font-size: 11px;
    }
  }

  .right {
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    & > img {
      width: 73%;
      height: 100%;
      object-fit: cover;
      margin-left: auto;
    }
  }
}

.coupon-card4 {
  @extend .coupon-card2;
  height: 125x;

  svg {
    top: 12px;
    left: 4px;
  }
}
