.SortAndFilteredItemsMid5 {
  >section {
    margin-top: 24px;
    display: grid;
    justify-self: center;
    justify-items: center;
    grid-auto-rows: auto;

    @media screen and (min-width: 767px) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 3rem;
      row-gap: 2rem;
      justify-self: baseline;
      justify-items: baseline;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      margin-left: 0px;
      column-gap: 20px;
      row-gap: 15px;
      justify-self: center;
      justify-items: center;
    }
  }
}