.tertiaryBtnCustomStyle {
  position: relative;
  border: 0 !important;

  & p,
  .textualElement {
    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1.1px;
      background-color: var(--textual-bg, rgba(255, 255, 255, 1));
      z-index: 99;
    }
  }
}
