.footer-login-logout {
  width: fit-content;

  .footer-login-logout-button {
    border: 0.2px solid #707070;
    text-align: center;
    padding: 3px 8px 3px 8px;
    border-radius: 4px;
    margin-top: 12px;
    width: 70px;
    height: 27px;
  }

  &:hover {
    background: #f4f4f4;
  }
}

@media screen and (max-width: 767px) {
  .footer-login-logout {
    width: fit-content;

    .footer-login-logout-button {
      border: 0.2px solid #707070;
      text-align: center;
      padding: 3px 8px 3px 8px;
      border-radius: 4px;
      margin-top: -12px;
      margin-bottom: 13px;
      width: 70px;
      height: 27px;
    }

    &:hover {
      background: #f4f4f4;
    }
  }
}

.footer-button-phone-icon {
  width: 11px;
  height: 11px;
  position: relative;
  display: inline-block;
  top: 0px;
}

.footer1-login-logout {
  width: 70px;
  margin-top: 12px;
  margin-bottom: 5px;

  .footer1-login-logout-button {
    border: 0.2px solid #707070;
    text-align: center;
    padding: 3px 8px 3px 8px;
    border-radius: 4px;
    width: 70px;
    height: 27px;
  }

  &:hover {
    background: #f4f4f4;
  }
}

.footer-ads___width {
  width: 877px;
}
