@import './../common/mixin.scss';

.premium-order-list-screen {
  .mobile-header {
    position: fixed;
    width: 100%;
    z-index: 20;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    color: #000000;
    padding-bottom: 0;
    margin-bottom: 0;
    box-shadow: 0px 3px 6px #00000029;
    top: 0;

    .back-icon {
      max-width: 100%;
      max-height: 24px;
      filter: invert(100);
    }

    .header-title {
      font-weight: 600;
      font-size: 20px;
    }

    .header-top {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;

      & > :last-child {
        margin-left: 15px;
      }
    }
    .header-bottom {
      position: relative;
      display: flex;
      flex-direction: row;
    }
    .tabs-wrapper {
      margin: 10px -20px 0 -20px;
      background: #ffffff;
      padding: 0 20px;
      cursor: pointer;
    }
    .tabBtn {
      position: relative;
      font-size: 14px;
      background-color: transparent;
      color: #ea5151;
      padding: 10px 0;
      text-transform: uppercase;
      font-weight: 600;
      border-bottom: 4px solid transparent;
      opacity: 0.5;

      &.active {
        opacity: 1;

        &::after {
          position: absolute;
          bottom: -4px;
          left: 0;
          content: '';
          width: 100%;
          padding: 2px;
          background-color: #ea5151;
          border-radius: 4px 4px 0 0;
        }
      }
      &:nth-child(1) {
        margin-right: 5px;
      }
      &:nth-child(2) {
        margin-left: 5px;
      }
    }
  }

  .premium-order-list-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 0px 20px 0;
    min-height: 100vh;
    max-width: 866px;
    margin: 0 auto;

    h3.date-btn {
      text-align: center;
      margin-bottom: 0;
      margin-top: 0;
      line-height: 0;
    }

    h3.date-btn span {
      display: inline-block;
      font-size: 12px;
      font-weight: 600;
      border: 1px solid #c9c6c6;
      padding: 12px;
      border-radius: 40px;
      color: #757575;
    }

    .card {
      box-shadow: 0px 16px 36px #00000029;
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 20px;
      border: 0;
      margin-top: 20px;
      cursor: pointer;
      .top-wrap {
        display: flex;
        justify-content: space-between;
        .oid {
          font-weight: 700;
          font-size: 15px;
          color: #313131;
        }
        span {
          font-weight: 600;
          color: #ea5151;
          font-size: 14px;
          &.warning {
            color: #d61d1d;
          }
        }
      }
      .products {
        margin-top: 10px;
        span {
          color: #777777;
          font-size: 12px;
          display: inline-block;
          word-break: break-all;
        }
      }
      .time-wrap {
        font-size: 12px;
        color: #999;
        margin: 5px 0;
      }
    }
  }
  .reorder-btn-wrap {
    display: flex;
    justify-content: flex-end;
    .reorder-link {
      border: 0;
      box-shadow: none;
      font-size: 14px;
      background-color: transparent;
      margin-top: 10px;
      font-weight: 600;
      color: #ea5151;
      border: 1px dotted #ea5151;
      padding: 5px 12px;
      border-radius: 30px;
    }
  }

  .premium-order-heading {
    font-size: 28px;
    font-weight: 500;
    padding: 20px 0;
  }
}

@media screen and (max-width: 766px) {
  .premium-order-list-screen {
    display: block;
    margin: auto;
    min-height: 100vh;
    position: relative;

    header {
      padding: 20px 20px 0;
      top: 0;
    }

    .rodal {
      position: absolute;
      height: 100vh;
    }
  }
}

@media screen and (max-width: 766px) {
  .premium-order-list-screen .premium-order-list-wrapper {
    padding-top: 80px;
  }

  .premium-order-heading {
    display: none;
  }
}
