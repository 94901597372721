.Header9 {
  height: 75px;
  position: sticky;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 48px;
  z-index: 9999;
  box-shadow: 0px 3px 6px #00000029;

  &__mobile {
    display: none;
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & > &--subsection {
      display: flex;
      align-items: inherit;

      & > span {
        display: flex;
        align-items: center;

        & > img {
          width: 25px;
          height: 25px;
          cursor: pointer;
          margin-left: 32px;
        }
      }

      & .cart-item-length-comp {
        height: 20px;
        width: 20px;
        border-radius: 999px;
        background-color: #ff5757;
        top: -9px;
        right: -9px;
        color: #ffffff;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__name-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 25vw;
  }
  & .store-logo {
    height: 50px;
    width: 50px;
    margin-right: 15px;
  }
  & .store-name {
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 18px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  & .cart-item {
    border: 1px solid black;
    width: 118px;
    height: 42px;
  }
}

@media screen and (max-width: 767px) {
  .Header9 {
    height: 56px;
    padding: 0 16px;
    flex-direction: column;

    &__mobile {
      display: inline-block;
      &.navigation__container {
        position: fixed;
        z-index: inherit;
        top: 56px;
        background-color: rgba(0, 0, 0, 0.62);
        width: 100%;
        height: calc(100vh - 56px);
      }
      .navigation__container--inner {
        background-color: #ffffff;
        padding: 10px 20px;
        height: fit-content;
        overflow-y: auto;
        max-height: calc(100vh - 56px);
      }
    }

    &__desktop {
      display: none;
    }
    &__inner {
      & > &--subsection {
        & > img {
          width: 20px;
          height: 20px;
          margin-left: 22px;
        }
      }
    }
  }
}
