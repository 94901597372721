.store-policies {
  margin: 80px auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0px clamp(15px, 5%, 40px);
  .policy-navbar {
    width: 421px;
    position: sticky;
    top: 124px;
    margin-right: 43px;
    opacity: 1;
    background-color: #ffffff;
    ul {
      list-style: none;
      border: 1px solid #e2e2e2;
      border-radius: 3px;
      width: 100%;
    }

    li {
      font-size: 14px;
      padding: 20px 30px 20px 20px;
      border-bottom: 1px solid #e2e2e2;
      cursor: pointer;
      white-space: nowrap;
    }

    li:last-child {
      border-bottom: 0;
    }

    .page-selected {
      border-right: 3px solid black;
      font-weight: 600;
    }
  }

  .policy-page {
    width: 100%;
    .page-heading {
      margin-bottom: 30px;
      p {
        color: #818181;
        font-size: 14px;
        margin: 0;
      }
      h3 {
        margin: 0;
        font-size: 18px;
        margin-bottom: 6px;
        font-weight: 700;
      }
    }

    .page-content {
      margin: 0;
      font-size: 14px;
      line-height: 1.8;
      h2 {
        font-size: 16px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        line-height: 1.8;
      }
    }

    .hide-page {
      display: none;
    }
  }
}

@media screen and (max-width: 766px) {
  .store-policies {
    margin: 0px auto 55px auto;
    width: 100% !important;
    flex-direction: column;
    align-items: center;
    .policy-navbar {
      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
      }
      margin-right: 0;
      margin-bottom: 20px;
      top: 55px;
      width: 100%;
      overflow-x: auto;
      box-shadow: 0px 2px 4px #00000011;
      ul {
        display: flex;
        border: 1px solid #e2e2e2;
        padding: 0 10px;
        border-radius: 0;
        width: fit-content;
      }
      li {
        border: 0;
      }

      .page-selected {
        border: 0;
        border-bottom: 3px solid black !important;

        font-weight: 600;
      }
    }

    .policy-page {
      padding: 0 20px;
      .page-heading {
        margin-bottom: 25px;
        p {
          font-size: 14px;
        }
      }
    }
  }
}
