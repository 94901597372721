.PremiumOrderInstructionModal {
  padding-top: 15px;
  &--title {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #4d4d4d;
    display: block;
  }

  &--textarea {
    width: 100%;
    height: 110px;
    border-radius: 4px;
    border: 1px solid #ccd2de;
    resize: none;
    font-size: 12px;
    padding: 6px 10px;
    margin: 24px 0;
  }

  &--text {
    color: #8d8c8c;
    font-size: 15px;
    margin: 14px 0 0 0;
  }
}

.Premium-dialog-OrderInstruction {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: auto;
  transform: translate(50%, -50%);
  background-color: #ffffff;
  border: 1px solid #707070;
  border-radius: 15px;
  z-index: 9999;
  position: absolute;
  top: 50%;
  right: 50%;
  padding: 30px 40px 40px 40px;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    & > img {
      max-width: 20px;
      filter: grayscale(100);
    }
  }
}
