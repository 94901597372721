.SortAndFilteredItemsMid6 {
  >section {
    width: 100%;
    max-width: 1000px;
    display: grid;
    grid-template-rows: auto;
    justify-items: center;
    box-sizing: border-box;
    margin-bottom: 3rem;
    margin-top: 2rem;
    margin-left: 20px;

    @media screen and (min-width: 767px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      row-gap: 2rem;
      column-gap: 1.5rem;
      width: -webkit-fill-available;
    }

    @media screen and (max-width: 766px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      row-gap: 1.5rem;
      column-gap: 1rem;
      padding: 0 1rem;
    }
  }
}