.builderDnd {
  flex-direction: row;
  height: 93vh;
}

.widgetContainer {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  // min-height: 1rem;
  // &:empty {
  //   height: 2rem;
  //   background: repeating-linear-gradient(
  //     135deg,
  //     rgba(244, 66, 66, 0.05) 1%,
  //     rgba(244, 66, 66, 0.1) 2%
  //   );
  // }
}
.builderWidgets {
  height: 100%;
  overflow: auto;

  .widgetBox {
    border: 1px solid #aaa;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: move;

    :global(.hidden) {
      display: none;
    }

    .widgetName {
      font-size: 0.8rem;
      text-align: center;
    }
    .widgetThumb {
      width: 100%;
      height: auto;
    }
  }

  .searchWidget {
    border: 1px solid #aaa;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    width: calc(100% - 1rem);
    box-sizing: border-box;
  }

  .widgetGroup {
    padding-bottom: 0.5rem;

    .widgetGroupName {
      text-transform: capitalize;
      padding: 0 0.5rem;
    }

    .widgetGroupList {
      display: flex;
      flex-wrap: wrap;
    }

    .icon {
      width: calc(50% - 1rem);
    }
  }
}
