.Footer2 {
  width: 100%;

  &__top-section {
    padding: 45px 48px;
    background-color: #f8f8f8;
  }

  &__bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 48px;

    & .payment-text {
      font-size: 12px;
    }

    & .footer__bottom-text > p {
      margin: 0;
      color: #a3a3a3;
      font-size: 10px;

      & > a,
      & > a:visited,
      & > a:active,
      & > a:visited {
        color: inherit;
      }
      & > a:not(:hover) {
        text-decoration: none;
      }
    }
  }

  &__section {
    flex: 1 1 auto;
  }

  .footer-shop-desc {
    padding-right: 25px;

    & .top-section {
      display: flex;
      align-items: center;
      margin-bottom: 45px;
      gap: 18px;

      img {
        height: 100%;
        object-fit: cover;
      }

      h2 {
        font-size: 20px;
        margin: 0;
      }
    }

    & .bottom-section {
      h4 {
        font-size: 14px;
      }

      .desc-text {
        font-size: 12px;
        margin: 0;
        max-width: 270px;
        width: 100%;
        line-height: 18px;
      }
    }
  }

  .desc-text__view-more {
    font-size: 12px;
    margin: 0;
    line-height: 18px;
  }

  .store-information {
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 14px;
      margin-top: 0;
    }

    .address-text {
      font-size: 12px;
      margin: 0;
      max-width: 270px;
      width: 100%;
      line-height: 18px;
      word-break: break-word;
    }

    &__subsection {
      display: flex;
      align-items: center;
      margin-bottom: 13px;

      & span {
        margin-right: 8px;
        width: 15px;
      }

      & a {
        all: unset;
        cursor: pointer;
        font-size: 12px;
        margin: 0;
      }
    }

    & .find-us {
      h4 {
        font-size: 14px;
      }

      & div {
        display: flex;
        align-items: center;

        & img {
          margin-right: 15px;
          cursor: pointer;
        }
      }
    }
  }

  .footer-social-media-container {
    h4 {
      margin-top: 0;
    }

    .social-media-section {
      display: flex;
      gap: 16px;
      justify-content: flex-start;
      flex-wrap: wrap;

      .social-media-icon {
        height: 24px;
        width: 24px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .Footer2 {
    &__section {
      margin-bottom: 20px;
    }
    &__top-section {
      padding: 45px 16px;

      &--inner {
        flex-direction: column;
        padding: 0 !important;
      }

      & .store-information {
        margin-bottom: 20px;
      }
    }

    .footer-shop-desc {
      margin-bottom: 20px;
    }

    &__bottom-section {
      flex-direction: column;
      align-items: flex-start;
      padding: 45px 16px;

      & > div {
        align-items: flex-start;
      }

      & > div:first-child {
        flex-direction: column;
        margin-bottom: 20px;
      }
    }

    .footer-social-media-container {
      .social-media-section {
        display: flex;
        gap: 16px;
        justify-content: flex-start;

        .social-media-icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}
