@import './../common/mixin.scss';

.otp-verify-screen {
  padding: 20px;
  background-color: #fff;
  .heading {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
    & + span {
      font-size: 25px;
      font-weight: 600;
    }
  }
  input:focus {
    border-color: #000 !important;
  }
  input[value=''] {
    color: #999;
  }
  .btn-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    button {
      background-color: #000;
      color: #fff;
      width: 100%;
      border: 0;
      padding: 15px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: bold;
      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

@include respond-to('medium') {
  .otp-verify-screen {
    display: block;
    margin: auto;
    max-width: 420px;
    border: 1px solid #e2e2ee;
    position: relative;
    height: 100vh;
  }
  .btn-wrap {
    position: absolute;
  }
}
