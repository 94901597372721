.storeLogo-wrapper {
  display: flex;
  align-items: center;
  width: 100px;
  height: 100px;
  padding: 1px;
  box-shadow: 0px 3px 6px #00000029;

  & > img {
    max-width: 100%;
    height: auto;
    border: 4px solid #FFFFFF;
  }
}