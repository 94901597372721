$bottomHeight: 70px;

.QuickViewModalContainer {
  position: relative;

  @media screen and (min-width: 767px) {
    width: 100%;
    max-width: 1200px;
    max-height: 700px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 20px;
  }

  &__close {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    display: inline-block;

    @media screen and (min-width: 767px) {
      right: 15px;
      top: 5px;
    }

    @media screen and (max-width: 766px) {
      left: 50%;
      transform: translateX(-50%);
      top: -4rem;
    }
  }
}

.QuickViewModal {
  padding: 0;
  // position: relative;
  background-color: #ffffff;
  width: 100%;
  overflow-y: scroll;

  @media screen and (min-width: 767px) {
    padding: 40px 25px;
    max-height: 700px;
  }

  @media screen and (max-width: 766px) {
    padding-bottom: $bottomHeight;
  }

  &__main {
    @media screen and (min-width: 767px) {
      display: flex;
      max-height: 660px;
    }

    @media screen and (max-width: 766px) {
      display: block;
      position: relative;
      width: 100%;
      max-height: 550px;
      height: fit-content;
      overflow-y: auto;
      max-height: 70vh;
    }

    &--image-component {
      @media screen and (min-width: 767px) {
        flex: 1;
        margin-right: 10px;
        overflow: scroll;
      }

      @media screen and (max-width: 766px) {
        height: auto;
        margin-bottom: 20px;
        margin-top: 10px;
        // padding: 0 20px;

        & .slick-track {
          display: flex;
          align-items: center;
        }
      }
    }

    &--inner {
      // position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media screen and (min-width: 767px) {
        flex: 1;
        height: auto;
        overflow-y: auto;
        margin-left: 10px;
        margin-bottom: 30px;
      }

      @media screen and (max-width: 766px) {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        height: auto;
      }
    }

    &--info-box {
      @media screen and (max-width: 766px) {
        display: block;
        padding: 15px 20px 0;
      }

      & > h4 {
        margin: 0;

        @media screen and (min-width: 767px) {
          font-size: 28px;
          font-weight: 500;
          color: #000000;
        }

        @media screen and (max-width: 766px) {
          font-size: 14px;
          font-weight: 600;
          color: #363f4f;
        }
      }
    }

    &--gst-inclusive {
      font-size: 14px;
      color: #797a7c;
      margin-top: 10px;
      margin-bottom: 23px;
      @media screen and (max-width: 767px) {
        font-size: 10px;
        color: inherit;
        margin-top: 0px;
        margin-left: 20px;
      }
    }
    &--gst-exclusive {
      font-size: 12px;
      color: #797a7c;
      margin-top: 10px;
      margin-bottom: 23px;
      margin-bottom: 0px;
      @media screen and (max-width: 767px) {
        font-size: 10px;
        color: inherit;
        margin-top: 0px;
        margin-left: 20px;
        margin-bottom: 0px;
      }
    }
    &--price-box {
      display: flex;

      @media screen and (min-width: 767px) {
        margin: 30px 0;
      }

      @media screen and (max-width: 766px) {
        padding: 10px 20px;
        padding-bottom: 6px;
        align-items: center;
      }

      & p {
        margin: 0;
      }

      & > .price-amount--box {
        display: flex;
        margin-right: 10px;

        @media screen and (min-width: 767px) {
          flex-direction: row;
          height: fit-content;
        }

        @media screen and (max-width: 766px) {
          flex-direction: column;
        }

        .main-price {
          @media screen and (min-width: 767px) {
            font-size: 30px;
            font-weight: 500;
            margin-right: 10px;
          }

          @media screen and (max-width: 766px) {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 5px;
          }
        }

        .slashed-price {
          text-decoration: line-through;
          color: #abadae;

          @media screen and (min-width: 767px) {
            font-size: 20px;
            font-weight: 400;
          }

          @media screen and (max-width: 766px) {
            font-size: 10px;
            font-weight: 400;
          }
        }

        .discounted-percent {
          color: #ffffff;
          margin: 0;
          background-color: #369d00;
          padding: 3px 12px;

          @media screen and (min-width: 767px) {
            font-size: 13px;
          }
          @media screen and (max-width: 766px) {
            font-size: 10px;
          }
        }
      }
    }

    &--variant-box {
      @media screen and (min-width: 767px) {
        max-width: 300px;
      }

      @media screen and (max-width: 766px) {
        padding: 10px 20px;
      }

      & > h4 {
        font-size: 12px;
        color: #000000;
        font-weight: 600;
      }

      & > .variant-list {
        list-style-type: none;

        .variant-list--item {
          padding: 10px 5px 10px 30px;
          border: 1px solid #dedede;
          border-radius: 8px;
          margin-bottom: 15px;

          & > p {
            margin: 0;
            font-size: 13px;
            color: black;
          }
        }
      }
    }

    &--description-box {
      margin: 5px 0;
      word-break: break-word;
      white-space: pre-line;
      position: relative;

      @media screen and (min-width: 767px) {
        padding-bottom: 20px;
      }

      @media screen and (max-width: 766px) {
        padding: 10px 20px;
        margin-top: 20px;
      }

      & > p {
        margin: 0 5px 0 0;
        color: #2a2a2a;
        font-weight: 500;
        opacity: 0.6;

        & a,
        & a:visited,
        & a:link {
          color: rgb(0, 97, 224);
          font-weight: 600;
        }

        @media screen and (min-width: 767px) {
          font-size: 14px;
        }

        @media screen and (max-width: 766px) {
          font-size: 13px;
        }
      }
    }
  }

  &__bottom {
    @media screen and (min-width: 767px) {
      display: none;
    }

    @media screen and (max-width: 766px) {
      position: absolute;
      bottom: 0;
      height: $bottomHeight;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 13px 20px;
      box-shadow: 0px -3px 6px #00000029;
      z-index: 20;
      background-color: #ffffff;
    }

    &--btn-container {
      height: auto;
      display: inline-block;
      position: relative;

      .go-to-cart__btn {
        position: relative;
        background-color: #369d00;
        border: 1px solid #369d00;
        color: #fff;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;

        @media screen and (min-width: 767px) {
          width: 180px;
        }
      }

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }

      @media screen and (max-width: 766px) {
        flex: 1;
      }
    }
  }

  .QuickViewModal__bottom.bottom--desktop {
    @media screen and (min-width: 767px) {
      position: absolute;
      height: $bottomHeight;
      width: fit-content;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 13px 0px;
    }

    @media screen and (max-width: 766px) {
      display: none;
    }
  }

  &--store-closed {
    display: flex;
    flex: 1;

    &-icon {
      width: 12px;
      height: 12px;
      margin-right: 10px;
    }

    &-para {
      & > p {
        font-size: 12px;
        color: #404040;
        font-weight: 400;
        line-height: 1.5;
        margin: 0;

        & > a {
          color: #000000;
          text-decoration: underline;
          font-weight: 500;
          cursor: pointer;

          &:visited,
          &:active,
          &:hover {
            color: #000000;
          }
        }

        & > span {
          color: #000000;
          text-decoration: underline;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
}

.MultiImageViewer {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  &__main {
    width: 100%;
    display: flex;
    height: 350px;
    margin-bottom: 20px;

    & > img {
      object-fit: scale-down;
      width: 100%;
    }
  }

  &__row {
    display: flex;
    flex: 1;
    margin-top: 10px;

    &--image {
      min-width: 100px;
      min-height: 100px;
      margin: 5px;
      cursor: pointer;
      background-size: cover;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.zIndex99 {
  z-index: 99 !important;
}

/** Slider CSS */
.QuickViewModalContainer {
  & .slick-slider {
    max-width: 550px;
    // height: 80%;
  }
  & .slick-initialized .slick-slide > div {
    // margin: 0 0px 0 15px;
    height: 100%;
  }
}

.quick-view-mobile-html-description {
  padding: 40px 20px 0px 20px;
  @media screen and (min-width: 767px) {
    display: none;
  }
}

.quick-view-desktop-html-description {
  margin: 0 45px;
  display: block;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
