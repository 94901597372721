.OrderInstructionModal {
  padding-top: 15px;
  &--title {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #4D4D4D;
    display: block;
  }

  &--textarea {
    width: 100%;
    height: 110px;
    border-radius: 4px;
    border: 1px solid #CCD2DE;
    resize: none;
    font-size: 12px;
    padding: 6px 10px;
    margin: 24px 0;
  }

  &--text {
    color: #8D8C8C;
    font-size: 15px;
    margin: 14px 0 0 0;
  }

  &>button {
    padding: 15px 0;
  }
}

.dialog-OrderInstruction {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: auto;
    transform: translate(50%, -50%);
    background-color: #ffffff;
    border: 1px solid #707070;
    border-radius: 15px;
    z-index: 9999;
    position: absolute;
    top: 50%;
    right: 50%;
    padding: 30px 40px 40px 40px;
    
    &__close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
  
      & > img {
        max-width: 20px;
        filter: grayscale(100);
      }
    }
}