.premium-delivery-list-page {
  display: flex;
  min-width: 447px;
  max-height: 601px;
  .premium-delivery-header {
    display: flex;
    padding: 15px;
    background-color: #ffffff;
    align-items: center;
    font: normal normal bold 13px/16px Montserrat;
    letter-spacing: 1.08px;
    color: #000000;
    position: fixed;
    width: 100%;
    top: 0;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 99;

    .premium-delivery-label {
      display: block;
      font-weight: 600;
      font-size: 16px;
      align-items: center;

      img {
        max-height: 24px;
        filter: invert(100);
        margin-right: 15px;
      }
    }
  }

  .main-body {
    max-width: 800px;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .premium-heading {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 0;
      position: sticky;
      top: 0px;
      background: white;
      z-index: 1;
    }

    .premium-custom-radio-group {
      border-bottom: 1px dotted #dfdfdf;
      padding: 20px 0;
      line-height: 1.3em;

      label {
        font-size: 15px;
        text-transform: capitalize;
        font-weight: 500;
        width: 100%;
      }

      .description {
        font-size: 14px;
        color: #999;
        font-weight: 300;
        padding-left: 35px;

        p {
          margin-bottom: 5px;

          b {
            color: #000;
          }
        }
      }

      .title {
        font-size: 14px;
        font-weight: 600;
        color: #000;
        display: inline-block;
        position: relative;
      }

      .address-details {
        font-size: 12px;
        color: #646974;

        .address {
          padding: 0px 0 8px 0;
        }

        .address-type {
          font-size: 12px;
          padding: 2px 10px;
          border: 1px solid #000;
          border-radius: 12px;
        }
      }
    }
  }

  footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px;
    left: 0px;
    background: #fff;
    background-color: #fff;
    z-index: 9;
    border-radius: 0px 0px 8px 8px;

    button.confirm-btn {
      width: 100%;
      padding: 12px 10px;
      border-radius: 30px;
      font-weight: 600;
      font-size: 15px;
    }
  }
}

@media screen and (min-width: 767px) {
  .premium-delivery-list-page .premium-delivery-header {
    padding: 0;
  }

  .premium-delivery-list-page .main-body {
    padding-top: 0px;
    padding-bottom: 75px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .premium-delivery-list-page .main-body .premium-custom-radio-group .title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .premium-delivery-list-page
    .main-body
    .premium-custom-radio-group
    .address-details
    > .phone,
  .premium-delivery-list-page
    .main-body
    .premium-custom-radio-group
    .address-details
    > .address {
    font-size: 16px;
  }

  .premium-delivery-list-page
    .main-body
    .premium-custom-radio-group
    .address-details
    > .phone {
    margin-bottom: 5px;
  }

  .premium-delivery-list-page
    .main-body
    .premium-custom-radio-group
    .address-details
    .address-type {
    font-size: 14px;
  }
}

@media screen and (max-width: 766px) {
  .premium-delivery-list-page {
    min-width: 328px;
    .main-body {
      padding-bottom: 100px;
      height: 70vh;
      padding-bottom: 50px;
      .premium-heading {
        font-size: 18px;
        font-weight: 600;
      }
    }
    footer {
      padding: 20px;
    }
  }
}
