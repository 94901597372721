.f2px {
  font-size: 2px;
}

.f4px {
  font-size: 4px;
}

.f6px {
  font-size: 6px;
}

.f8px {
  font-size: 8px;
}

.f10px {
  font-size: 10px;
}

.f12px {
  font-size: 12px;
}

.f14px {
  font-size: 14px;
}

.f16px {
  font-size: 16px;
}

.f18px {
  font-size: 18px;
}

.f20px {
  font-size: 20px;
}

.f22px {
  font-size: 22px;
}

.f24px {
  font-size: 24px;
}

.f26px {
  font-size: 26px;
}

.f28px {
  font-size: 28px;
}

.f30px {
  font-size: 30px;
}

.f32px {
  font-size: 32px;
}

.f34px {
  font-size: 34px;
}

.f36px {
  font-size: 36px;
}

.f38px {
  font-size: 38px;
}

.f40px {
  font-size: 40px;
}

.f42px {
  font-size: 42px;
}
