.category-widget-9 {
  padding: 0 20px;
  .categoryWidget__container {
    margin: 0 auto;
    width: 100%;
    max-width: 1400px;
  }

  .categoryWidget__box {
    display: grid;
    row-gap: 15px;
    column-gap: 15px;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(2, 1fr);

    .card {
      cursor: pointer;
      height: auto;
      overflow: hidden;
      position: relative;
      // border-radius: 10px;

      & > img {
        box-shadow: 0px 3px 6px #00000029;
        transition: 0.8s all;
        // border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 766px) {
  .category-widget-9 {
    .categoryWidget__box {
      row-gap: 5px;
      column-gap: 5px;
    }
  }
}
