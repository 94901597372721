.cfeNavItem-padding {
  @media screen and (max-width: 767px) {
    padding-left: 34px;
  }
}

.cfeNavItem-margin {
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.cfeNavItems {
  display: flex;
  align-items: center;
  margin-left: 30px;

  &__item {
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    cursor: pointer;
    margin-left: 18px;
    position: relative;

    & > div {
      display: flex;
      align-items: center;

      & > img {
        margin-left: 10px;
        height: 10px;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &.vertical-nav {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 30px 0 0;

    .cfeNavItems__item {
      margin: 0 0 14px 0;
    }

    & .cfe-dropdown-nav {
      top: -20px;
      left: 70px;
      z-index: 10;

      // &:before {
      //   content: '';
      //   border: none;
      // }
    }
  }
}

@media screen and (min-width: 768px) {
  .cfe-dropdown-nav {
    display: inline-block;
    position: absolute;
    top: 30px;
    left: 0;
    box-shadow: 0px -3px 6px #00000012;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   border: 10px solid transparent;
    //   border-bottom-color: #ffffff;
    //   top: -20px;
    //   left: 10px;
    // }

    &__items {
      background-color: #ffffff;
      padding: 11px 0px;
      max-height: 290px;
      overflow-y: auto;
      width: 190px;
      scrollbar-color: #000000 transparent;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000000;
      }
    }

    &__item {
      display: block;
      width: 100%;
      cursor: pointer;
      padding: 4px 14px;
      margin: 0;
      font-size: 12px;
      color: #000000;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        background-color: #fafafa;
      }
      &:not(:last-child) {
        margin: 0 0 10px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .cfeNavItems {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;

    .cfeNavItems__item {
      margin: 29px 0 0 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      font-size: 16px;

      &:hover {
        text-decoration: none;
      }
      &:last-child {
        margin-bottom: 30px;
      }
    }

    &.footer-nav-style {
      max-height: 100%;
      .cfeNavItems__item {
        font-size: 14px;
      }
    }
  }
  .cfe-dropdown-nav {
    padding: 0px 0px 0px 14px;
    margin-top: 11px;

    &__items {
      border-left: 1px solid #e2e2e2;
      padding-left: 17px;
    }

    &__item {
      font-size: 14px;
      color: #6a6a6a;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
