.catalog-custom-btn {
    padding: 8px 25px;
    font: normal normal 600 13px/22px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    width: -webkit-fill-available;
    background: #EA5151 0% 0% no-repeat padding-box;
    border: 0;
    box-shadow: 0px 1px 3px #0000004D;
    border-radius: 4px;
    cursor: pointer;

    &-fill {
        // padding: 10px 25px;
        font: normal normal 600 13px/22px Montserrat;
        background: #EA5151 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        color: #ffffff;
        border: 0;
        border-radius: 30px;
        font-weight: 600;
        width: 100%;
        cursor: pointer;
    }
    
    &-outline {
        // padding: 10px 25px;
        // background: #EA5151 0% 0% no-repeat padding-box;
        // box-shadow: 0px 16px 36px #00000029;
        border: 1px solid #EA5151;
        background-color: #fff;
        border-radius: 30px;
        color: #EA5151;
        font-weight: 600;
        width: 100%;
        cursor: pointer;
       
        &:hover {
            box-shadow: 0 0 4px #b1b1b1;
        }
    }
}

.disable {
    opacity: 0.5;
}

