.viewCart-nav {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: #EA5151;
    box-shadow: 0px -3px 6px #00000029;
    bottom: 0;
    z-index: 99;
    padding: 10px;
    transform: translateY(100%);
    transition-timing-function: ease-out;
    transition: 0.2s;

    &__body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__left {
        font-size: 15px;
        letter-spacing: 0.6px;
        align-items: center;
        display: flex;
        text-align: center;
        color: #FFFFFF;
        
        & > span {

            margin-right: 25px;
            & > span {
                padding: 3px;
                border-radius: 50px;
                font-size: 10px;
                background-color: #000000;
                width: 20px;
                height: 20px;
                position: absolute;
                margin: -2px 0 0 -11px;
                text-align: center;
            }
        }
        
    }

    &__right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        font-size: 11px;
        font-weight: 700;
        border: 1px solid #FFFFFF;
        border-radius: 32px;
        padding: 11px 13px;

        & img {
            width: 14px;
            height: 14px;
            margin-left: 1px;
        }
    }

    .congratulations__strip {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -10px -10px 10px -10px;
        background-color: #0DBF44;
        font-size: 11px;
        padding: 6px;
        font-weight: 500;

        & > img {
            max-width: 100%;
            height: 17px;
            margin-right: 10px;
        }
    }

    .avialFreeDelivery__strip {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        padding: 6px;
        font-weight: 500;
        margin: -10px -10px 10px -10px;
        background-color: #1D1D1D;

        & > img {
            max-width: 100%;
            height: 14px;
            margin-right: 10px;
        }
        &--price {
            color: #FFD90B;
            padding: 0 4px;
        }
    }
}

.animate-Up{
    // transform: translateY(130%);
    transform: translateY(0);
    bottom: 0;
    transition-timing-function: ease-in;
    transition: 0.2s;
}

@media screen and (min-width: 991px){ 
    .viewCart-nav {
        max-width: 416px;
    }
  }