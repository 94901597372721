header {
  & .name-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
  }
  & .store-logo {
    height: 40px;
    width: 40px;
    margin-right: 15px;
  }

  .store-name {
    font-size: 18px;
    font-weight: 600;
    display: inherit;
    width: 100%;
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: 767px) {
      & {
        font-size: 16px;
        height: 16px;
        padding-right: 10px;
      }
    }
  }
}
