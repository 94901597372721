.custom-icon--btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 105px;
    height: 30px;
    border: 1px solid #EBEBEB;
    border-radius: 14px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    color: #484848;
    padding-right: 8px;
    cursor: pointer;
    
    img {
      max-width: 16px;
      margin: 7px 5px;
    }
}