.partial-payment-section {
  padding: 0px 12px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 24px;
  margin-top: -15px;
  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 24px;
  }
  .radio-input-wrapper {
    display: flex;
    gap: 40px;

    @media screen and (max-width: 766px) {
      flex-direction: column;
      gap: 20px;
    }

    .radio-input-item {
      display: flex;
      align-items: center;
      gap: 10px;

      .radio-content-wrap {
        display: flex;
        gap: 10px;
        img {
          cursor: pointer;
        }
        .__react_component_tooltip {
          background: #4f4f4f;
          border-radius: 5px;
          padding: 12px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          opacity: 1;
        }
        article {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #2c2c2c;
        }
      }
      .mobile-radio-wrap {
        .__react_component_tooltip {
          width: 65%;
        }
      }
    }
  }
}
