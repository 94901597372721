.spotlightCitySearch {
  &__header {
    padding-bottom: 50px;
    padding: 14px 20px 140px 12px;
    background: transparent linear-gradient(180deg, #fcdacb 0%, #ffffff00 100%)
      0% 0% no-repeat padding-box;

    &--title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 21px;
      font-weight: 600;
      letter-spacing: 0.3px;
      color: #202124;
      margin: 16px 24px 20px 12px;

      & > .back--icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & > img {
          max-width: 100%;
          height: 24px;
          filter: invert(1);
          margin-right: 6px;
        }
      }
    }

    &--search {
      padding: 0 4px 0 12px;

      & > .searchbar-wrapper {
        width: inherit;
        left: 0;

        & > .searchbar {
          box-shadow: 0px 1px 3px #00000029;
          border-radius: 20px;
        }
      }
    }
  }

  &__body {
    padding: 10px 24px 28px 24px;
    margin-top: -130px;

    &--nearme {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #ea5151;
      font-weight: 600;
      font-size: 18px;
      padding: 10px;
      background-color: #ffe3d9;
      margin: 0 -10px;
      border-radius: 5px;

      & > img {
        max-width: 100%;
        height: 20px;
        margin-right: 10px;
      }
    }

    &--cityCount {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #a9a9a9;
      padding: 25px 0;

      .not-found {
        width: 100%;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 740px) {
  .spotlightCitySearch {
    display: block;
    margin: auto;
    max-width: 420px;
    border: 2px solid #e2e2e2;
  }
}
