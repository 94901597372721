.card-payment-screen {
  header {
    background-color: #fff;
    padding: 20px;
    color: #000;
    display: flex;
    align-items: center;
    span {
      display: block;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;

      &:nth-child(2) {
        margin-left: 20px;
      }
      img {
        max-height: 20px;
        filter: invert(100);
      }
    }
  }
  .card-payment-exceptions {
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 400;
  }
}

.myspin-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 99;
}
