.brand-footer {
  & > :first-child {
    margin-bottom: 1px;
    flex-wrap: wrap;
  }

  & > :last-child:not(.text-links) {
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    padding: 14px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
  }

  &__linkText {
    color: #000;
    border: 1px solid rgba(112, 112, 112, 0.1);
    border-radius: 4px;
    padding: 4px 18px;
    font-size: 11px;
    margin: 4px;

    &--href {
      color: inherit;
      font-size: inherit;
      text-decoration: none;

      &:focus {
        text-decoration: none;
        color: inherit;
      }

      & > img {
        max-width: 100%;
        height: 11px;
        margin-right: 6px;
      }
    }
  }

  &__fadeText {
    font-size: 11px;
    letter-spacing: 0.31px;
    color: #ffffff;
    opacity: 0.68;
    margin: 0 0 8px 0;
  }

  &__BrandIcon {
    cursor: pointer;

    & > img {
      max-width: 100%;
      height: 65px;
    }
  }
}
