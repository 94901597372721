[data-widget] {
  & > .widgetFrameContainer > .widgetHandle {
    opacity: 0;

    .widgetHandleIcon {
      display: flex;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &:hover {
    & > .widgetFrameContainer > .widgetHandle {
      opacity: 1;
    }
  }
}

.widgetFrameContainer {
  position: absolute;
  // right: -55%;
  top: 0%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 99;
  display: inherit;

  &.alwaysVisible {
    width: 100%;

    & > .widgetHandle {
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 1;
      .widgetName {
        opacity: 1;
      }
    }
  }
  .widgetHandle {
    opacity: 0;
    display: flex;
    align-items: center;
    font-size: 0.75em;
    padding: 0.125rem 0.5rem;
    z-index: 1000000;
    color: #fff;
    position: absolute;
    // top: 50%;
    // right: 0;
    // top: 50%;
    // transform: translateY(-50%);

    user-select: none;
    background-color: var(--widget-highlight-color);
  }
  .widgetHandleIcon {
    display: none;
    font-size: 1rem;
    padding: 0.25rem;
    cursor: pointer;
    border-right: 1px solid rgba(212, 212, 212, 0.779);

    &:last-child {
      border-right: none;
    }

    &:hover {
      box-shadow: 0 0 10px 10px inset rgba(0, 0, 0, 0.125);
    }
  }

  .widgetName {
    padding: 0.25rem 0.5rem;
  }

  .dragIndicator {
    transform: rotate(90deg);
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .centerHandle {
    right: 0%;
    // top: 50%;
    // transform: translateX(-50%);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    .delete:hover {
      box-shadow: 0 0 10px 10px inset rgba(255, 0, 0);
    }
  }
}
