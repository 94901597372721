.side-drawer { 
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 40%;
  z-index: 99;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    
    img {
      max-width: 20px;
      filter: grayscale(100);
    }
  }
}

.side-drawer.open {
  transform: translateX(0);
}