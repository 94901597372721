.paymentOptions-wrapper {
  margin-top: 10px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    li {
      position: relative;
      border-radius: 10px;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.3);
      padding: 12px;
      height: 112px;
      color: #fff;
      list-style: none;

      &.googlepay {
        background-color: #4285f4;
      }

      &.mobikwik {
        background-color: #0c4bff;
        & > .logo-wrap > img {
          position: relative;
          bottom: 7px;
        }
      }

      &.phonepe {
        background-color: #6738b7;
      }

      &.card,
      &.creditCard,
      &.debitCard {
        background-color: #fc5c02;
      }

      &.card {
        & > .logo-wrap > img {
          position: relative;
          bottom: 7px;
        }
      }

      &.paytm,
      &.paytmupi {
        background-color: #06306f;
      }

      &.whatsapp {
        background-color: #4cb050;

        & > .logo-wrap > img {
          max-width: 55%;
        }
      }

      &.airtel {
        background-color: #e40000;

        & > .logo-wrap {
          padding: 5px 0;
        }
      }

      &.payzapp {
        background-color: #9ea6b9;
      }

      &.lazyPay {
        background-color: #ed3561;

        & > .logo-wrap > img {
          max-width: 55%;
        }
      }

      &.cash {
        background-color: #403f41;

        & > .logo-wrap > img {
          max-width: 55%;
        }
      }

      &.cemi {
        background-color: #000000;

        & > .logo-wrap > img {
          position: relative;
          bottom: 5px;
        }
      }

      .logo-wrap {
        position: relative;
        width: 48px;
        height: 48px;
        padding: 12px 0;
        border-radius: 8px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 80%;
        }

        & + span {
          font-size: 14px;
          font-weight: 500;
          display: block;
          font-size: 11px;
          letter-spacing: 0.5px;
          margin-top: 14px;
          white-space: nowrap;
        }
      }

      .transaction-charge-text {
        font-weight: 400;
        font-size: 9px;
        position: absolute;
        width: fit-content;
        left: 0%;
        padding-left: 12px;
      }
    }
  }
}
