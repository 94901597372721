.OrderConfirmLoader {
  position: relative;

  .payment-section {
    display: flex;
    padding: 20px;
    position: relative;

    .payment-closeicon {
      position: absolute;
      top: 0px;
      right: 10px;
      cursor: pointer;

      img {
        max-width: 20px;
        filter: grayscale(100);
      }
    }

    h2 {
      margin: 0;
      color: #1c1d1d;

      @media screen and (min-width: 767px) {
        font-size: 28px;
      }

      @media screen and (max-width: 766px) {
        font-size: 24px;
      }
    }

    &:not(:last-child) {
      border-bottom: 0.4px solid rgba(112, 112, 112, 0.2);
    }

    @media screen and (max-width: 766px) {
      flex-direction: column;
    }

    .info-header {
      color: #1c1d1d;
      margin: 0;

      &--main {
        @media screen and (min-width: 767px) {
          font-size: 14px;
        }
        @media screen and (max-width: 766px) {
          font-size: 12px;
        }
      }

      &--sub {
        margin-right: 15px;

        @media screen and (min-width: 767px) {
          font-size: 12px;
        }
        @media screen and (max-width: 766px) {
          font-size: 10px;
        }
      }
    }

    .payment-selection {
      margin-top: 10px;
      padding: 16px 15px 19px;
      border-radius: 10px;
      border: 1px solid #3e9b28;
      display: flex;
      flex: 1 1 100%;
      background-color: #ddf7d8;

      &__radio {
        margin-right: 13px;

        &--icon {
          display: inline-block;
          width: 18px;
          height: 18px;
          border-radius: 50px;
          background-color: black;
          position: relative;

          &-inner {
            position: absolute;
            display: inline-block;
            width: 13.5px;
            height: 13.5px;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            border: 3px solid #ddf7d8;
            background-color: black;
          }
        }
      }

      &__info {
        display: flex;
        flex-direction: column;

        &--header {
          color: #101010;
          margin: 0 0 6px;
          font-weight: 400;

          @media screen and (min-width: 767px) {
            font-size: 18px;
          }

          @media screen and (max-width: 766px) {
            font-size: 16px;
          }
        }

        &--amount {
          display: flex;
          font-weight: 400;

          &-text {
            color: #a8a8a8;

            @media screen and (min-width: 767px) {
              font-size: 16px;
            }

            @media screen and (max-width: 766px) {
              font-size: 14px;
            }
          }

          &-price {
            margin-left: 7px;
            font-weight: 500;
            letter-spacing: 1.17px;

            @media screen and (min-width: 767px) {
              font-size: 16px;
            }

            @media screen and (max-width: 766px) {
              font-size: 14px;
            }
          }
        }
      }
    }

    .payment-options {
      margin-top: 5px;
      display: flex;
      align-items: center;

      & > span {
        display: inline-block;
        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      &--cash {
        margin: 0;
        font-size: 12px;
        color: #1c1d1d;
      }
    }

    &--vary {
      @media screen and (min-width: 767px) {
        align-items: center;
        justify-content: center;
      }
    }

    .payment-cancellation {
      display: flex;
      flex: 1 1 100%;
      align-items: center;

      &__bar {
        flex: 1 1 auto;
        margin-right: 20px;
        border-radius: 8px;
        background-color: #ededed;
        overflow: hidden;
        position: relative;

        @media screen and (min-width: 767px) {
          height: 19px;
        }

        @media screen and (max-width: 766px) {
          height: 12px;
        }

        &--animated {
          position: absolute;
          left: 0;
          height: 100%;
          border-radius: 8px;
          animation-timing-function: linear;
          animation-name: move;
          width: 100%;
        }
      }

      &__btn {
        flex: 0 0 auto;
      }
    }
  }
}

@keyframes move {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
