.category-card-1 {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  height: auto;

  & > img {
    width: 100%;
    height: 100%;
    transition: 0.8s all;
  }

  .image {
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }

  .category-name {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -44%);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    width: 100%;
    padding: 0 20px;
    color: #ffffff;
    z-index: 4;
    text-shadow: 0px 6px 10px #00000089;
    transition: transform 0.8s ease;
  }
  .category-btn {
    border-radius: 30px;
    position: absolute;
    font-size: 12px;
    top: 78%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 10px 15px;
    z-index: 4;
    border: 0;
    box-shadow: 0px 6px 26px #00000089;
    background-color: white;
    cursor: pointer;
    opacity: 0;
    width: fit-content;
    max-width: 85%;
  }

  .horizontal-category-btn {
    border-radius: 30px;
    position: absolute;
    font-size: 12px;
    top: 78%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 15px;
    z-index: 4;
    border: 0;
    box-shadow: 0px 6px 26px #00000089;
    background-color: white;
    cursor: pointer;
    opacity: 0;
    width: fit-content;
    max-width: 85%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: #0000004d;
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
  }

  &:hover {
    & > img {
      transform: scale(1.1);
    }

    & > .image {
      // transform: scale(1.1);
      // transition: all 1s;
      -ms-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
    }

    & > .category-name {
      transform: translate(-50%, -85%);
    }
    & > button {
      opacity: 1;
      transform: translate(-50%, -200%);
      animation: fadeInUp 0.8s ease;
    }
    &:before {
      opacity: 1;
    }
  }
}

.category-card-3 {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #f4f4f4;
  min-height: 4.5rem;

  & > img {
    width: 100%;
    height: 100%;
    transition: 0.8s all;
    transform: scale(1.2);
  }
  .category-btn {
    border-radius: 0px;
    max-width: 85%;
    position: absolute;
    font-size: 12px;
    bottom: 15px;
    left: 15px;
    padding: 10px 30px;
    z-index: 4;
    border: 0;
    // box-shadow: 0px 6px 26px #00000089;
    background-color: white;
    cursor: pointer;
  }

  &:hover {
    & > img {
      transform: scale(1.1);
      transform-origin: left;
    }
    & > button {
      opacity: 1;
      background-color: #000000;
      color: #fff;
      transition: background-color 0.4s ease-in;
    }
    &:before {
      opacity: 1;
    }
  }
}

.category-card-7 {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #f4f4f4;
  border: none;

  & > img {
    width: 100%;
    height: 100%;
    // transition: 0.8s all;
    // transform: scale(1.2);
  }
  .category-btn {
    border-radius: 0px;
    max-width: 70%;
    position: absolute;
    font-size: 12px;
    bottom: 15px;
    left: 15px;
    padding: 10px 30px;
    z-index: 4;
    border: 0;
    box-shadow: 0px 6px 26px #00000089;
    background-color: white;
    cursor: pointer;
  }

  &:hover {
    & > img {
      // transform: scale(1.1);
      transform-origin: left;
    }
    & > button {
      opacity: 1;
      background-color: #000000;
      color: #fff;
      transition: background-color 0.8s ease-in;
    }
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    z-index: 99;
    position: absolute;
    top: -10px;
    left: 32px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: #e2e2e2;
  }
  &:after {
    content: '';
    z-index: 99;
    position: absolute;
    bottom: -10px;
    left: 32px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: #e2e2e2;
  }
}

.category-card-9 {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  height: auto;

  & > img {
    width: 100%;
    height: 100%;
    transition: 0.8s all;
  }

  // .name-btn-wrapper {
  //   position: absolute;
  //   top: 45%;
  //   left: 50%;
  //   transform: translate(-50%, -44%);
  //   transition: transform 0.8s ease;
  //   z-index: 4;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  // }

  .category-name {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -44%);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    width: 100%;
    padding: 0 20px;
    color: #ffffff;
    z-index: 10;
    margin: 15px 0;
    text-shadow: 0px 6px 10px #00000089;
    transition: transform 0.8s ease;
  }

  .image {
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    transition: all 0.7s;
  }

  .image:hover {
    // transition: all 1s;
    // background-size: 110%;
  }

  .category-btn {
    position: absolute;
    font-weight: 500;
    font-size: 12px;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -0%);
    padding: 10px 25px;
    z-index: 4;
    border: 0;
    box-shadow: 0px 6px 26px #00000055;
    background-color: white;
    cursor: pointer;
    opacity: 0;
    width: fit-content;
    max-width: 85%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: #0000004d;
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
  }

  &:hover {
    & > .image {
      // transform: scale(1.1);
      // transition: all 1s;
      -ms-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
    }

    & > .category-name {
      transform: translate(-50%, -85%);
    }
    // .category-btn {
    // opacity: 1;
    // transform: translate(-50%, -200%);
    // animation: fadeInUp 0.8s ease;
    // }
    & > button {
      opacity: 1;
      transform: translate(-50%, -200%);
      animation: fadeInUp 0.8s ease;
    }
    &:before {
      opacity: 1;
    }
  }
}

.category-card-12 {
  & > .image {
    border-radius: 50%;
  }
}

.category-card-13 {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  height: auto;

  & .category-name {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    letter-spacing: 0.25px;
    font-weight: 500;
    line-height: 1.1;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 13px;
    color: #ffffff;
    color: #ffffff;
    background: transparent linear-gradient(360deg, #000000 0%, #54545400 100%)
      0% 0% no-repeat padding-box;
    opacity: 0.86;
  }
}

.category-card-11,
.category-card-12 {
  position: relative;
  cursor: pointer;
  height: auto;

  &.horizontal-card {
    max-width: 75px;
    min-width: 75px;
    margin-right: 10px;
    width: 100%;
  }

  & > .category-name {
    font-size: 12px;
    letter-spacing: 0.25px;
    font-weight: 500;
    line-height: 1.1;
    margin-top: 10px;
  }

  & .card-selected-indicator {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    position: absolute;
    bottom: 0;
    height: 5px;
    background-color: #000000;
  }
}

.category-card-1,
.category-card-3,
.category-card-7,
.category-card-9,
.category-card-11,
.category-card-12,
.category-card-13 {
  & > .image {
    background-color: #f4f4f4;
    position: relative;
    width: 100%;
    background-position: top center;
    background-repeat: no-repeat;
  }
}

@include respond-to('small') {
  .category-card-1 {
    // // height: 758px;

    // & > img {
    //   width: 100%;
    //   height: 100%;
    // }

    // .category-name {
    //   font-size: 24px;
    // }
    .category-btn {
      padding: 15px 20px;
      font-size: 18px;
      opacity: 0;
      left: 50%;
      top: 65%;
      // transform: translateX(-50%, -78%);
    }
  }
  .category-card-9 {
    .category-name {
      font-size: 30px;
    }
    .category-btn {
      font-size: 18px;
      width: 135px;
      height: 45px;
      top: 62%;
    }
  }

  .category-card-11 {
    & > .category-name {
      font-size: 16px;
      letter-spacing: 0.33px;
    }
    &.horizontal-card {
      max-width: 186px;
      min-width: 186px;
      margin-right: 31px;
    }
  }

  .category-card-12 {
    & > .category-name {
      font-size: 16px;
      letter-spacing: 0.33px;
    }
    &.horizontal-card {
      max-width: 186px;
      min-width: 186px;
      margin-right: 31px;
    }
  }

  .category-card-13 {
    & > .category-name {
      font-size: 16px;
      letter-spacing: 0.33px;
    }
    &.horizontal-card {
      max-width: 186px;
      min-width: 186px;
      margin-right: 31px;
    }
  }
}

@include respond-to('medium') {
  .category-card-1 {
    // height: 558px;

    // & > img {
    //   width: 100%;
    //   height: 100%;
    // }

    // .category-name {
    //   font-size: 24px;
    // }
    // .category-btn {
    //   padding: 15px 20px;
    //   font-size: 18px;
    //   opacity: 0;
    //   left: 50%;
    //   top: 78%;
    //   // transform: translateX(-50%, -78%);
    // }
  }

  .category-card-3 {
    .category-btn {
      font-size: 16px;
    }
  }
  .category-card-9 {
    .category-name {
      font-size: 30px;
    }
    .category-btn {
      font-size: 18px;
      width: 135px;
      height: 45px;
      top: 60%;
    }
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -78%);
    transform: translateX(-50%, -78%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -200%);
    transform: translate(-50%, -200%);
  }
}

@keyframes textUp {
  0% {
    -webkit-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%);
  }

  100% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@keyframes zoomin {
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 150%;
  }
}
