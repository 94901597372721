.custom-dropdown {
  font-family: Inter, sans-serif !important;

  .MuiFormControl-root {
    width: 100% !important;
    border-radius: 6px;

    &:hover {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) !important;
      border-radius: 6px;
    }
  }

  .MuiFormLabel-root {
    font-family: Inter, sans-serif !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    word-break: break-all;
    right: 20px;
    font-size: 15px;
    font-weight: 400;
    color: #111c36;
    opacity: 0.6;
    &:focus {
      color: #111c36;
      opacity: 0.6;
    }
  }

  .MuiFormLabel-filled {
    display: none !important;
  }

  // .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  //   display: none !important;
  // }
  .MuiInputBase-root {
    font-family: Inter, sans-serif !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    color: #111c36 !important;
    border-radius: 6px;
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(17, 28, 54, 0.12) !important;
      }
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid #111c361f !important;
    }
    fieldset {
      border: 1px solid #111c361f !important;
    }
  }

  // .MuiInputBase-root.MuiOutlinedInput-root {
  //   border-radius: 8px;
  //   border-color: #ebebeb;
  // }
  .MuiInputBase-input {
    padding: 12px 32px 12px 12px;
    border-radius: 6px !important;
    &:hover {
      border-color: rgba(17, 28, 54, 0.12) !important;
    }
    // height: 50px !important;
  }

  .MuiChip-filled.MuiChip-sizeMedium.MuiChip-filledDefault {
    height: auto;
    padding: 0px 0px;
  }

  .mui-select {
    fieldset {
      border-color: #9a3131 !important;
    }
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(17, 28, 54, 0.12) !important;
  }

  .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation {
    box-shadow: 0px 2px 8px 0px rgba(46, 50, 58, 0.2) !important;
    border: 1px solid rgba(17, 28, 54, 0.12) !important;
    border-radius: 6px !important;
    margin-top: 6px;
    max-height: 400px !important;
    max-width: 200px;

    .MuiMenuItem-root {
      // border-bottom: 1px solid #f0f0f0;
      font-family: Inter, sans-serif !important;
      padding: 8px 12px;
      font-size: 15px;
      color: #111c36;
      font-weight: 400;
      cursor: pointer !important;

      &.Mui-selected {
        background: #fff !important;

        &.active {
          span {
            font-weight: 600 !important;
          }

          background: rgba(17, 28, 54, 0.06) !important;
        }
      }
    }

    .MuiMenuItem-root .MuiListItemText-root {
      white-space: normal;

      span {
        font-family: Inter, sans-serif !important;
        font-size: 15px;
        font-weight: 400;
      }
    }

    .MuiCheckbox-root {
      padding: 1px 0px;
    }

    .search-menu-item {
      &:hover {
        background-color: #fff !important;
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation {
    border-radius: 8px 8px 0px 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
    width: 100% !important;
    max-width: none;
    top: auto !important;
  }

  .MuiBackdrop-root {
    background-color: grey !important;
    opacity: 80% !important;
  }

  .custom-dropdown {
    .MuiFormControl-root {
      width: 100% !important;
      height: auto !important;
      border-radius: 6px;
      border: 1px solid #111c361f;

      &:hover {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08) !important;
      }
    }
  }
}
