@import './../common/mixin.scss';

.payment-screen {
  header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ddd;
    font-weight: 700;

    & > img {
      max-width: 100%;
      height: 30px;
      filter: invert(1);
      margin-right: 10px;
    }
  }

  main {
    padding: 20px;

    .pay-title {
      font-size: 36px;
      font-weight: 700;
      word-break: break-all;

      & > small {
        font-size: 14px;
        font-weight: bold;
        color: #76c717;
        margin-left: 10px;
      }
    }

    .choose__mode {
      color: #22222280;
      font-weight: 500;
      display: block;
      margin: 20px 0 10px 0;
    }

    .payment-wrapper {
      margin-top: 10px;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        li {
          border-radius: 10px;
          box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.3);
          padding: 12px;
          height: 112px;
          color: #fff;
          list-style: none;

          &.googlepay {
            background-color: #4285f4;
          }

          &.phonepe {
            background-color: #6738b7;
          }

          &.card {
            background-color: #fc5c02;

            & > .logo-wrap > img {
              position: relative;
              bottom: 5px;
            }
          }

          &.paytm,
          &.paytmupi {
            background-color: #06306f;
          }

          &.whatsapp {
            background-color: #4cb050;

            & > .logo-wrap > img {
              max-width: 55%;
            }
          }

          &.cash {
            background-color: #403f41;

            & > .logo-wrap > img {
              max-width: 55%;
            }
          }

          &.cemi {
            background-color: #000000;
          }

          .logo-wrap {
            position: relative;
            width: 48px;
            height: 48px;
            padding: 12px 0;
            border-radius: 8px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
            background-color: #fff;
            text-align: center;
            display: block;
            margin: auto;

            img {
              max-width: 80%;
              display: block;
              margin: auto;
            }

            & + span {
              font-size: 14px;
              font-weight: 500;
              display: block;
              text-align: center;
              font-size: 11px;
              letter-spacing: 0.5px;
              margin-top: 25px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  footer {
    padding: 0 20px;

    .payment-disclaimer {
      display: flex;
      margin-top: 80px;

      &__imgWrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 4px;
        flex: none;

        & > img {
          height: 11px;
        }
      }

      &__txtWrap {
        margin-left: 5px;
        font-size: 12px;
        color: #9ea6b9;
        line-height: 1.4em;

        & > span {
          font-weight: 600;
          color: #2e3034;
        }

        & > a {
          color: inherit;
          text-decoration: underline;
        }
      }
    }
  }
}

@include respond-to('medium') {
  .payment-screen {
    display: block;
    margin: auto;
    max-width: 420px;
    border: 1px solid #e2e2ee;
    min-height: 100vh;
    position: relative;

    header {
      padding-top: 20px;
      top: 0;
      max-width: 420px;
    }

    .rodal {
      position: absolute;
      height: 100vh;
    }
  }
}

.extra-cod-charges {
  color: #6B6B6B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  align-self: stretch;
}
