.cashConfModal {
  padding-top: 30px;
  line-height: 1.5em;
  
  &--title {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #4D4D4D;
    display: block;
    margin-bottom: 24px;
  }

  &>button {
    padding: 15px 0;
  }
}