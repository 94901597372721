.logout-modal-content-container {
  padding-bottom: 15px;
  .no-container {
    cursor: pointer;
    border-radius: 4rem;
    width: 139px;
    height: 54px;
    border: 1px solid black;
  }
  .yes-container {
    cursor: pointer;
    border-radius: 4rem;
    width: 139px;
    height: 54px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 14px;
  }
}

@media screen and (max-width: 767px) {
  .logout-modal-content-container {
    padding-right: 11px;
    .no-container {
      cursor: pointer;
      border-radius: 4rem;
      width: 115px;
      height: 45px;
      border: 1px solid black;
    }
    .yes-container {
      cursor: pointer;
      border-radius: 4rem;
      width: 115px;
      height: 45px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-left: 12px;
    }
  }
}
