.ds-searchbar-wrapper {
  width: 100%;
  .searchbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 43px;
    padding: 0 15px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000029;
    border: 0.30000001192092896px solid #ebebeb;
    border-radius: 4px;
    opacity: 1;
  
    &-icon {
      width: 16px;
      height: 16px;
    }
  
    &-input {
      border: 0px;
      width: -webkit-fill-available;  
      text-align: left;
      padding-left: 10px;
      font-size: 15px;
      letter-spacing: 0.42px;
      color: #898989;
    }
  
    &-closeicon {
      display: contents;
      cursor: pointer;
  
      img {
        max-width: 20px;
        filter: grayscale(100);
      }
    }
  }
}
.searchbar-fixed {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  padding: 6px 0;
  z-index: 99;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000033;

  &>div {
    width: 92%;
  }
}