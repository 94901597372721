.spotlightCategoryStoresList {
  min-height: 100vh;

  &__header {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 140px 0px;
    background: transparent linear-gradient(180deg, #fcdacb 0%, #ffffff00 100%)
      0% 0% no-repeat padding-box;

    &--top {
      display: flex;
      justify-content: space-between;
      padding: 14px 20px 10px 12px;

      & > .back--icon {
        & > img {
          max-width: 100%;
          height: 24px;
          filter: invert(1);
        }
      }

      & > .search--icon {
        & > img {
          max-width: 100%;
          height: 20px;
        }
      }
    }
  }

  &__categories {
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    padding-top: 10px;
    // width: 500px;
    overflow-y: scroll;

    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      /* Chrome/Safari/Webkit */
    }

    .category-chips {
      display: flex;
      font-size: 12px;
      font-weight: 600;
      padding: 8px 14px;
      margin: 0 9px 5px 0;
      border-radius: 18px;
      color: #ea5151;
      background-color: #ffffff;
      box-shadow: 0 2px 5px 1px #00000029;
      white-space: nowrap;
      cursor: pointer;
    }

    .active {
      background-color: #ea5151;
      color: #ffffff;
    }
  }

  &__footer {
    min-height: 150px;

    & > img {
      max-width: 100%;
      height: auto;
    }
  }

  &__body {
    min-height: 60vh;
    margin-top: -130px;
    padding: 0 20px 60px 20px;
    position: relative;

    &--title {
      & > span {
        display: block;
      }

      & > :first-child {
        color: #202124;
        font-size: 20px;
        font-weight: 600;
      }

      & > :last-child {
        font-size: 12px;
        font-weight: 600;
        color: #f4071b;
        padding: 4px 0 12px 0;
      }
    }
    & > .view-more-btn {
      opacity: 0;
      border: 0.800000011920929px solid #ea5151;
      font-size: 10px;
      width: auto;
      color: #ea5151;
      padding: 6px 10px;
      position: absolute;
      bottom: 35px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;

      &:hover {
        color: #ffffff;
        background-color: #ea5151;
      }
    }
  }
}

@media screen and (min-width: 740px) {
  .spotlightCategoryStoresList {
    display: block;
    margin: auto;
    max-width: 420px;
    border: 2px solid #e2e2e2;
    position: relative;
  }
}
