.imageDescriptionModal {
  .rodal-dialog {
    max-height: 90%;
    max-width: 80vw;
    padding: 40px;

    @media screen and (max-width: 767px) {
      min-width: 100vw;
      height: 101vh;
      border: none;
      padding: 0px;
    }
  }
}
