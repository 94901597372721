// Constants color file

// VARIABLES
$black-color: #000000;
$white-color: #ffffff;

$opaque-grey: #7f7f7f;
$lime-green-color: #23bf44;
$flamingo-color: #ea5151;
$peach-yellow-color: #fbddb1;
$ochre-color: #b8741e;
$misty-rose-color: #ffd8d8;
$cloud-burst-color: #363f4f;
$sunset-orange-color: #f54947;
$harlequin-color: #37cb00;
$amaranth-color: #ed3561;
$highlighter-red-color: #ea5158;
$slimy-green-color: #3a8e0d;
$ultramarine-blue-color: #3d6afe;
$copyright-text-grey-color: #a3a3a3;
$light-suva-grey: #929292;
$vertical-ad-text-color: #7f7f7f;
$tile-gray: #808080;
$star-dust: #9c9faa;
$sonic-silver: #797a7c;
$display-rating-bar: #cacaca;
//Lime Green
.bg-lime-green-color {
  background-color: $lime-green-color;
}

.lime-green-color {
  color: $lime-green-color;
}

.b-lime-green-color {
  border-color: $lime-green-color;
}

//Flamingo
.bg-flamingo-color {
  background-color: $flamingo-color;
}

.flamingo-color {
  color: $flamingo-color;
}

.b-flamingo-color {
  border-color: $flamingo-color;
}

//Peach Yellow
.bg-peach-yellow-color {
  background-color: $peach-yellow-color;
}

.peach-yellow-color {
  color: $peach-yellow-color;
}

.b-peach-yellow-color {
  border-color: $peach-yellow-color;
}

// Ochre
.bg-ochre-color {
  background-color: $ochre-color;
}

.ochre-color {
  color: $ochre-color;
}

.b-ochre-color {
  border-color: $ochre-color;
}

//Misty Rose
.bg-misty-rose-color {
  background-color: $misty-rose-color;
}

.misty-rose-color {
  color: $misty-rose-color;
}

.b-misty-rose-color {
  border-color: $misty-rose-color;
}

//Cloud Burst
.bg-cloud-burst-color {
  background-color: $cloud-burst-color;
}

.cloud-burst-color {
  color: $cloud-burst-color;
}

.b-cloud-burst-color {
  border-color: $cloud-burst-color;
}

//Sunset Orange
.bg-sunset-orange-color {
  background-color: $sunset-orange-color;
}

.sunset-orange-color {
  color: $sunset-orange-color;
}

.b-sunset-orange-color {
  border-color: $sunset-orange-color;
}

//Harlequin
.bg-harlequin-color {
  background-color: $harlequin-color;
}

.harlequin-color {
  color: $harlequin-color;
}

.b-harlequin-color {
  border-color: $harlequin-color;
}

//Amaranth
.bg-amaranth-color {
  background-color: $amaranth-color;
}

.amaranth-color {
  color: $amaranth-color;
}

.b-amaranth-color {
  border-color: $amaranth-color;
}

//Highlighter Red
.bg-highlighter-red-color {
  background-color: $highlighter-red-color;
}

.highlighter-red-color {
  color: $highlighter-red-color;
}

.b-highlighter-red-color {
  border-color: $highlighter-red-color;
}

// Slimy Green
.bg-slimy-green-color {
  background-color: $slimy-green-color;
}

.slimy-green-color {
  color: $slimy-green-color !important;
}

.b-slimy-green-color {
  border-color: $slimy-green-color;
}

// Ultramarine Blue
.bg-ultramarine-blue-color {
  background-color: $ultramarine-blue-color;
}

.ultramarine-blue-color {
  color: $ultramarine-blue-color !important;
}

.b-ultramarine-blue-color {
  border-color: $ultramarine-blue-color;
}

.copyright-text-grey-color {
  color: $copyright-text-grey-color;
}

.light-suva-grey {
  color: $light-suva-grey;
}

.vertical-ad-text-color {
  color: $vertical-ad-text-color;
}

.black-color {
  color: $black-color;
}

.bg-pure-black-color {
  background-color: $black-color;
}

.white-color {
  color: $white-color;
}

.bg-white__color {
  background-color: $white-color;
}

.opaque-grey__color {
  color: $opaque-grey;
}

.tile-gray-color {
  color: $tile-gray;
}

.star-dust {
  color: #9c9faa;
}

.sonic-silver {
  color: $sonic-silver;
}

.c44454A {
  color: #44454a;
}

.c24AC21 {
  color: #24ac21;
}

.c28292C {
  color: #28292c;
}

.c333333DE {
  color: #333333de;
}

.c1C1C1C {
  color: #1c1c1c;
}

.c464646 {
  color: #464646;
}

.c2F80ED {
  color: #2f80ed;
}

.c393939 {
  color: #393939;
}

.c2F80ED {
  color: #2f80ed;
}

.c6C6C6C {
  color: #6c6c6c;
}

.cF54C52 {
  color: #f54c52;
}

.cacaca {
  color: $display-rating-bar;
}
