.productDescription {
  &-modal {
    & > :last-child {
      margin: 0;
      padding: 0;
      top: initial;
      min-height: 400px;
    }

    & > .rodal-mask {
      background-color: #000000c7;
    }
  }

  &__container {
    overflow-y: scroll;
    max-height: 500px;
    margin: 8px 0 16px 0;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px 8px 16px;

    & > :first-child {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #2a2a2a;
    }
    & > :last-child {
      max-width: 20px;
      filter: grayscale(100);
    }
  }

  &__imgWrapper {
    &--img {
      background-color: #f6f6f6;
      max-width: 100%;
      position: relative;
      background-size: cover;
      height: 260px;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 6px;
      margin-right: 14px;
      margin-bottom: 5px;
    }
    &--dummy {
      display: flex;
      height: 258px;
      justify-content: center;
      box-shadow: 0px 3px 6px #00000029;
      align-items: center;
      opacity: 0.5;
      background-color: #efefef;
    }
  }

  &__contentBox {
    height: 100%;
    background-color: #ffffff;
    margin-top: -1px;
    padding: 0px 0 100px 0;

    &--head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 22px 10px 16px;

      &-name {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #2a2a2a;
      }
    }

    &--variant {
      padding: 0 20px 20px 20px;
    }

    &--body {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0;
      color: #2a2a2ab8;
      padding: 0 35px 0 16px;
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 0;
    justify-content: space-between;
    padding: 15px;
    max-height: 75px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px -3px 16px #00000033;
    &--outofstock {
      // border: 2px solid #EA5151;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 0;
      height: 48px;
      width: 153px;
      color: #ffffff;
      font-weight: 600;
      background-color: #2a2a2a;
    }

    .store-closed-icon {
      width: 11px;
      height: 11px;
      margin-right: 5px;
    }

    .store-closed-para {
      display: block;
      line-height: 1.5;

      & > p {
        margin: 0;
        color: #000000;
        font-size: 10px;

        & > span {
          text-decoration: underline;
        }
      }
    }
  }

  &__amount {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--box {
      display: flex;
      flex-direction: row;

      &-discounted {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #ea5151;
        padding: 6px 0;
      }
      &-withoutdiscounted {
        display: flex;
        align-items: center;
        text-decoration: line-through;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #88889a;
        margin-left: 3px;
      }
    }

    &--savebox {
      font-size: 12px;
      letter-spacing: 0px;
      color: #25252e;
    }
  }
}

/** Slider CSS */
.productDescription__imgWrapper > .slick-slider.slick-initialized {
  padding: 0px;
  background-color: transparent;
  border-radius: 8px;
}
.productDescription__imgWrapper > .slick-initialized .slick-slide {
  padding: 0 !important;
}

.dotted_icons {
  display: inline-block;
  background-color: #ffffff;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  padding: 8px 0 0px 0;
  text-align: center;
}

.dotted_icons li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
}

.dotted_icons li button {
  border: none;
  background: #bababa;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 7.5px;
  padding: 0;
}

.dotted_icons li.slick-active button {
  padding: 0px;
  background: #ea5151 0% 0% no-repeat padding-box;
  border-radius: 3px;
  width: 12px;
  height: 6px;
}

.dotted_icons li button:hover,
.dotted_icons li button:focus {
  background: #ea5151;
  outline: 0;
}

@media (max-width: 320px) {
  .productDescription {
    &__container {
      max-height: 430px;
    }

    &__imgWrapper {
      &--img {
        height: 170px;
      }
      &--dummy {
        height: 170px;
      }
    }

    &__contentBox {
      &--head {
        &-name {
          font-size: 14px;
        }
        &-amount {
          font-size: 13px;
        }
      }

      &--body {
        font-size: 12px;
      }
    }
  }
}
