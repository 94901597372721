.custom-radio {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: black;
  width: 18px;
  height: 18px;
  border: 0.15em solid black;
  border-radius: 50%;
  position: relative;

  &:checked {
    color: currentColor;
    border: 0.15em solid currentColor;
  }

  &::before {
    content: '';
    width: 0.6em;
    height: 0.6em;
    border-radius: 50%;
    background-color: transparent;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:checked::before {
    content: '';
    background-color: currentColor;
  }
}
