 .sortingTab {
   display: flex;
   position: relative;
   align-items: center;
   justify-content: space-between;
   border: 1px solid rgb(209, 209, 209);
   padding: 9px;
   white-space: nowrap;
   font-size: 14px;
   cursor: pointer;
   min-width: 233px;


   .sortingTabText {
     display: flex;
   }

   .dropDownContainer {
     position: absolute;
     display: flex;
     flex-direction: column;
     z-index: 6;
     padding-left: 22px;
     top: 100%;
     margin-top: 10px;
     left: 0%;
     min-width: 233px;
     padding-top: 25px;
     border: 1px solid rgb(209, 209, 209);
     background: white;
   }

   .dropDownArrow {
     display: flex;
     align-items: center;

     &::before {
       content: "";
       padding: 5px;
     }

     &::after {
       content: "";
       padding: 5px;
     }

     >svg {
       transform: rotateZ(var(--showDropDownAngle));
     }
   }
 }