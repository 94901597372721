.payment-failed-screen {
  background-color: #cb1c1c;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-direction: column;
  color: #fff;
  text-align: center;
  min-height: 100vh;
  padding: 10px 20px;

  .back-icon {
    position: absolute;
    max-width: 35px;
    top: 20px;
    left: 20px;
  }
  .main-title {
    font-size: 28px;
    line-height: 1.5em;
    & + p {
      font-size: 14px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
}
