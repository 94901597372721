.ds-input {
    width: 100%;
    margin-bottom: 40px;
    position: relative;

    .form-control,
    .form-control:focus,
    .form-control:hover {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #c3c3c3;
        color: #000;
        outline: none;
        position: relative;
        z-index: 2;
        box-shadow: none;
        background-color: transparent;
        width: 100%;
        padding: 5px;

        &::placeholder {
            opacity: 0.7;
        }
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: #e9ecef;
        opacity: 0.5;
    }

    .form-control+label {
        position: absolute;
        top: -35px;
        left: 11px;
        transition: 0.2s all;
        z-index: 1;
        color: #EA5151;
        font-size: 12px;
    }

    .form-control:not(:empty)+label,
    .form-control:focus+label {
        top: -35px;
        font-size: 12px;
        color: #EA5151;
    }

    input[value=""]+label {
        top: -22px;
        left: 6px;
        color: #999;
        font-size: 14px;
    }
}