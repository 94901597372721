.spotlight {
  min-height: 100vh;

  &__body {
    padding: 20px 12px 100px 22px;
    margin-top: -50px;
    position: relative;

    .view-more-btn {
      opacity: 0;
      border: 0.800000011920929px solid #ea5151;
      font-size: 10px;
      width: auto;
      color: #ea5151;
      padding: 6px 10px;
      position: absolute;
      bottom: 35px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;

      &:hover {
        color: #ffffff;
        background-color: #ea5151;
      }
    }
  }

  &__footer {
    min-height: 150px;

    & > img {
      max-width: 100%;
      height: auto;
    }
  }
}

@media screen and (min-width: 740px) {
  .spotlight {
    display: block;
    margin: auto;
    max-width: 420px;
    border: 2px solid #e2e2e2;
    position: relative;
  }

  .spotlight .rodal {
    max-width: 420px;
    display: block;
    margin: auto;
    height: 100vh;
  }
}
